@import '../../../../styles/variables-mixins.sass'

.bp-skinslider
    width: 100vw
    position: relative
    background: black
    z-index: 1
    .battlepass-badge
        position: absolute
        left: 50%
        transform: translateX(-50%)
        top: -6em
        z-index: 2
        max-width: 10em
        +respond-max-width(md)
            max-width: 10em
            top: -5em
        +respond-max-width(sm)
            max-width: 6em
            top: -3em
    .bg-image
        display: none
    .goldBolds
        position: absolute
        left: 2em
        width: 20%
        top: -2em
        z-index: 1
        +respond-max-width(md)
            top: 2em
            left: -3em
            width: 32%
        +respond-max-width(sm)
            width: 40%
    .bp-secondary-bg-img
        position: absolute
        right: 2em
        width: 20%
        top: -2em
        z-index: 1
        +respond-max-width(md)
            right: -3em
            width: 32%
            top: 2em
        +respond-max-width(sm)
            width: 40%
    .clipShape
        clip-path: polygon(100% 100%, 100% 0%, 90% 20%, 93% 0, 50% 80%, 7% 0%, 10% 20%, 0 0, 0% 100%)
        height: 6vw
        z-index: 1
        width: 100%
        background: black
        position: absolute
        bottom: calc(100% - 2px)
    img:not(.battlepass-badge)
        opacity: 0
    &.visible
        img:not(.battlepass-badge), .battlePass-chars
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
    .battlePass-slider
        .slide 
            height: 70vh
            min-height: 500px
            text-align: center
            +respond-max-width(md)
                height: unset
                min-height: unset
            img 
                height: 100%
                width: auto
                +respond-max-width(md)
                    height: unset
                    width: 100%
                +respond-max-width(md)
                    width: 95%
        +respond-max-width(md)
            padding-top: 15%

    .battlePass-chars
        width: auto
        margin: 0 auto
        padding-top: 4em
        img
            width: 80vw
            margin: 0 auto
            +respond-max-width(md)
                width: 100vw
        .battlePass-paginations
            padding: 0
            display: flex !important
            justify-content: center
            align-items: center
            margin-top: 20px
            li
                position: relative
                display: inline-block
                margin: 0 0.5em
                button
                    cursor: pointer
                    outline: none
                    height: 10px
                    width: 160px
                    border: none
                    background: #29ade4
                    color: transparent
                    opacity: 0.3
                    transition: 0.5s ease-in-out
                    +respond-max-width(md)
                        width: 60px
                &.slick-active
                    button
                        opacity: 1
    .battlepass-bottom-content
        text-align: center
        margin: 3% auto 0
        position: relative
        z-index: 1
        h1
            font-size: 5vw
            +respond-min-width(lg)
                font-size: 1.4em
            +respond-max-width(md)
                font-size: 6vw
        p
            margin: 0 auto
            margin-top: 2vw
            font-size: 1.5vw
            width: 45vw
            +respond-min-width(lg)
                font-size: 1.4em
            +respond-max-width(md)
                width: 75vw
                font-size: 2.5vw

.chapter-2-season-3,
.ch2-s3
    .badge-container
        position: absolute
        bottom: 98%
        left: 0
        right: 0
        width: 100%
        max-width: 1400px
        margin: 0 auto
        +respond-max-width(xs)
            bottom: 96%
    .battlepass-badge
        position: absolute
        top: unset
        left: unset
        bottom: 0
        right: 1em
        max-width: 12em
        transform: translateX(0)
        +respond-max-width(xs)
            max-width: 7.5em
    .goldBolds
        display: none
    .bg-image
        display: block
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        transform: translateY(-10%)
        clip-path: polygon(0% 100%, 0% 0%, 75% 5%, 26% 0%, 100% 4%, 100% 100%)
    .battlepass-bottom-content
        color: #000
        h1 
            color: #0086cb
            font-size: 4vw
            +respond-max-width(xs)
                font-size: 2em
        p
            font-size: 1.2vw 
            margin: 0 auto
            width: 35em
            +respond-max-width(md)
                font-size: 1.2em
            +respond-max-width(xs)
                font-size: 1em
                width: 100%
                padding: 0
                max-width: 35em
        +respond-max-width(xs)
            padding: 0 1em
    .bp-skinslider
        background: #fff
        padding: 3em 0
        +respond-max-width(md)
            .battlePass-slider
                padding-top: 0  
    .clipShape
        display: none


.ko
    .battlepass-bottom-content
        p 
            font-family: $font_ko_art !important
            font-size: 1.5em
