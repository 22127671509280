@import '~epic-fortnite-styles/common-variables.sass'


body.ar
    .searchView
        direction: rtl
.search-ctr
    background-color: $background
    .section-header-imagebg
        background: url('~epic-fortnite-react/images/sfs/backgroundSky.jpg') no-repeat center top
        background-size: cover
.searchView
    min-height: 80vh
    padding: 60px 1em
    background: url('~epic-fortnite-react/images/siteBg/FNlightTexture-min.jpg') no-repeat center top
    background-size: 100% auto
    background-color: white
    @include respond-max-width(xs)
        padding: 1em 0
    &.bingView
        min-height: 40vh
    h1
        padding-bottom: 0.6em
        border-bottom: 1px solid $darkGray
        margin-bottom: 0.6em
        @include respond-max-width(xs)
            font-size: 2em
    .gsc-control-cse
        margin: 0 auto
        border: 1px solid transparent
        padding: 0
        background-color: transparent
        & *
            font-family: $font_4 !important
            font-weight: 400
    .gs-webResult .gs-snippet
        margin-top: 0.4em
        margin-bottom: 0.4em
    .gsc-webResult.gsc-result
        margin-bottom: 1em
        background-color: #fff
        padding: 1.6em
        &:hover
            border-color: transparent
            background-color: #fff
        @include respond-max-width(xs)
            padding: 1em
    .gsc-result .gs-title
        height: auto
        @include respond-max-width(xs)
            margin-bottom: 0.2em
    .gs-webResult.gs-result a.gs-title:link
        color: $foundersBlue
        font-size: 22px
        line-height: 1.3
        font-weight: 200
        b
            font-size: 22px
            color: $foundersBlue
            font-weight: 400
        &:hover
            color: darken($foundersBlue, 5%)
            text-decoration: underline
        @include respond-max-width(xs)
            font-size: 18px
            b
                font-size: 18px

    .gs-webResult.gs-result a.gs-title:visited,
    .gs-webResult.gs-result a.gs-title:visited b
        color: darken($foundersBlue, 30%)
    .gs-webResult div.gs-visibleUrl-long
        color: #737373
    .gs-result .gs-title
        text-decoration: none
    .gsc-results .gsc-cursor-box .gsc-cursor-page
        margin-right: 1em
        color: $darkGray
        background-color: transparent
        font-size: 18px
    .gsc-results .gsc-cursor-page.gsc-cursor-current-page
        color: $siteBlockBg
        background-color: $darkGray
        text-shadow: none
        font-size: 18px
    .gs-no-results-result .gs-snippet
        padding: 10px
    .gsc-table-cell-snippet-close
        padding-left: 4px
    .gsc-tabHeader.gsc-tabhInactive,
    .gsc-tabHeader.gsc-tabhActive
        border: none
        padding: 0.8em 1.6em
        font-size: 13px
    .gsc-tabHeader.gsc-tabhActive
        background-color: $foundersBlue
        color: #333
        font-weight: bold
    .gsc-tabHeader.gsc-tabhInactive
        background-color: #333
        color: #fff
    .gs-spelling
        a
            color: $foundersBlue
            &:hover
                color: darken($foundersBlue, 10%)
                text-decoration: underline
    .gsc-input > input
        padding: 0.8em
        @include respond-max-width(xs)
            padding: 0.5em
            font-size: 14px
    input.gsc-search-button
        padding: 0.8em 1.5em
        border: 0
        border-radius: 0
        background-color: $accent-color
        color: $invert-text-color
        text-transform: uppercase
        font-weight: 500
        font-size: 16px
        height: auto
        &:hover
            background-color: $accent-color-hover
        @include respond-max-width(xs)
            font-size: 14px
            padding: 0.5em
    td.gcsc-branding-text div.gcsc-branding-text
        color: $siteBlockTextSecondary

    .searchResults > div
        background-color: transparent !important
    .cse form.gsc-search-box, form.gsc-search-box
        padding: 0
    .cse .gsc-cursor-box, .gsc-cursor-box
        border-color: transparent
    .cse table.gsc-search-box td.gsc-input, table.gsc-search-box td.gsc-input
        padding-right: 0
    .gsc-control-cse .gs-spelling
        font-weight: 600
    .gsc-url-bottom
        @include respond-max-width(xs)
            display: none
