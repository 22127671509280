@import '../../../../styles/variables-mixins.sass'

.animate-string
    span
        display: inline-block
        padding-right: 0.2em
        opacity: 0
        &:last-child
            padding-right: 0
    &.overflow
        overflow: hidden
