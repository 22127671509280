@import '~epic-fortnite-styles/variables-mixins.sass'

.tweet
    background-color: #ffffff
    min-height: 51px
    max-width: 588px
    min-width: 350px
    border-bottom: 1px solid #e6ecf0
    color: #14171a
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif
    font-size: 14px
    line-height: 20px
    list-style-image: none
    list-style-position: outisde
    list-style-type: none
    padding: 9px 12px
    position: relative
    text-align: start
    overflow: hidden
    svg
        vertical-align: middle
    .tweet-text
        white-space: pre-wrap
        word-wrap: break-word
        color: #14171a
        margin: 0
        .emoji
            height: 14px
            margin-right: 5px
        a
            text-decoration: none
            color: #1da1f2
    .content
        margin-left: 58px
    .context
        margin: -3px 0 3px 34px
        .tweet-context
            svg
                vertical-align: sub
        .pretty-link
            text-decoration: none
            color: #657786
    .header
        .account-group
            color: #8899a6
            text-decoration: none
        .avatar
            float: left
            margin-top: 3px
            margin-left: -58px
            width: 48px
            height: 48px
            border-radius: 24px
            border: 0
        .fullname
            font-weight: bold
            color: #14171a
        .username
            direction: ltr
            unicode-bidi: embed
            font-size: 14px
            color: #657786
            s
                color: #b1bbc3
                text-decoration: none

            b
                font-weight: normal
        .tweet-timestamp
            font-size: 14px
            text-decoration: none
            color: #8899a6
        .logo
            float: right
            margin-top: -3px
    .time
        color: #657786
    .retweet-text
        color: #657786
        font-size: 12px
        line-height: 16.5px
        margin-left: 8px
    .tweetText
        white-space: pre-wrap
        word-wrap: break-word
        color: #14171a
        margin: 0
    .footer
        display: table
        width: 100%
        font-size: 12px
        padding-top: 1px
        .ProfileTweet-actionList
            font-size: 0em
            line-height: 1
            margin-top: 10px
            margin-bottom: 2px
            display: block
        .ProfileTweet-action
            display: inline-block
            width: 80px
        .ProfileTweet-actionButton
            display: inline-block
            font-size: 16px
            line-height: 1
            padding: 0 2px
            position: relative
            border: 0
            background: transparent
            margin: 0
            appearance: button
            cursor: pointer
        .ProfileTweetActionCount
            color: #657786
            display: inline-block
            font-size: 12px
            font-weight: bold
            margin-left: 6px
            position: relative
        .ProfileTweetActionRetweetCountActive
            color: #17bf63
        .ProfileTweetActionFavouriteCountActive
            color: #e0245e
        .IconContainer
            display: inline-block
            vertical-align: middle
        .IconTextContainer
            display: inline-block
    .Icon
        background: transparent
        display: inline-block
        font-style: normal
        position: relative
    .IconContext
        background: #77b255
        display: inline-block
        font-style: normal
        vertical-align: middle
        position: relative
        width: 15px
        height: 14px
        fill: white
        borderRadius: 2px
    .AdaptiveMedia
        display: inline-block
        max-height: 508px
        max-width: 508px
        margin: 10px 0 0 0
        overflow: hidden
        border: 1px solid rgba(0,0,0,0.1)
        border-radius: 5px
        vertical-align: top
        text-align: center
        width: 100%
        .AdaptiveMedia-photoContainer
            max-height: 300px
            +respond-max-width(xl)
                max-height: 240px
            width: 100%
            position: relative
            overflow: hidden
            img
                width: 100%
                position: relative
                vertical-align: bottom
        .AdaptiveMediaBadge
            background: rgba(0, 0, 0, 0.3)
            border-radius: 3px
            bottom: 52px
            left: 80px
            box-sizing: border-box
            color: #fff
            height: 20px
            line-height: 20px
            font-weight: 700
            padding: 0 5px
            position: absolute
            z-index: 1
            .video
                width: 100%
                vertical-align: bottom
                max-height: 508px
                object-fit: contain
                background: #000000
    .img
        width: 100%
    .QuoteTweet
        margin-top: 12px
        border: solid 1px #e6ecf0
        &:hover
            border: solid 1px aab8c2
        cursor: pointer
        border-radius: 4px
        position: relative
        .QuoteTweet-link
            height: 100%
            left: 0
            position: absolute
            top: 0
            width: 100%
        .QuoteTweet-innerContainer
            padding: 12px
        .QuoteTweet-originalAuthor
            display: inline-block
            max-width: 100%
            position: relative
            z-index: 1
        .QuoteTweet-screenname
            color: #657786
        .QuoteTweet-text
            margin-top: 3px
            max-height: 78px
            max-width: 100%
            overflow: hidden
            white-space: pre-wrap
            word-wrap: break-word
    .Modal
        position: fixed
        top: 0
        left: 0
        z-index: 999999999999
        overflow-x: hidden
        text-align: center
        background: rgba(0, 0, 0, 0.8)
        display: table
        width: 100%
        height: 100vh
        .spacer
            height: 100%
            display: inline-block
            vertical-align: middle
        .closeModal
            height: 50px
            width: 100%
            display: flex
            justify-content: flex-end
            align-items: center
            background: white
        .modal-tweet
            background-color: #ffffff
            min-height: 51px
            min-width: 350px
            color: white
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif
            font-size: 14px
            line-height: 20px
            list-style-image: none
            list-style-position: outisde
            list-style-type: none
            position: relative
            text-align: start
            .media-wrap
                width: 100%
                background: black
                img
                    width: 100%
                    margin: 0 auto
                    display: block
            .content
                margin-left: 58px
                padding: 9px 12px
        .ModalWrap
            position: relative
            display: inline-block
            vertical-align: middle
            margin: 0 auto
            z-index: 20
            margin-top: 50px
            border-radius: 5px
            overflow: hidden
        .ModalClose
            position: fixed
            top: 0
            left: 0
            z-index: 10
            overflow-x: hidden
            text-align: center
            display: table
            width: 100%
            height: 100vh
        .ModalInner
            display: table-cell
            vertical-align: middle
            position: relative
            z-index: 20
