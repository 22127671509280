@import '../../../../styles/variables-mixins.sass'

.fn-mobile-view
    .btn.btn-primary
        color: $invert-text-color
        background-color: $yellow
        &:hover
            background-color: darken($yellow, 10%)
    .redeemView
            background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat center
            background-size: cover
            .buttonContainer
                .col-sm-6:last-child
                    width: 100%
                .btn-default
                    display: none


