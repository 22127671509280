@import '../../../styles/variables-mixins.sass'
$background: #000000
.category-filter
    padding: 0 15px
    &.overlayCat
        position: fixed
        z-index: 2 
        top: 0 
        left: 0
        right: 0
        width: 100%
        max-width: $containerWidth
        min-width: $containerMinWidth
        margin: 0 auto
        @media screen and (max-width: $containerMinWidth)
            max-width: 100%
            width: 100% 
            min-width: 100%
        .filter-container
            position: absolute
            top: 8em
            right: -2.85em
            padding: 0 1em
            transition: right 0.3s ease-out
            @media screen and (max-width: 1675px)
                right: 0
            .toggleButton
                position: relative
                display: inline-block
                padding: 0.7em .7em 0.5em
                background-color: black
                cursor: pointer
                transition: background-color 0.3s ease-in-out
                animation: float-in 0.5s ease-in-out 0.2s forwards
                opacity: 0
                i 
                    font-size: 1.5em
                    color: white
                    transition: color 0.3s ease-in-out
                .count
                    font-size: 0.7em
                    display: block 
                    position: absolute 
                    top: -1em
                    right: -1.2em
                    color: $text-color
                    width: 2.2em 
                    height: 2.2em
                    span 
                        position: relative
                        z-index: 1
                        display: block
                        width: 100%
                        text-align: center
                        transform: translateY(4px)
                    &:before 
                        content: ''
                        display: block
                        position: absolute 
                        top: 0 
                        left: 0 
                        right: 0 
                        bottom: 0
                        border-radius: 50%
                        background-color: $accent-color
                        z-index: 0
                &:hover 
                    +respond-min-width(md)
                        background-color: gray 
                        i 
                            color: black
            .filter-list
                position: fixed
                top: 0
                left: 0
                bottom: 0
                right: 0
                color: $text-color
                padding: 2em
                width: 100%
                height: 100%
                visibility: hidden
                z-index: -1 
                opacity: 0
                transform: scale3d(2, 2, 2)
                .bkgrd
                    position: absolute
                    top: 0
                    left: 0
                    width: 100% 
                    height: 100%
                    background: rgba($background, 0.95)
                .filter-content 
                    width: 100% 
                    max-width: 900px
                    padding: 1em
                    margin: 0 auto 
                    +vertical-align
                    h3 
                        margin: 1.5em 0 
                        text-align: center 
                        +respond-max-width(sm)
                            margin: 1em 0
                    ul 
                        display: block 
                        margin: 0 auto
                        text-align: center
                        transform: translateY(20px)
                        opacity: 0
                        li 
                            font-family: $font_1
                            font-size: 1.2em
                            padding: 0.5em 1em
                            display: inline-block
                            margin: 0 0.5em 1em
                            border: 1px solid white
                            cursor: pointer
                            transition: all 0.2s ease-in-out
                            +respond-max-width(sm)
                                font-size: 1em
                                padding: 0.5em
                            span + span 
                                margin-left: 0.5em
                            &:hover,
                            &.active
                                background: $accent-color
                                border-color: $accent-color
                                color: $invert-text-color
                    .closeButton
                        position: absolute
                        top: -1em 
                        right: -1em
                        cursor: pointer
                        display: block
                        i 
                            font-size: 2em
                            transition: color 0.3s ease-in-out
                        +respond-max-width(sm)
                            top: -1em 
                            right: 0 
                        &:hover
                            +respond-min-width(md)
                                i 
                                    color: $accent-color
        &.open
            .toggleButton 
                opacity: 0
                visibility: hidden
            .filter-list
                visibility: visible
                z-index: 1 
                opacity: 1
                transform: scale3d(1, 1, 1)
                transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out
                .filter-content ul 
                    transform: translateY(0)
                    opacity: 1
                    transition: all 0.4s ease-in-out 0.05s
    &.listCat
        .toggleButton, .closeButton 
            display: none
        .filter-container
            padding: 0 15px
            .filter-list
                .filter-content
                    h3 
                        display: none
                    ul 
                        margin-bottom: 2em
                        display: block
                        +respond-max-width(sm)
                            text-align: center
                        li 
                            font-size: 1em
                            display: inline-block
                            margin-right: 0.7em
                            cursor: pointer
                            opacity: 0.5
                            transition: opacity 0.3s ease-in-out
                            &:hover,
                            &.active
                                opacity: 1

@keyframes float-in
    0%
        opacity: 0
        transform: translateY(100px)
    100% 
        opacity: 1
        transform: translateY(0)
