@import '~epic-fortnite-styles/variables-mixins.sass'

// Creative Islands V2 Header
.creative-island-header
    padding-top: 96px
    padding-bottom: 192px
    position: relative
    z-index: 1
    overflow: hidden
    margin-bottom: 64px

    +respond-max-width(md)
        margin-bottom: 0
        padding-top: 0
        padding-bottom: 128px

    .creative-island-bg
        --fn--blur: 1rem
        background-size: cover
        background-position: center center
        background-repeat: no-repeat
        filter: blur(var(--fn--blur))
        height: 100%
        left: 0px
        overflow: hidden
        position: absolute
        transform: scale(1.1)
        top: 0px
        width: 100%
        z-index: -1

    .creative-island-bg::before
        background: linear-gradient(100deg, rgb(18, 30, 104), rgb(16, 80, 176)) red
        content: ""
        display: block
        height: calc(100% + var(--fn--blur) * 2)
        left: calc(var(--fn--blur)*-1)
        margin: auto
        opacity: 0.7
        position: absolute
        top: calc(var(--fn--blur)*-1)
        width: calc(100% + var(--fn--blur) * 2)

    .creative-island-content-wrapper
        max-width: 1920px
        margin: 0 auto
        padding: 0 32px
        display: flex
        align-items: center

        +respond-max-width(md)
            flex-direction: column

    .creative-island-content
        flex-basis: 40%
        color: white
        padding-left: 48px
        padding-right: 48px
        padding-top: 24px

        +respond-max-width(md)
            order: 1
            padding: 24px 0 0
            text-align: center
            width: 100%

        h1
            font-size: 66px
            line-height: 72px
            margin-top: 20px
            margin-bottom: 20px

            +respond-max-width(md)
                font-size: 51px
                line-height: 56px

            +respond-max-width(sm)
                font-size: 41px
                line-height: 48px

        h3
            font-size: 28px
            color: #cef3ff

            +respond-max-width(md)
                font-size: 20px

            span
                color: #58DCFF
    .creative-island-creator-tag
        display: flex
        align-items: center
        justify-content: start
        gap: 0.5rem

        +respond-max-width(md)
            justify-content: center

    .creative-island-img
        flex-basis: 60%
        +respond-max-width(md)
            width: 100%
            flex-basis: 100%

        img
            filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 1.5rem)
            width: 100%

    .creative-island-button-group
        display: flex
        align-items: center
        justify-content: start
        gap: 1rem

        .btn-primary
            clip-path: polygon(0% 8%, 100% 0%, 98% 100%, 2% 92%)
            transition: clip-path 0.2s ease-in-out
            padding: 0.5rem 2rem 0

        +respond-max-width(md)
            justify-content: center

    .creative-island-tagline
        color: #cef3ff
        font-family: BurbankSmall, sans-serif
        font-size: 22px
        font-weight: 600
        line-height: 33px
        margin-top: 24px
        margin-bottom: 3rem
        max-width: 460px

        +respond-max-width(md)
            margin: 24px auto 12px

    .creative-island-code-wrapper
        display: flex
        align-items: center
        justify-content: start
        gap: 1rem

        +respond-max-width(md)
            justify-content: center

    .island-header-code
        font-size: 28px
        margin: 0

        +respond-max-width(md)
            font-size: 20px

    .creative-island-details-unavailable-message
        background: #58DCFF
        padding: 10px
        color: rgb(51, 24, 137)
        font-weight: bold
        display: inline-flex
        margin: 4rem 0 0
        align-items: center

// @deprecated v1 header
.headerSection
    position: relative
    color: $white
    font-size: 16px
    +respond-max-width(md)
        font-size: 14px
    +respond-max-width(xs)
        font-size: 10px
    .fade
        display: flex
        justify-content: center
        align-items: center
        opacity: 0
        position: fixed
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba(0, 0, 0, 0.9)
        &.error
            animation: fade-in-and-out 2.5s ease-in-out both
            z-index: 99
        span
            font-size: 3em
            font-family: $Burbank
            text-transform: uppercase
        .icon-warning
            &::before
                margin-right: 1em
    b
        text-transform: uppercase
    .island-header
        display: flex
        // padding: 0
        +respond-max-width(md)
            flex-wrap: wrap
            flex-direction: column
        .island-header-image-or-video, .island-header-content
            display: flex
            // padding: 5%
            flex-direction: column
            justify-content: center
            +respond-max-width(md)
                flex: 1 1 100% !important
        .island-header-image-or-video
            z-index: 2
            position: relative
            flex: 57 57 57%
            display: flex
            clip-path: polygon(0 0, 100% 0%, 96% 99%, 0% 100%)
            min-height: calc(100vh - 50px)
            +respond-max-width(md)
                min-height: unset
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 95%)
            .island-header-edit-overlay
                position: absolute
                top: 0
                right: 0
                left: 0
                bottom: 0
                background-color: rgba(0,0,0,0.8)
                width: 100%
                display: flex
                justify-content: center
                align-items: center
            img
                width: 100%
            .image
                position: absolute
                top: 0
                bottom: 0
                right: 0
                left: 0
                background-position: center
                background-size: cover
                +respond-max-width(md)
                    display: none
                &.mobile
                    display: none
                    z-index: 4
                    position: relative
                    width: 100%
                    +respond-max-width(md)
                        display: block
            .hoverable
                cursor: pointer
                transform: none
                transition: transform 0.3s ease-in-out
                display: flex
                flex: 1
                width: 100%
                justify-content: center
                align-items: center
                .image
                    background-position: center
                    background-size: cover
                    position: absolute
                    top: 0
                    left: 0
                    right: 0
                    bottom: 0
                    &.mobile
                        position: relative
                .icon-play
                    color: $yellow
                    font-size: 8em
                    position: relative
                    display: block
                    z-index: 0
                    &:after
                        content: ''
                        position: absolute
                        height: .6em
                        width: .6em
                        border-radius: 50%
                        top: .4em
                        left: .2em
                        transform: translateY(-50%)
                        background-color: black
                        z-index: -1
                    +respond-max-width(md)
                        position: absolute
                        z-index: 4
                &:hover
                    transform: scale(1.01) rotateY(5deg)
            .youtube-video-embed
                width: 100%
        .island-header-content
            overflow: hidden
            z-index: 1
            flex: 43 43 43%
            padding: 4% 4% 4% 7%
            margin-left: -3%
            clip-path: polygon(0% 0, 100% 0%, 100% 98%, 0% 99%)
            background-color: $light-blue
            +respond-max-width(md)
                margin-left: 0
                padding-top: 4em
                margin-top: -2.5em
                align-items: center
                clip-path: polygon(0% 0, 100% 0%, 100% 98%, 0% 100%)
            +respond-max-width(sm)
                padding-bottom: 3em
            .island-header-edit
                button
                    background-color: $black
                    clip-path: polygon(0 8%, 100% 0%, 97% 100%, 3% 94%)
                    color: $white
                    padding: 5px 30px
                    font-family: $OpenSans
                    font-size: 1.1em
                    font-weight: bold
                    letter-spacing: 1px
                    margin-bottom: 1em
                    margin-right: 10px
            .island-header-creator-wrapper
                order: 0 // for SEO semantic header order
            .island-header-creator
                font-weight: 700
                font-size: 1em
                display: inline
                margin-right: 10px
                text-transform: uppercase
                font-family: OpenSans,sans-serif
            *[contenteditable=true]
                position: relative
                &:before
                    content: "\e92a"
                    position: absolute
                    left: -30px
                    font-size: 20px
                    color: $yellow
                    font-family: 'icomoon' !important
                    speak: none
                    font-style: normal
                    font-weight: normal
                    font-variant: normal
                    text-transform: none
                    line-height: 1

                    /* Enable Ligatures ================ */
                    letter-spacing: 0
                    -webkit-font-feature-settings: "liga"
                    -moz-font-feature-settings: "liga=1"
                    -moz-font-feature-settings: "liga"
                    -ms-font-feature-settings: "liga" 1
                    font-feature-settings: "liga"
                    -webkit-font-variant-ligatures: discretionary-ligatures
                    font-variant-ligatures: discretionary-ligatures

                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased
                    -moz-osx-font-smoothing: grayscale
            .island-header-title
                order: 1 // for SEO semantic header order
                margin-top: 0
                padding: 2%
                margin-left: -2%
                margin-bottom: 0
                font-size: 4em
                line-height: 90%
                width: 90%
                align-self: flex-start
                word-wrap: break-word
                text-transform: uppercase
                &[contenteditable=true]
                    outline: none
                    color: $white
                    background-color: $lighter-blue
                    pointer-events: auto
                    caret-color: #a5e7fa
                    text-transform: unset
                    &::selection, & ::selection
                        background: $black
                    &::selection, & ::selection
                        background: $black
                +respond-max-width(lg)
                    font-size: 3em
                +respond-max-width(md)
                    align-self: center
                    text-align: center
            .island-header-version
                order: 2 // for SEO semantic header order
                letter-spacing: 1px
                margin-bottom: 0
            .island-header-tagline
                order: 3 // for SEO semantic header order
                line-height: 1.1
                width: 80%
                margin: 0 0 0 -2%
                padding: 1%
                font-size: 1.5em
                font-family: $BurbankSmall
                font-style: italic
                color: $yellow
                text-transform: uppercase
                word-wrap: break-word
                &[contenteditable=true]
                    outline: none
                    color: $white
                    background-color: $lighter-blue
                    pointer-events: auto
                    caret-color: #a5e7fa
                    text-transform: unset
                    &::selection, & ::selection
                        background: $black
                    &::selection, & ::selection
                        background: $black
                +respond-max-width(md)
                    text-align: center
            .island-header-code-wrapper
                order: 4 // for SEO semantic header order
                margin-top: 2em
                white-space: nowrap
                +respond-max-width(sm)
                    margin-top: 1em
                &.invisible
                    opacity: 0
                    margin-top: 0
                    height: 3em
                .copyToClipboardWrapper
                    +respond-max-width(sm)
                        text-align: center
                .island-header-code
                    font-size: 2.25em
                    margin: 0
                    padding: 2% 0
                    letter-spacing: 0.2em
                    +respond-max-width(sm)
                        margin-top: 0.5em
            .island-header-cta
                order: 5 // for SEO semantic header order
                margin-bottom: 3%
                +respond-max-width(sm)
                    text-align: center
                    margin-bottom: 5%
                +respond-max-width(md)
                    text-align: center
                .btn
                    padding: 2% 10%
                    clip-path: polygon(0 0, 100% 0, 98% 98%, 2% 100%)
                    font-family: BurbankBigCondensed-Black,sans-serif
                    font-size: 2em
                    letter-spacing: 1px
                    background-color: $white
                    color: $light-blue
                    &.disabled.added
                        opacity: 1
                        padding-left: 4%
                        min-width: 50%
                        &:hover
                            color: #067800 !important // override btn.disabled:hover effects
                    i.icon-check
                        font-weight: 800
                        margin-right: 0.5em
                .add-notice
                    display: block
                    margin-top: 3%
                    font-size: 0.75em
                    width: 50%
                    margin-left: 10px
                    .see-example
                        font-size: 1.2em
                        padding-left: 3%
                        color: $yellow
                        cursor: pointer
                        text-transform: uppercase
                        font-weight: bold
                    +respond-max-width(md)
                        text-align: center
                    +respond-max-width(sm)
                        margin: 3% auto
                .island-header-note
                    margin-top: 3%
                    text-transform: uppercase
                    font-family: $OpenSans
                    font-size: 0.75em
                    letter-spacing: 1px
                    font-weight: bold
                    width: 50%
                    text-align: center
                    +respond-max-width(md)
                        margin: 0 auto
    .island-header-decritpion
        margin: 0
        text-align: center
        padding-bottom: 2em
        +respond-max-width(sm)
            padding-left: 2%
            padding-right: 2%
            width: 100%
            margin-top: 0

.headerSection.editMode
    .island-header
        // transition: all 0.3s ease-in-out
        color: #55cef6
        pointer-events: none
        user-select: none
        .island-header-cta
            .btn.btn-primary
                color: #03b8f1
                background-color: #55cef6
            .add-notice
                .see-example
                    color: #55cef6
    .island-header-edit-overlay
        z-index: 2
        pointer-events: auto
        +respond-max-width(md)
            z-index: 5
        input
            outline: none
            width: 70%
            font-size: 2em
            font-family: $BurbankSmall
            text-align: center
            color: gray
            padding: 1%
            &::placeholder
                color: #d5d5d5
                text-transform: uppercase
                // font-weight: bold
    .island-header-edit
        button
            pointer-events: auto
        .discardBtn
            background-color: #0999c4
    .toxic
        border: 3px solid $yellow
    .toxic-warning
        margin-top: 3px
        color: $yellow
        font-family: $OpenSans
        font-style: italic
@keyframes bounce
    0%
        transform: translateY(0.1em)
    50%
        transform: translateY(0.4em)
    100%
        transform: translateY(0.1em)
