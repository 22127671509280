@import '../../../../../../../../libraries/epic-fortnite-styles/variables-mixins'

$ctaColor: #0b0b33
$ctaHoverColor: lighten($ctaColor, 20%)

.fortnite-wrapper
    .chapter2-season6
        background: #3BAEDA
        .container
            display: flex
            justify-content: flex-end
            align-items: center
            padding: 1em
            z-index: 1001
            &:after,
            &:before
                display: none

        .BgCTA-container
            width: 100vw !important
            &.mobile
                height: 100vh
                min-height: 800px
                +respond-max-width(xs)
                    min-height: 600px
                    height: 90vh
                .BgCTA-wrapper
                    height: 100%
                .center
                    top: 10em
            .BgCTA-wrapper
                width: 100%
                background-size: cover
                background-position: center
                background-repeat: no-repeat
            .center
                display: flex !important
                flex-direction: column
                position: absolute
                top: 20em
                bottom: 0
                left: 0
                right: 0
                width: 100%
                text-align: center
                transform: none
                padding-top: 10em
                justify-content: center
                +respond-max-width(lg)
                    top: 10em
                +respond-max-width(xs)
                    top: 0
                .text-wrapper
                    display: inline-block
                    padding: 1em 10em
                    font-size: 1em !important
                    +respond-max-width(sm)
                        padding: 3em 0
                        width: 100vw
                button
                    padding: 0 1em
                    background-color: $ctaColor
                    color: $black
                    &:hover
                        background-color: $ctaHoverColor
                    +respond-max-width(sm)
                        font-size: 2em
            .copyright
                p
                    color: $white

        .cta-container
            padding: 2em 1em
            h1
                +respond-max-width(sm)
                    font-size: $font-size-h1
            p
                max-width: 30em !important
                margin-top: .5em
                font-family: $font_2
                font-size: 25px
                line-height: 1.25
                +respond-max-width(sm)
                    font-size: 1.8rem

            button
                margin-top: .75em
                font-size: 4rem

                +respond-max-width(xs)
                    font-size: 2.4rem

        .LPVideo
            .screen
                background: #3BAEDA
.noheader
    .chapter2-season6-thankyou
        .container
            display: flex
            justify-content: space-between
            align-items: center
            padding: 1em
            &:after,
            &:before
                display: none

        .LPdlwrapper
            justify-content: flex-end
            flex-direction: column
            +respond-max-width(sm)
                background-position: 70% 0
            .LPdlwrapper-lowlight-overlay
                background: transparent

        .LPdlinnerwrapper
            align-items: center
            &.right
                margin: 0 auto auto 0
            .right.ch2s6
                margin: 18% auto 0
            .left.ch2s6
                margin: 18% auto 0
                h1
                    padding: 10px 0
            .candownload, .selectPlatform, .available
                color: #000
                +respond-max-width(sm)
                    color: #fff
            +respond-max-width(sm)
                &.left
                    margin: 0

.ar
    .noheader .chapter2-season6-thankyou
        .LPdlinnerwrapper .legalTags
            align-self: center !important
