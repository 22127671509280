@import '~epic-fortnite-styles/common-variables.sass'


.bingResult
    padding: 2em
    margin: 1em 0
    background-color: #fff
    @include respond-max-width(xs)
        padding: 1em
    a
        color: $foundersBlue
        transition: color 0.3s ease-in-out
        &:hover
            color: darken($foundersBlue, 10%)
    .resultTitle
        a
            display: inline-block
            font-size: 22px
            font-weight: 600
            line-height: 1.2
            margin-bottom: 0.125em
            padding-bottom: 0.25em
            border-bottom: 2px solid transparent
            transition: border 0.3s ease-in-out
            @include respond-max-width(xs)
                font-size: 16px
                text-decoration: underline
                border-bottom: 0
            &:hover
                border-bottom: 2px solid darken($foundersBlue, 10%)
    .resultLink
        a
            display: inline-block
            margin: 5px 0 3px
            font-size: 13px
            word-break: break-all
            color: $darkGray
            font-weight: 600
            line-height: 1.1
            &:hover
                color: $foundersBlue
    .resultDescription
        font-size: 14px
        font-weight: 400
        line-height: 1.4em
        color: $darkGray
        @include respond-max-width(xs)
            font-size: 13px

