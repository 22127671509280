@import '~epic-fortnite-styles/mixins.sass'

.CodeNotFound
    background: url('~epic-fortnite-react/images/error/404-bkgrd.jpg') center no-repeat
    background-size: cover !important
    text-align: center
    font-size: 16px
    overflow: hidden
    min-height: 90vh
    display: flex
    +respond-max-width(md)
        min-height: 85vh
    @include respond-max-width(sm)
        padding: 1em
    .content.content
        display: flex
        justify-content: center
        flex-direction: column
        margin: 60px auto
        @media only screen and (min-width: $screen-xl-min)
            margin-bottom: 90px
        @include respond-max-width(md)
            margin-bottom: 30px
        h1
            font-family: BurbankBigCondensed-Black, sans-serif
            font-size: 5em
            letter-spacing: 0.05em
            color: #ffffff
            display: inline
            line-height: 1
            pointer-events: none
        p
            font-family: BurbankSmall-Black, sans-serif
            text-transform: uppercase
            font-size: 2em
            color: #ffffff
            line-height: 1.5
            +respond-max-width(xs)
                font-size: 1.5em
    .errorHeader
        display: block
        max-width: 66em
        position: relative
        margin: 0 auto 1em
    .errorBody
        max-width: 600px
        margin: 4em auto
        +respond-max-width(sm)
            margin: 1em auto

