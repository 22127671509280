@import '../../../../styles/variables-mixins.sass'

.patch-notes-carousel
    .background
        width: 100%
        padding-bottom: 56.25%
        background-size: cover
    .slick-arrow
        width: 148px
        cursor: pointer
        i
            display: none
        &.slick-prev
            left: 0
            top: 0
            height: 100%
            right: auto
        &.slick-next
            right: 0
            top: 0
            height: 100%
            left: auto
    .slick-slide
        transition: transform 0.3s ease-in-out 0.1s
    &.left
        +respond-min-width(md)
            .slick-slide
                transform: translate3d(100px, 0, 0)
                transition: transform 0.3s ease-in-out
    &.right
        +respond-min-width(md)
            .slick-slide
                transform: translate3d(-100px, 0, 0)
                transition: transform 0.3s ease-in-out
