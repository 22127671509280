@import '../../../../styles/variables-mixins.sass'

.battlepass-emotes
    position: relative
    max-width: 1440px
    margin: 5% auto 0
    &.visible
        .emotes-image
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
    .emotes-image
        width: 85%
        margin: 0 auto
        opacity: 0
        display: block
        &.absolute
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
    .emotes-content
        width: 100%
        text-align: center
        z-index: 1
        margin-bottom: 5%
        +respond-max-width(xs)
            display: flex
            justify-content: center
        .animate-string
            h1
                font-size: 4vw
                color: #000
                +respond-max-width(xs)
                    font-size: 7vw
                    width: 90vw
                    text-align: center
        p
            margin-top: 2vw
            font-size: 1.5vw
            width: 25vw
            +respond-max-width(xs)
                font-size: 2.5vw
    .emote-videos-wrapper
        .battlepass-emote-video
          height: 100%
          position: relative
          padding-bottom: 56.25%
          +respond-max-width(sm)
              margin-bottom: 1em
          .icon-play
            +respond-max-width(md)
              font-size: 3em
          .background-video
            background-color: #fff
            width: 100%
            .overlay, .play-pause-overlay
              background: transparent
          video 
            width: 100%
          .video-still 
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            padding-bottom: 56.25%
            background-size: cover
            opacity: 1
            .bp-videoplay
              position: absolute
              top: 50%
              left: 0
              right: 0
              transform: translateY(-50%)
              margin: 0 auto
            &.fade 
              opacity: 0
              transition: opacity 0.5s ease-in-out


           
@keyframes dance-in
  0%
    opacity: 0
  10%
    opacity: 0
  10.1%
    opacity: 1
  10.2%
    opacity: 0
  20%
    opacity: 0
  20.1%
    opacity: 1
  20.6%
    opacity: 0
  30%
    opacity: 0
  30.1%
    opacity: 1
  30.5%
    opacity: 1
  30.6%
    opacity: 0
  45%
    opacity: 0
  45.1%
    opacity: 1
  50%
    opacity: 1
  55%
    opacity: 1
  55.1%
    opacity: 0
  57%
    opacity: 0
  57.1%
    opacity: 1
  60%
    opacity: 1
  60.1%
    opacity: 0
  65%
    opacity: 0
  65.1%
    opacity: 1
  75%
    opacity: 1
  75.1%
    opacity: 0
  77%
    opacity: 0
  77.1%
    opacity: 1
  85%
    opacity: 1
  85.1%
    opacity: 0
  86%
    opacity: 0
  86.1%
    opacity: 1
  100%
    opacity: 1

@keyframes bounce-left
  0%
    transform: translateX(-48px)
    animation-timing-function: ease-in
    opacity: 1
  24%
    opacity: 1
  40%
    transform: translateX(-26px)
    animation-timing-function: ease-in
  65%
    transform: translateX(-13px)
    animation-timing-function: ease-in
  82%
    transform: translateX(-6.5px)
    animation-timing-function: ease-in
  93%
    transform: translateX(-4px)
    animation-timing-function: ease-in
  25%,
  55%,
  75%,
  87%,
  98%
    transform: translateX(0px)
    animation-timing-function: ease-out
  100%
    transform: translateX(0px)
    animation-timing-function: ease-out
    opacity: 1

.chapter-2-season-3,
.ch2-s3
  .battlepass-emotes
    margin: 3em auto 0
    .emotes-content
      margin-bottom: 0
      h1
        color: #0086cb
        font-size: 5em
        +respond-max-width(xs)
          font-size: 3em
    .emote-videos-container
      max-width: 1440px
      .emote-videos-wrapper
        +respond-max-width(xs)
          .mobile-no-padding
            padding: 0
        .battlepass-emote-video
          padding-bottom: 100%  
          
          .video-still
            padding-bottom: 100%
            background-position: center
