@import '~epic-fortnite-styles/common-variables.sass'

.bingResults
    .search-filters
        margin: 1em 0 2em
        text-align: left
        padding: 0
        list-style: none
        li.search-filter-element
            padding-left: 0
            display: inline-block
            border-bottom: none
            font-family: Brutal_Bold,sans-serif
            float: none
            margin-right: 20px
            color: white
            transition: color 0.2s ease-in-out
            text-decoration: none
            a
                color: white

            &.active a
                color: $accent-color

            &:hover a
                color: $accent-color
                text-decoration: none


    .bingLoader
        margin: 80px auto
        width: 85px
        background: url('~epic-fortnite-react/images/loading/FN-spin.gif') no-repeat center
        background-size: cover
        height: 5em
        border: 0
    .bingSearchBar
        position: relative
        height: 40px
        input
            display: block
            border: 1px solid $lightGray
            padding: 0 0 0 1em
            width: calc(100% - 108px)
            height: 100%
            color: $invert-text-color
            @include respond-max-width(xs)
                width: calc(100% - 70px)
        button
            position: absolute
            padding: 0
            border: 0
            height: 100%
            width: 100px
            background: $accent-color
            color: $invert-text-color
            right: 0
            top: 0
            font-size: 0.8em
            font-weight: 500
            text-transform: uppercase
            text-align: center
            transition: background 0.2s ease-in-out
            &:hover
                background: $accent-color-hover
            @include respond-max-width(xs)
                font-size: 0.6em
                width: 60px
    .bing-branding
        padding: 0 2em
        background-color: $white
    .pagination
        @include clearfix
        width: 100%
        button
            border: 0
            font-size: 3em
            background: transparent
            color: $darkGray
            transition: 0.2s ease-in-out
            &:hover
                color: $accent-color-hover
    .no-result
        background-color: $white
        padding: 2em
    .nextResults
        float: right
    .prevResults
        float: left
    .noResults
        color: $darkGray
        margin: 1em 0
.ar
    .bingResults
        .bingSearchBar
            input
                padding: 0 1em
                float: left
                width: calc(100% - 108px)
                @include respond-max-width(xs)
                    width: calc(100% - 70px)
    .nextResults, .prevResults
        span
            font-family: FontAwesome !important
