@import '../../../../styles/variables-mixins.sass'

.text-block
    display: inline-block
    max-width: 21em
    transform: translateY(-8em)
    +respond-max-width(sm)
        max-width: 100%
        transform: translateY(0)
    &.padding
        transform: translateY(0)
        padding-top: 6em
        +respond-max-width(sm)
            padding-top: 0
    h2
        font-size: 2em
        i
            font-family: $font_1
            font-size: 0.5em
            color: $white
            font-style: normal
            display: inline-block
            position: relative
            z-index: 1
            vertical-align: top
            margin-right: 0.5em
            padding:  0.5em 0.6em 0.3em 0.6em
            border-radius: 50%
            background: $blue

    p
        font-size: 0.85em
        font-weight: 600
        padding-left: 1em
