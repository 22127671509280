@import '../../../../styles/variables-mixins.sass'

.hero-section
    position: relative
    text-align: center
    color: #ffffff
    max-width: 900px
    padding: 4em
    padding-bottom: 0
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
    .call-out
        margin-top: 30%
    +respond-max-width(xs)
        padding: 1em
        margin-top: 200px
        height: auto
    h1
        font-size: 100px
        line-height: 80px
        +respond-max-width(xs)
            font-size: 48px
            line-height: 50px
    h3
        font-size: 21px
        line-height: 28px
        text-transform: uppercase
        +respond-max-width(xs)
            font-size: 18px
    #stw-play-now
        background-color: #EF45EC !important
        color: #ffffff
        font-size: 20px
        line-height: 50px
        &:after
            display: none
    .stw-header__text
        color: #EF45EC
        font-family: $font_2
        font-size: 50px
        font-style: italic
        margin-top: 0
        margin-bottom: 10px
        text-transform: uppercase
body.ja
    .hero-section
        h3
            line-height: 1