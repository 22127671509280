@import '../../../../styles/variables-mixins.sass'

.chapter2-header
    position: relative
    height: 80vh
    width: 100%
    background-position: center
    background-size: cover
    background-repeat: no-repeat
    +respond-max-width(xs)
        height: calc(177vw - 3.7em)
    .ch2-header-image
        width: 100%
    .content
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        color: white
        display: flex
        justify-content: center
        .animated-content
            display: flex
            align-items: center
            flex-direction: column
            +respond-max-width(xs)
                text-align: center
            h1
                margin: 0 auto
                width: 20vw
                font-size: 5vw
                +respond-max-width(xs)
                    width: 70vw
                    font-size: 16vw
            .badge-img img
                margin-bottom: 1em
                width: 10vw
                +respond-max-width(xs)
                    width: 25vw
            .logo-img img
                width: 65vw
                margin-bottom: -8%
                margin-top: 4%
                +respond-max-width(xs)
                    width: 150vw
        .play-wrapper
            // transition: all 0.3s linear
            position: absolute
            right: 3vw
            bottom: 15%
            display: flex
            align-items: center
            // cursor: pointer
            +respond-max-width(xs)
                flex-direction: column
                bottom: 5%
                right: unset
            h3
                margin-right: 1vw
                font-size: 1.8vw
                +respond-max-width(xs)
                    font-size: 5vw
            .videoPlayButton
                font-size: 0.8vw
                margin: 0 auto
                +respond-max-width(xs)
                    font-size: 3vw
                &:hover
                    transform: scale(1.1) !important
