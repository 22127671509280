@import '../../../../styles/variables-mixins.sass'

.android-downloadpage
    position: relative
    color: $text-color
    padding: 2em 0
    margin: 0 auto
    min-height: 90vh
    img
        margin-bottom: 1em
    h1
        text-transform: none
        font-size: 2em
        letter-spacing: 1px
        max-width: 12em
        margin: 0 auto 0.5em
        +respond-max-width(xs)
            font-size: 1.7em
    p
        color: $text-color
        font-size: 1em
        max-width: 20em
        margin: 0 auto 0.5em
    .supportSteps
        margin: 1em 0
    .settings-message
        max-width: 27em
        margin: 0 auto
        padding: 0 1em
        font-weight: bold
        font-family: $font_2
        line-height: 1.2
        color: #000000
        text-transform: uppercase
        ol
            font-size: 1em
            padding-left: 1.4em
            margin: 0
            li
                margin-bottom: 0.5em
    .egIcon
        width: 220px
        display: block
        margin: 20px auto
        img
            width: 100%
    .support
        max-width: 27em
        margin: 0 auto
        padding: 0 1em
        span
            color: $yellow
            font-weight: 800
            font-size: 1em
            padding-left: 0.3em
            font-family: $font_2
            text-transform: uppercase
            i
                color: $yellow
                display: inline-block
                margin: 0 0.5em
                transform: rotate(90deg)

        p
            text-align: center
            span
                color: $yellow
                font-weight: 800
                font-size: 0.9em
                padding-left: 0.3em
                i
                    color: $yellow
                    display: inline-block
                    margin: 0 0.5em
                    transform: rotate(90deg)
        .drawer
            display: none
            p
                font-size: 0.9em
                max-width: 100%
                margin: 0
            a
                color: $yellow
                font-weight: 800
                cursor: pointer
        &.active
            span i,
            ol li span i
                transform: rotate(-90deg)
            .drawer
                display: block
    .copy
        a
            color: $yellow !important
            text-decoration: underline
            &:hover
                color: $yellow
        h4
            font-family: $font_1
            text-transform: none
            font-size: 1.5em
            margin: 1em auto
    .btn.btn-display
        opacity: 0
        animation: appear 0.3s ease 1.5s forwards !important

    &.notSupported
        padding-top: 5em
    &.oldFlow
        h1
            font-size: 2.5em
            text-transform: uppercase
            line-height: 1
        .description
            font-size: 1.75em
            text-transform: uppercase
            color: #000000
            font-family: $font_2
            line-height: 1

    .egsDownloadImg
        width: 90%
        margin-top: 20px
        max-width: 220px

    &.newFlow
        .copy
            width: 100%
            max-width: 16em
            margin: 0 auto
            p
                font-size: 0.9em
                line-height: 1.4
            &.spacing
                max-width: 18em
                margin: 45% auto 0
        .btn.btn-display
            +respond-max-width(xs)
                font-size: 1.5em
                padding: 0 1em

.ru
    .android-downloadpage
        .btn.btn-display
            +respond-max-width(xs)
                font-size: 1.5em

.ja
    .android-downloadpage
        .btn.btn-display
            +respond-max-width(xs)
                font-size: 1.2em
@keyframes appear
    0%
        opacity: 0
        transform: translateY(50px)
    100%
        opacity: 1
        tranform: translateY(0)
