@import '~epic-fortnite-styles/common-variables.sass'
@import '../../../../styles/variables-mixins.sass'
    
.help-center-wrapper
    // Generic styles - Should be extracted out once we are ready to develop the full theme.
    h1
        font-size: 100px
        +respond-max-width(md)
            font-size: 85px
        +respond-max-width(sm)
            font-size: 70px
    h2
        font-size: 81px
        +respond-max-width(md)
            font-size: 62px
        +respond-max-width(sm)
            font-size: 55px
    h3
        font-size: 48px
        +respond-max-width(md)
            font-size: 40px
        +respond-max-width(sm)
            font-size: 32px
    h4
        font-size: 27px
        +respond-max-width(md)
            font-size: 24px
        +respond-max-width(sm)
            font-size: 18px
    // End generic styles

    color: #fff
    padding: 50px 0px 0px 120px
    +respond-max-width(md)
        padding: 0

    .help-center-container
        display: flex
        padding-right: 0px
        .help-center-column
            flex: 1

    .content-left
        padding-bottom: 50px
        .main-title
            margin: 50px 0
            +respond-max-width(md)
                margin: 30px 0
                text-align: center


    .content-right
        position: relative
        right: 0
        display: block
        overflow: hidden
        +respond-max-width(md)
            display: none

        img
            background-size: cover
            background-repeat: no-repeat
            width: 70%
            position: absolute
            right: 10%
            top: 50px
    
    .hero-container-mobile
        display: none
        width: 100%
        overflow: hidden
        +respond-max-width(md)
            display: block
            height: 1000px
        +respond-max-width(xs)
            display: block
            height: 700px
        .hero-image-mobile
            display: block
            background-size: contain
            background-repeat: no-repeat
            width: 100%
