@import '~epic-fortnite-styles/variables-mixins.sass'

.island-steps
    h1 
        margin: .3em 2em 1.3em 2em
        color: $light-blue
        font-style: italic
        line-height: 1.1
        text-align: center
        +respond-max-width(xs)
            margin: 1em 0 .5em 0
    p
        font-size: 1em
        line-height: 1.42857143
    padding: 5% 10%
    font-size: 16px
    position: relative
    +respond-max-width(xs)
        font-size: 14px
        padding: 5%
        padding-bottom: 4em
    img
        width: 100%
    .island-step-wrapper
        display: flex
        +respond-max-width(xs)
            flex-wrap: wrap
        .island-step-description
            padding: 2%
            flex: 4 4 40%
            +respond-max-width(xs)
                flex: 1 1 100%
            h2
                margin-top: 0
                color: $light-blue
                font-style: italic
                font-size: 1.375em
                line-height: 1.1
                +respond-max-width(xs)
                    margin-top: 1em
            h3
                margin-top: 0
                font-size: 2.75em
                line-height: 85%
                margin-bottom: 10px
                line-height: 1.1
                +respond-max-width(md)
                    font-size: 2em
            .copyToClipboardWrapper
                h2.copyToClipboard
                    color: rgb(51, 51, 51)
                    font-style: normal
                    font-size: 2.75em
                    padding-top: 1em
                    letter-spacing: 10px
                    // transition: all 0.3s linear
                    +respond-max-width(xs)
                        letter-spacing: 5px
                    &:hover
                        // text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15), 2px 2px 2px rgba(199,48,187,0)
        .island-step-img
            padding: 2%
            flex: 6 6 60%
            +respond-max-width(xs)
                flex: 1 1 100%
