@import '../../../../styles/variables-mixins.sass'

.downloadView
    display: flex
    align-items: center
    min-height: 90vh
    background: url('../../../../images/download-bg.jpg') center no-repeat
    background-size: cover
    .site-block
        text-align: center
        max-width: 100%
        h1
            font-size: 2em
            font-weight: 200
            margin-top: 0.5em
            margin-bottom: 0.75em
        p
            font-weight: 200
        +respond-max-width(xs)
            padding: 30px 20px
            border-radius: 0
            margin: 0 auto
    .selectPlatform
        font-size: 13px
        font-weight: 400
        margin: 16px 0
    .btn
        display: inline-block
        width: 100%
        padding: 0 0.5em
        +respond-max-width(xs)
            margin-right: 0
            margin-bottom: 1.5em
    .mobile-faq-link
        font-family: $font_2
        font-size: 1.5em
        margin: 10px 0
        display: block
    ul.downloadLegalTags
        padding: 0
        max-width: 400px
        font-size: 0.8em
        margin: 0 auto
        li.tag-container
            display: inline-block
            p
                margin: 0
            .bottomImg
                display: inline-block
                max-height: 7em
                max-width: 100%
                margin: 0 auto
                padding: 0.2em
    .returnLink
        transition: 0.1s ease-in-out
        font-weight: 200
        font-size: 14px
        cursor: pointer
        margin-top: 1em
        display: block
    .epicLogo
        width: 80px
        height: 100px
        margin: 20px auto
        background: url('../../../../images/logo-epic.png') center no-repeat
        background-size: contain
    .downloadButtons
        display: inline-flex
        margin-bottom: 2em
        +respond-max-width(sm)
            max-width: 20em
            margin: 0 auto
        .button
            width: 8em
            height: 8em
            margin: 0.5em
            padding: 1.8em 0 0
            background-color: $blue
            display: inline-block
            color: $text-color
            transition: background-color 0.3s ease-in-out
            +respond-max-width(sm)

            &:hover
                background-color: $lightBlue
            span
                font-size: 0.8em
            i
                color: $text-color
                font-size: 3.5em
                display: block
    .gfnLogo
        height: 80px
        margin: -0.5rem
        background: url('../../../../images/geforceNowIcon.png') center no-repeat
        background-size: 80%
    .mobileMsg
        margin: 10px 0 40px 0
    .epicLogo
        display: none
    .download-header-container
        h1
            font-size: 2.8em
            margin-top: 0.4em
            margin-bottom: 0
        h2
            font-size: 1.8em
        h3
            font-size: 1.4em
            margin-top: 0.2em
            font-weight: 400

    .newsletter-box
        padding: 0px 1.25em 1.25em
        h2
            max-width: 20em
        .newsletter-select
            text-align: left
            margin: 1.25em
        .custom-checkbox
            width: 1.5em
            height: 1.5em
            border: 2px solid $accent-color
            display: inline-block
            margin-right: 0.8em
            position: relative
            top: 0.4em
            cursor: pointer
            position: relative
            &:hover
                border-color:  darken($accent-color, 5%)
            &.interest-select:before
                content: '✓'
                position: absolute
                top: -0.5em
                left: 0
                font-size: 2em
                color: $darkGray
        .subscribe-message
            padding: 1em
            &.success
                background-color: $lightGreen
                i
                    color: darken($lightGreen, 40%)
            &.failed
                background-color: $lightPink
                i
                    color: darken($lightPink, 40%)
            i
                margin-right: 0.5em
                font-size: 2em
                position: relative
                top: 0.2em
