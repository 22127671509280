@import '../../../../styles/variables-mixins.sass'

.inviteView
    background: url('../../../../images/sfs/background.jpg') center no-repeat
    background-size: cover

$darkBlue: #002A44
$lightBlue: #00C6FF
$red: $red

.redeemContainer
    .bk-img
        +respond-max-width(md)
            opacity: 0.4
    .parallax-container
        width: 60%
        float: right
        +respond-max-width(sm)
            display: none
    .loadscreen-overlay, .load-indicator
        width: 100vw
    .redeemView
        display: flex
        flex-direction: column
        justify-content: center
        padding-left: 10%
        min-height: 100vh
        opacity: 0
        width: 100%
        min-width: 600px
        animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s forwards
        padding-top: 2em
        +respond-max-width(xs)
            justify-content: flex-end
            padding: 0 20px 50px
            min-width: auto
            width: 100%
            min-height: 150vh
        @media screen and (max-width:320px)
            min-height: 220vh
        @media screen and (min-height: 900px)
            min-height: 100vh
        .site-block-page-title
            display: none
        .site-block
            background: none
            color: #ffffff
            border: none
            padding: 0
            margin: 0
            position: relative

            p
                font: $font-h5
                text-transform: uppercase
            +respond-max-width(xs)
                text-align: center
            .site-block-header
                border: none
                padding: 0
                +respond-max-width(xs)
                    margin-bottom: 0
                .site-block-title
                    font: $font-h2
                    margin: 1em 0 0 0
                    +respond-max-width(md)
                        font: $font-h3
                    +respond-max-width(sm)
                        font: $font-h3

            .itemTitle
                color: #ffffff
        input
            margin: 2.5em 0
            border: none
            background: #ffffff
            text-align: left
            color: #000000
            +respond-max-width(xs)
                max-width: 100%
                margin: 1.5em 0
        .errorMessage
            text-align: left
        .buttonContainer
            text-align: left
            margin-bottom: 2.5em
            +respond-max-width(xs)
                text-align: center
                margin: 0 auto
            .btn
                width: 200px !important
                padding: 0
                +btn-hover-transform
                +respond-max-width(xs)
                    margin: 1em auto
        .posa-content
            padding: 1% 3%
            height: 15em
            +respond-max-width(sm)
                height: 100%
            +respond-max-width(xs)
                margin: 0 auto
            a
                color: $yellow
                font-size: 1em
            .posa-title
                margin: 0 0 1.5rem 0
                padding: 0
            .posa-subtitle
                margin: 0 0 1.5rem 0
                padding: 0
                a
                    font-family: $font_1
                    color: $yellow
                    line-height: 1
                +respond-max-width(md)
                    font-size: 1.2em
        .overlay
            .overlay-close
                &:hover
                    color: $yellow
            .overlay-content
                h1
                    font-size: 2.5em
                a
                    color: $yellow
                    font-size: 1em
    .site-block-content
        position: relative
        color: #ffffff
        font-family: $font_1
        font-size: 2rem
        +respond-max-width(xs)
            text-align: center
            font-size: 1.2em
        a
            font-family: $font_1
            font-size: 2rem
            color: $yellow

    .btn.btn-default
        background: #000000
        &:hover
            background: #ffffff
            color: #000000
    .confirmStage
        .errorPrimary
            font: $font-h5
            text-transform: uppercase
            color: #ffffff
        .itemTitle, .itemDescription, p.centered, .errorData
            text-align: left
            +respond-max-width(xs)
                text-align: center
        hr
            margin-left: 0
            +respond-max-width(xs)
                margin-left: auto
    .successStage
        .site-block-page-title
            font: $font-h2
            padding: 0
            text-align: left
            display: block
            +respond-max-width(xs)
                text-align: center
                font-size: 3.5em
        .site-block
            .site-block-header
                .site-block-title
                    font: $font-h4
        .btn
            margin: 0
body.ar
    .redeemContainer
        direction: rtl
        .redeemView
            padding-left: 0
            padding-right: 10%
            +respond-max-width(xs)
                padding: 0 20px 50px
            input
                text-align: right
            .site-block
                text-align: right
                +respond-max-width(xs)
                    text-align: center
            .buttonContainer
                text-align: right
                +respond-max-width(xs)
                    text-align: center
        .parallax-container
            float: left
        .posa-content
            img
                margin-left: 0
                margin-right: 4em
                +respond-max-width(xs)
                    margin-right: 0

body.ja
    .redeemContainer
        .redeemView
            .site-block
                p
                    font-family: $font_jaBody
                .site-block-title
                    font-family: $font_jaHeader
                    +respond-max-width(xs)
                        font-family: $font_jaHeader
        .confirmStage
            .errorPrimary
                font-family: $font_jaBody
            .successStage
                .site-block-page-title
                    font-family: $font_jaHeader
                .site-block
                    .site-block-header
                        .site-block-title
                            font-family: $font_jaHeader

body.ko
    .redeemContainer
        .redeemView
            .site-block
                p
                    font-family: $font_ko
                .site-block-title
                    font-family: $font_ko_art
                    +respond-max-width(xs)
                        font-family: $font_ko_art
        .confirmStage
            .errorPrimary
                font-family: $font_ko
            .successStage
                .site-block-page-title
                    font-family: $font_ko_art
                .site-block
                    .site-block-header
                        .site-block-title
                            font-family: $font_ko_art

body.ru
    .redeemContainer
        .redeemView
            .site-block
                p
                    font-family: $font_1
                .site-block-title
                    font-family: $font_1
                    +respond-max-width(xs)
                        font-family: $font_1
        .confirmStage
            .errorPrimary
                font-family: $font_1
        .successStage
            .site-block-page-title
                font-family: $font_1
                font-size: 3em
            .site-block
                .site-block-header
                    .site-block-title
                        font-family: $font_1

body.de
    #redeemSuccessView
        .redeemContainer
            .buttonContainer .btn
                width: 300px !important
                padding: 0 5%

body.ar,
body.es_ES,
body.es_MX,
body.pt_BR,
body.de,
body.fr,
body.it,
body.ja,
body.ko,
body.ru,
body.pl,
body.tr
    .redeemContainer
        .posa-content
            height: 16.5em
            +respond-max-width(sm)
                height: 100%
            .posa-title h1
                font-size: 2em
                +respond-max-width(md)
                    font-size: 1.8em
                +respond-max-width(xs)
                    font-size: 1.5em

@keyframes textAnimateIn
    0%
        opacity: 0
        transform: translateY(100px)
    100%
        opacity: 1
        transform: translateY(0)

@keyframes ctaAnimateIn
    0%
        opacity: 0
        transform: skewX(30deg)
        transform-origin: 'center bottom'
    100%
        opacity: 1
        transform: skewX(0)

@keyframes disappear
    0%
        opacity: 1
        transform: translateY(0) scale(1)

    100%
        opacity: 0
        transform: translateY(-100px) scale(0.7)
