@import '~epic-fortnite-styles/mixins.sass'

#mainCarousel
    position: relative
    +respond-max-width(sm)
        clip-path: none
    .slick-slide
        width: 100%
        .caption
            h1, h3, img, button
                opacity: 0
    .slick-active
        z-index: 10
        .caption
            img
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s forwards
            h1
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.4s forwards
            h3
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s forwards
            button
                animation: ctaAnimateIn 0.8s cubic-bezier(0.5, 0, 0, 1) 0.5s forwards
    .slide
        height: 60vh
        position: relative
        overflow: hidden
        +respond-max-width(sm)
            height: 90vh
            min-height: auto
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-end
            padding-top: 10vh
            padding-bottom: 12vh
        .caption
            position: absolute
            color: #fff
            max-width: 35vw
            top: 50%
            transform: translateY(-50%)
            padding-top: 50px
            +respond-max-width(sm)
                max-width: 450px
            img
                width: 100px
                margin-bottom: 20px
                +respond-max-width(sm)
                    width: 60px
                    margin-left: auto
                    margin-right: auto
                    filter: drop-shadow(0 0 5px rgba(black, 0.5))
            button, a
                margin-top: 40px
                transition: 0.5s ease-in-out
                +respond-max-width(sm)
                    margin-top: 1em
            h1
                font-size: 2vw
                line-height: initial
                margin: 0
                transition: 0.5s ease-in-out
                +respond-max-width(sm)
                    text-align: center
                    font-size: 21px
            h3
                font-size: 5vw
                line-height: 1
                margin: 0
                +respond-max-width(sm)
                    font-size: 60px
            &.right
                right: 200px
            &.left
                left: 200px
                text-align: left
            &.center
                text-align: center
                left: 0
                right: 0
                margin-left: auto
                margin-right: auto
                img
                    margin-left: auto
                    margin-right: auto
            +respond-max-width(sm)
                position: relative
                text-align: center
                top: 0
                left: 0 !important
                right: 0 !important
                margin-left: auto
                margin-right: auto
                padding: 0 1em
                transform: translateY(0)
                width: auto !important
        .play-wrapper
            // transition: all 0.3s linear
            z-index: 1
            position: absolute
            right: 3vw
            bottom: 15vh
            display: flex
            align-items: center
            // cursor: pointer
            +respond-min-width(lg)
                top: 80%
                bottom: auto
            +respond-max-width(xs)
                position: static
                flex-direction: column
                margin-top: 10px
            h3
                margin-right: 2vw
                font-size: 2vw
                color: #ffffff
                filter: drop-shadow(0 5px 3px rgba(black, 0.2))
                +respond-max-width(xs)
                    font-size: 5vw
            .videoPlayButton
                font-size: 0.6em
                +respond-max-width(xs)
                    font-size: 2vw
                &:hover
                    transform: scale(1.1) !important
        &.feature
            .caption
                max-width: 30vw
                +respond-max-width(sm)
                    max-width: 450px
                h1
                    font-size: 7vw
                    line-height: 0.8
                    margin: 0
                    transition: 0.5s ease-in-out
                    filter: drop-shadow(0 5px 3px rgba(black, 0.2))
                    +respond-max-width(sm)
                        font-size: 60px
                        text-shadow: 0 0 20px rgba(0, 0, 0, 0.2)
            +respond-max-width(sm)
                justify-content: space-between
    .carousel-dots
        position: absolute
        left: 0
        right: 0
        text-align: center
        padding: 0
        bottom: 50px
        z-index: 10
        margin: 0 auto
        li
            position: relative
            display: inline-block
            margin: 0 0.5em
            button
                cursor: pointer
                outline: none
                height: 10px
                width: 160px
                border: none
                background: rgb(178, 234, 252)
                color: transparent
                opacity: 0.3
                transition: 0.5s ease-in-out
                +respond-max-width(sm)
                    width: 80px
            &.slick-active
                button
                    opacity: 1
body.ja
    #mainCarousel
        .slide
            .caption
                h3, h1
                    line-height: 1

body.tr
    #mainCarousel
        .slide
            .caption
                h3
                    line-height: 1
                    margin-top: 15px

body.ar
    #mainCarousel
        .slide
            .caption
                &.right
                    left: 200px
                    right: unset
                &.left
                    left: unset
                    right: 200px
body.ko
    #mainCarousel
        .slide
            .caption
                h1
                    word-break: keep-all !important

body.ru, body.ko, body.ja, body.en_US
    #mainCarousel
        .slide
            .caption
                @media screen and (min-width: 1200px) and (max-height: 800px)
                    h1
                        font-size: 4em !important

body.ru
    #mainCarousel
        .slide
            .caption
                h3
                    text-align: center
                button
                    @media screen and (max-width: 800px)
                        font-size: 1.3em
