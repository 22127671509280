.FeaturedGridWithSlider
    .card-body
        padding: 10px
    .row
        margin: 0
    .grid-actions
        text-align: center
        padding-bottom: 1em
        button
            display: inline
            margin-left: 0.5em
            background: url('../../images/creativeMode/arrow-r.png')
            width: 60px
            height: 60px
            border: none
            background-size: contain
        button:first-child
            display: inline
            margin-right: 0.5em
            background: url('../../images/creativeMode/arrow-l.png')
            background-size: contain
