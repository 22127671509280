@import '../../../../styles/variables-mixins.sass'

.noheader
    // +setTextColors()
    height: 100%
    .page-body
        height: 100%
        min-height: 800px
    .container
        position: absolute
        padding: 0
        top: 0
        right: 0
        width: 100%
        z-index: 1000
        .full-site-container
            display: flex
            justify-content: flex-end
            margin: 0 1rem 0 1rem
        .locale-wrapper
            width: 128px
            float: right
            padding-right: 1em
        .fulls
            +respond-max-width(sm)
                width: 4.5em
                text-align: right
                float: left
                margin: 3.35vw 3.5vw 0 2vw
            a
                font-weight: bold
                color: white
                font-size: 1vw
                +respond-max-width(sm)
                    font-size: 0.5em

            a:hover
                color: #ff0!important

        .fulls.right
            float: right
        #locale
            float: right
            text-align: right
            width: 128px
            background-color: unset
            font-size: 1.2em
            i
                color: white

//         .lines.accent-text-color
//             &, &:after, &:before
//                 background-color: $accentTextColor
        .locale-list
            background-color: #2a2a2a
        #egh
            background-color: none
            background: none
            height: 4vw
            margin: 3.5vw 0 0 3.5vw
            .propLogo
                width: 12.25vw
                background-size: cover
                height: 4vw
                margin: 0
                cursor: hand
                cursor: pointer

        //tablet mode portrait needs a little more room
        @media only screen and (max-width: 950px) and (min-width: $screen-sm)
            #locale
                width: 60px
                min-width: 60px
                a
                    width: 60px
                    min-width: 60px
                    .label
                        display: none
                &:after
                    right: 12px

        #locale
            padding: 0
            ul
                opacity: 0
                visibility: hidden
                overflow: hidden
                height: 0
                padding: 0.5em 0
                width: auto
                position: absolute
                top: 1.5em
                left: -60px
                +respond-tablet
                    right: -48px
                    left: initial
                &.ul-right
                    right: -60px
                    left: initial
                &.ul-right.ul-right-0
                    right: 0px
                    min-width: 180px
                    z-index: 4
                li
                    float: none
                    display: block
                    width: 100%
                    margin: 0.1em 0
                    text-align: center
                    a
                        padding: 0 0.9em
                        text-align: center
                        width: auto
                        span, p
                            font-size: .625em
                            text-transform: uppercase
                            line-height: 12px
                            height: 100%
                            +alignMiddle

            &:hover
                a
                    opacity: 1
                ul
                    opacity: 1
                    height: auto
                    visibility: visible
                    transition: all .1s ease-in-out

div.page-body
    h1, h2, h3, h4, h5, h6
        font-family: $font_2

html[lang="ar"]
    div.page-body
        direction: rtl

.fortnite-wrapper
    button.btn.btn-red, button.btn.btn-red:hover
        background: #e8473d
        background-color: #e8473d

    button.btn.btn-pink, button.btn.btn-pink:hover
        background: #f804ff
        background-color: #f804ff
