@import '~epic-fortnite-styles/common-variables.sass'

.no-touch
    .fn-mobile-overlay
        .overlay-container
            .content-question,
            .content-selection
                .btn:hover
                    background-color: darken($yellow, 10%)
.fn-mobile-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 9999999
    width: 100%
    height: 100%
    background-color: rgba(#106ea0, 0.98)
    .bkgrd
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: url(~epic-fortnite-react/images/fnMobile/bolts.png) no-repeat center
        background-size: cover
        z-index: 0
        +respond-max-width(xs)
            background-image: url(~epic-fortnite-react/images/fnMobile/bolts_m.png)
    .close-button
        display: inline-block
        background-color: #1ac5d9
        outline: none
        border: none
        margin-bottom: 0.5em
        +respond-max-width(md)
            right: 1em
        +respond-max-width(sm)
            top: 0.2em
        i
            display: inline-block
            padding-top: 0.3em
            font-size: 1.2em

    .overlay-container
        +vertical-align(relative)
        width: 100%
        max-width: 50em
        z-index: 1
        margin: 0 auto
        @media screen and (max-height: 800px)
            margin: 2em auto 0
        +respond-max-width(sm)
            margin: 0 auto
            padding: 2em 15px
        .content-question,
        .content-selection,
        .content-error,
        .download-selection
            h2
                font-size: 5em
                color: $text-color
                line-height: 1
                @media screen and (max-height: 800px)
                    font-size: 2em
                +respond-max-width(xs)
                    font-size: 2em
                    margin-bottom: 0.5em
            p
                color: $text-color
                max-width: 23em
                margin: 1em auto

            .btn
                background-color: $yellow
                color: $invert-text-color
                margin: 0.5em
                padding: 0.5em 2em
                white-space: normal
                line-height: 1
                transition: background-color 0.2s ease-in-out
        .customized-answer-no
            max-width: 600px
            margin: 0 auto
            h1
                color: white
                font-size: 5em
        .content-selection,
        .download-selection
            h2
                margin: 0 auto 0.5em
                font-size: 4.5em
                max-width: 8em
                +respond-max-width(sm)
                    font-size: 2em
                +respond-handheld-max-width(sm, landscape)
                    max-width: 10em
            .btn-container
                max-width: 33em
                margin: 0 auto
                +respond-max-width(xs)
                    max-width: 23em
                +respond-handheld-max-width(sm, landscape)
                    max-width: 33em
                .btn
                    font-size: 1em
                    width: 10em
                    padding: 0.5em 0 0
                    margin: 0.5em
                    line-height: 1
                    height: 10em
                    overflow: hidden
                    i
                        font-size: 5em
                        display: block
                    span
                        font-family: $font_4
                        font-size: 0.8em
                        font-weight: 800
                        span
                            font-size: 1em
                            text-transform: none
                    +respond-max-width(sm)
                        width: 5em
                        height: 5em
                        i
                            font-size: 2.5em
                        span
                            font-size: 0.6em

        .content-error
            padding: 0 1em
            h2
                font-size: 3em
                margin-bottom: 0.5em
                @media screen and (max-height: 800px)
                    font-size: 2em
                +respond-max-width(xs)
                    font-size: 1.5em
                    margin-bottom: 1em
            .btn
                +respond-max-width(xs)
                    width: 100%
                    max-width: 10em
                    margin: 0 0 0.5em
                    padding: 0.5em
        .download-selection
            .btn-container
                max-width: 42em
                +respond-max-width(xs)
                    max-width: 100%
                +respond-handheld-max-width(xs, portrait)
                    max-width: 20em
