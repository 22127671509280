@import '~epic-fortnite-styles/variables-mixins.sass'

.copyToClipboardWrapper
    .copyToClipboard
        cursor: copy
    .copiedToast
        display: block
        height: 0
        opacity: 0

        &.copied
            animation: fade-in-and-out 2s ease-in-out both
        .icon-check
            margin-right: 10px
