@import '../../../../styles/variables-mixins.sass'

.chapter2s3-header-wrapper
    height: 90vh
    min-height: 1000px
    position: relative
    overflow: hidden
    +respond-max-width(md)
        height: 50vh
        min-height: 500px
    +respond-max-width(sm)
        height: 90vh
        max-height: auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-end
        padding-top: 10vh
        padding-bottom: 12vh
    .header
        height: 100%
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        +respond-max-width(xs)
            height: calc(177vw - 3.7em)
    .active.content
        .animated-content
            h1, h3
                transform: none
                transition: 0.5s ease-in-out
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s forwards
    .content
        position: absolute
        padding-top: 50px
        color: #fff
        left: 0
        right: 0
        width: 100%
        text-align: center
        top: 0
        transform: none

        height: 65vh

        +respond-max-width(md)
            height: 40vh

        +respond-min-width(sm)
            top: 15%
        +respond-min-width(md)
            top: 20%
        +respond-min-width(lg)
            top: 25%
        +respond-max-width(sm)
            height: 75vh
            max-height: auto
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-start
            padding-top: 10vh
            padding-bottom: 12vh

        .animated-content
            button, a
                margin-top: 0
                +respond-max-width(sm)
                    margin-top: 1vw
                    margin-bottom: 0
            img
                width: 30vw
                margin-bottom: 0
                +respond-max-width(sm)
                    width: 90%
            h1, h3
                opacity: 0
            +respond-max-width(xs)
                text-align: center
            h1
                font-size: 10vw
                line-height: 0.8
                font-style: italic
                padding-right: 2.5vw
                text-shadow: 0 0 40px rgba(255, 126, 0, 0.8), 0px 5px 8px rgba(255, 126, 0, 0.3)
                +respond-max-width(sm)
                    font-size: 64px
            h3
                position: relative
                z-index: 1
                font-size: 2vw
                line-height: 1.5
                text-shadow: 0 0 40px rgba(255, 126, 0, 0.8)
                letter-spacing: 5px
                transition: 0.5s ease-in-out
                margin: 0 0 10px 0
                +respond-max-width(sm)
                    font-size: 21px
                    letter-spacing: 2px
                    text-align: center
                    margin-top: 6.7vw
                +respond-max-width(xs)
                    margin-top: 28.8vw
            .badge-img img
                margin-bottom: 1em
                width: 10vw
                +respond-max-width(xs)
                    width: 25vw
            .logo-img img
                width: 65vw
                margin-bottom: -5%
                margin-top: -3%
                +respond-max-width(xs)
                    width: 150vw
        .play-wrapper
            // transition: all 0.3s linear
            position: absolute
            right: 3vw
            bottom: 15%
            display: flex
            align-items: center
            // cursor: pointer
            +respond-max-width(xs)
                flex-direction: column
                bottom: 10%
                right: unset
                left: 40vw
            h3
                margin: 0 1vw 0 0
                font-size: 1.8vw
                +respond-max-width(xs)
                    font-size: 3vw
                    text-align: center
            .videoPlayButton
                font-size: 0.8vw
                margin: 0 auto
                +respond-max-width(xs)
                    font-size: 3vw
                &:hover
                    transform: scale(1.1) !important
