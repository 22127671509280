@import '../../../../styles/variables-mixins.sass'
.flow-slide-container
    max-width: 27em
    margin: 0 auto
    &.inView
        .slick-slider
            .slick-active
                .slide-top
                    transform: translateY(0)
                    opacity: 1
                    transition-delay: 0.5s
                .slide-image
                    .placeholder-image
                        display: none
                    .animate-image
                        display: block
    .slick-slide
        .flow-slider
            display: flex
            flex-direction: column
            align-items: center
            .slide-top
                background: #ffffff
                width: 100%
                padding: 1em
                font-family: $font_0
                display: flex
                align-items: center
                transition: 0.2s all
                transform: translateY(-100px)
                opacity: 0
                .slide-step
                    color: #4ec1f9
                    white-space: nowrap
                    text-transform: uppercase
                    font-size: 2em
                .slide-title
                    color: #000000
                    padding-left: 1em
                    text-align: left
            .slide-image
                margin-top: 1.5em
                width: 100%
                position: relative
                .slide-placeholder
                    position: absolute
                    top: 0
                    bottom: 0
                    right: 0
                    left: 0
                    z-index: 10
                .placeholder-image
                    width: 100%
                    display: block
                .animate-image
                    width: 100%
                    display: none
    .slick-dots
        position: relative !important
        li
            border-color: transparent !important
            background: #81dcfa !important
            &.slick-active
                background-color: #ffffff !important
.ko
    .flow-slide-container
        .flow-slider
            .slide-top
                font-family: $font_ko_art
.ja
    .flow-slide-container
        .flow-slider
            .slide-top
                font-family: $font_jaHeader