@charset "UTF-8"
@import "../../../../styles/variables-mixins"
body.ar
    .slick-slider
        & > div,
        .pagination
            direction: ltr

body[data-ios="true"],
body[data-iphone="true"]
    #fortniteReactWrapper
        .slick-slider
            .pagination
                li
                    display: inline-block
                    button
                        height: auto !important
@mixin nav-top
    div.slick-next
        top: -43px
        height: 37px
        right: 0
    div.slick-prev
        right: 50px
        top: -43px
        left: inherit
        height: 37px
    div.slick-slider .slick-control
        width: 2em

.slick-slider
    .slick-control
        position: relative
        border: none
        color: $darkGray
        width: 3em
        font-weight: bold
        text-align: center
        transition: all 0.2s
        display: table
        height: 100%
        i
            font-size: 1.6em
            display: table-cell
            vertical-align: middle
    .slick-next
        right: -3em
    .slick-prev
        left: -3em
        i
            transform: rotate(-180deg)
    &.nav-top
        +nav-top
    +respond-max-width(lg)
        +nav-top
    +respond-max-width(md)
        div.slick-next
            right: -3px
            .slick-control
                width: 2em
        div.slick-prev
            right: 22px
            .slick-control
                width: 2em

    .slick-prev, .slick-next
        position: absolute
        display: block
        height: 100%
        top: 0
        z-index: 99
        &.slick-disabled
            display: none !important
    &:hover
        .slick-control
            &:hover
                color: $accent-color
    .pagination
        text-align: center
        li
            display: inline-block
            button
                display: inline-block
                width: 1em
                height: 1em
                border: 1px solid $siteBlockBorder
                margin: 1em
                border-radius: 50%
                background: transparent
                color: transparent
                +respond-max-width(xs)
                    margin: 0.75em
            &.slick-active
                button
                    background: $siteBlockBorder

