@import '../../../../styles/variables-mixins.sass'

.VBucksSection
    position: relative
    opacity: 1
    background-color: #000
    .content-wrapper 
        position: relative
        opacity: 0
        &.visible
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
            .vBucksImage
                animation: bounce-in-from-center 2s both
                animation: bounce-in-from-top 1.5s both
                animation-delay: 0.5s
    .background-section
        position: relative
        
        .backgroundImage
            width: 100%
            padding-bottom: 50%
            background-repeat: no-repeat
            background-size: cover
            background-position: center center
            margin: 0 auto
            display: block
            +respond-max-width(xs)
                padding-top: 10%
                top: 0
                background-size: 140%
        .vBucksImage
            position: absolute	      
            top: 50%	           
            transform: translateY(-15%)	            
            bottom: 0	           
            width: 40%	           
            right: 0	
            left: 0	
            margin: 0 auto	
            +respond-max-width(xs)	
                width: 80vw
          
    .VBucks-description
        text-align: center
        color: #fff
        margin-top: 5%
        padding-bottom: 10%
        +respond-max-width(md)
            padding-bottom: 20%
        +respond-max-width(xs)
            bottom: 0
        .animate-string
            margin: 0 auto
            h1
                margin: 0 auto
                font-size: 5vw
                +respond-max-width(xs)
                    font-size: 6vw
            p
                margin: 0 auto
                margin-top: 2vw
                font-size: 1.5vw
                width: 46vw
                +respond-max-width(xs)
                    width: 75vw
                    font-size: 2.5vw
    .vbucks-bottom-slice
        position: absolute
        width: 100%

.chapter-2-season-3,
.ch2-s3
    .VBucksSection
        background-color: #fff
        padding: 3em 0
        margin-top: 0
        +respond-max-width(md)
            padding: 1em 0
        .content-wrapper
            position: relative
            padding: 0 0 6em
            +respond-max-width(xs)
                padding: 1em 0 5em
            .background-section
                position: initial
                text-align: center
                .backgroundImage
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    padding-bottom: 0
                    clip-path: polygon(0% 5%, 20% 8%, 14% 4%, 100% 15%, 100% 98%, 85% 95%, 90% 98%, 0% 90%)
                    +respond-max-width(xs)
                        background-size: cover
                        padding: 0
                        clip-path: polygon(0% 5%, 100% 17%, 100% 100%, 80% 97%, 88% 100%, 0% 95%)
                .vBucksImage
                    position: relative
                    transform: none
                    top: 0
                    width: 55%
                    margin-bottom: 2em
                    +respond-max-width(xs)
                        width: 100%
                        margin-bottom: 1em
            &.visible 
                .vBucksImage
                    animation: none
            .VBucks-description
                margin-top: 0
                padding-bottom: 2em
                h1 
                    color: #01aeff
                    font-size: 4vw
                    +respond-min-width(lg)
                        font-size: 3.8em
                    +respond-max-width(xs)
                        font-size: 2em
                p 
                    font-size: 1.2vw
                    width: 100%
                    max-width: 40em
                    +respond-min-width(lg)
                        font-size: 1.4em
                    +respond-max-width(md)
                        font-size: 1.2em
                    +respond-max-width(xs)
                        width: 100%
                        max-width: 35em
                        padding: 0 1em
                        font-size: 1em
                +respond-max-width(xs)
                    padding-bottom: 0
                    
.ko
    .VBucksSection .content-wrapper .VBucks-description
        p 
            font-family: $font_ko_art !important
            font-size: 1.5em
.ja .VBucksFeature
    .vbucks-content-title
        font-size: 3em !important
        +respond-max-width(sm)
            font-size: 2.5em !important
.de .VBucksFeature 
    .vbucks-callout
        p
            font-size: 1em !important
