@import '../../../../styles/variables-mixins.sass'
.vbuckcardTerms
    background-color: white
    .content
        padding: 2em
        margin: 0 auto
        max-width: 940px
        +respond-max-width(xs)
            font-size: 14px
            padding: 5%
        a, p
            font-size: 100%
        ol, ul
            +respond-max-width(xs)
                padding-left: 3em
        img
            +respond-max-width(xs)
                width: 100%
                height: auto
        p > span
            white-space: unset !important
