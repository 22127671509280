@charset "UTF-8"
@import '../../../../styles/variables-mixins.sass'

.fortnite-wrapper
    .download-button
        &.btn-display
            background-color: transparent !important
        &.play-free
            color: $invert-text-color
            &:after
                background-color: $yellow
            &:hover, &:active, &:focus
                color: $invert-text-color
                &:after
                    background-color: darken($yellow, 5%)
            &.disabled
                &:after
                    animation: none
        .bolt
            position: absolute
            top: 0
            right: 0
            width: 3.5em
            height: 100%
            z-index: 2
            i
                color: $yellow
                font-size: 0.5em
                background: $invert-text-color
                position: absolute
                top: 0.5em
                right: 0.5em
                padding: 0.5em
                border-radius: 2em
                transform: scale(0.8)

