@import '../../../../styles/variables-mixins.sass'

.guide-menu-wrap
    background-color: #fff
    .menu-nav
        position: absolute
        top: -55px
        width: 100%
        display: flex
        a, button
            flex: 1
            color: #fff
            height: 55px
            line-height: 55px
            white-space: nowrap
            font-size: 18px
            text-overflow: ellipsis
            max-width: 25%
            overflow: hidden
        a
            background-color: #9095B6
            text-align: center
        button
            border: 0
            border-bottom: 2px solid transparent
            padding: 0
            &.active
                border-color: #FFFE55
            &:nth-child(2)
                background-color: #9a50d8
            &:nth-child(3)
                background-color: #3ddabb
            &:nth-child(4)
                background-color: #f36a22
    .menu
        display: none
        &.active
            display: block
    .sub-menu-wrap
        margin: 0 15px
    .sub-menu
        &:last-child .title
            border: 0
        &.active .title
            background-color: #E6EAED
            margin: 0 -15px
            padding: 10px 35px
        .title
            border-bottom: 1px solid #E6EAED
            display: block
            padding: 10px 20px
            color: $darkText
            font-size: 19px
            line-height: 1.5
    .sub-tab
        margin: 0 15px
        .title
            border: 0
            border-bottom: 1px solid #E6EAED
            text-align: left
            display: block
            padding: 10px 20px
            color: $darkText
            font-size: 19px
            line-height: 1.5
            &.active
                background-color: #E6EAED
                margin: 0 -15px
                padding: 10px 35px
            &:last-child
                border: 0
    .leaf-wrap
        background-color: #fff
        border-bottom: 1px solid #E6EAED
        font-size: 13px
    .link
        background-color: #fff
        padding: 8px 20px
        cursor: pointer
        display: block
        color: #2b2b2b
        &.active
            color: #b1b1b1
    +respond-min-width(xs)
        .menu-nav
            top: -65px
            a, button
                font-size: 25px
                height: 65px
                line-height: 65px
        .sub-menu .title, .sub-tab .title
            font-size: 22px
        .leaf-wrap
            font-size: 14px
    +respond-min-width(sm)
        .menu-nav
            a, button
                font-size: 23px
        .sub-menu .title, .sub-tab .title
            font-size: 18px
        .leaf-wrap
            font-size: 12px
    +respond-min-width(md)
        .menu-nav
            a, button
                font-size: 35px
        .sub-menu .title, .sub-tab .title
            font-size: 28px
        .leaf-wrap
            font-size: 18px

