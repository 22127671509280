@import '~epic-fortnite-styles/variables-mixins.sass'

.creative-card
    position: relative
    margin-top: -6%
    +respond-max-width(sm)
        margin-top: -9%
    +respond-max-width(xs)
        margin-top: -4em
    .gradient
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: #108cc1
        background-image: linear-gradient(45deg, #108cc1 0%,#22fcff 75%)
        clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%)
        +respond-max-width(sm)
            clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%)
    .section
        position: relative
        padding: 5em 0
        +respond-max-width(sm)
            padding: 4em 0
    .infoBoxContainer
        max-width: 1600px
        width: 100%
        margin: 0 auto
        &.two
            max-width: 1135px
        &.three
            max-width: 1600px
        .cards
            display: flex
            flex-direction: column
            justify-content: center
            +respond-min-width(xs)
                flex-flow: row wrap
            +respond-max-width(xs)
                align-items: center
            .card
                position: relative
                display: flex
                flex-direction: column
                min-width: 0
                word-wrap: break-word
                background-color: #fff
                clip-path: polygon(0% 4%, 100% 0%, 97% 100%, 4% 95%)
                width: 25%
                margin: 1em 3em
                +respond-max-width(lg)
                    width: 30%
                    margin: 1em 0.5em
                +respond-max-width(xs)
                    width: 80%
                .card-image
                    background-color: $yellow
                    width: 100%
                .card-content
                    flex: 1 1 auto
                    padding: 1.5em 1.25em
                    text-align: center
                    h2.card-title
                        line-height: 0.7
                        font-size: 3em
                        margin: 0
                        +respond-max-width(md)
                            font-size: 1.5em
                        +respond-max-width(sm)
                            font-size: 1.25em
                    .card-description
                        padding: 0 5.3em
                        +respond-max-width(lg)
                            padding: 0 1em
                        +respond-max-width(xs)
                            padding: 0 3em
                        a
                            font-family: $font_4
                            line-height: 1
                            font-weight: bold
                            &:after
                                font-family: $font_8
                                content: '\e90f'
                                position: relative
                                top: 7px
                                left: 5px
                                font-size: 2em
                        h4
                            font-family: $font_4
                            margin-bottom: 0
                            text-transform: none
                            font-size: 0.89em
                            +respond-max-width(sm)
                                font-size: 0.8em
        .buttonWrapper
            margin-top: 2em

.de,
.es_ES,
.fr,
.ru,
.tr,
.pl
    .creative-card .infoBoxContainer .cards .card .card-content
        h2
            font-size: 2em
            +respond-max-width(sm)
                font-size: 2em

.es_MX,
.it
    .creative-card .infoBoxContainer .cards .card .card-content
        h2
            font-size: 2em
            +respond-max-width(sm)
                font-size: 2em

.ja,
.ko,
.ar
    .creative-card .infoBoxContainer .cards .card .card-content
        h2
            font-size: 2em
            +respond-max-width(sm)
                font-size: 1.25em

    .creative-card .buttonWrapper .btn.btn-primary
        +respond-max-width(sm)
            font-size: 1.5em

.ar
    .creative-card .infoBoxContainer .cards .card .card-content
        .card-description
            margin-top: 2em
            h4
                font-family: $font_ar
                font-size: 1.25em
                +respond-max-width(sm)
                    font-size: 1em

=card-description($padding, $linkSize, $iconTopPos)
    .card-description
        padding: 0
        +respond-min-width(lg)
            padding: $padding
        a
            font-size: $linkSize
            &:after
                top: $iconTopPos

.de,
.es_ES,
.fr,
.ru,
.tr,
.pl
    .creative-card .infoBoxContainer .cards .card .card-content
        +card-description(0 4em, 0.8em, 5px)

.es_ES,
.es_MX
    .creative-card .infoBoxContainer .cards .card .card-content
        +card-description(0 3.1em, 0.8em, 5px)
