@import '../../../../styles/variables-mixins.sass'

.chapters-screens
    position: relative
    margin-top: 5%
    color: #000
    .screen-section
        width: 65%
        margin: 5% auto 0
        perspective: 100vw
        +respond-max-width(md)
            width: 80%
        img
            width: 100%
            //clip-path: polygon(0 0%,100% 0,99% 100%,1% 99%)
        .screen-content
            width: 60%
            margin: 5% auto 0
            text-align: center
            +respond-max-width(xs)
                width: 80%
            h1
                font-size: 4vw
                +respond-max-width(xs)
                    font-size: 5vw
            h4
                font-size: 1.5vw
                +respond-max-width(xs)
                    font-size: 2.5vw

@keyframes screen-swing
    0%
        clip-path: polygon(0 0%,100% 0,100% 100%,0 100%)
    50%
        // clip-path: polygon(0 3%,97% 0,100% 98%,0 98%)
        clip-path: polygon(1% 2%,100% 3%,99% 98%,0 99%)
        transform: rotateX(0deg)
    100%
        clip-path: polygon(0 0%,100% 0,100% 100%,0 100%)
        transform: rotateX(-3deg)
