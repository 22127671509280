@import '~epic-fortnite-styles/variables-mixins.sass'

.BackgroundSideCharacters
    position: relative
    +respond-max-width(xs)
        overflow: hidden
    .char
        position: absolute
        background-size: contain
        background-repeat: no-repeat
