@import '../../styles/variables-mixins.sass'
$blog-bg: #000

=full-width
    width: 100%
    margin: auto
    float: none

body.ar
    .article-view
      direction: rtl
.article-view
    padding: 0
    font-family: $font_4 //DIN Regular
    color: $blog-text
    h1, h2
      font-weight: normal
    ul
        padding-left: 0
        li
          list-style: none
    .container
      padding: 0

    .blog-container
        a
          text-decoration: none
          color: $accent-color
          &:hover
              color: $accent-color-hover

        .blog-content
            .pull-quote
                width: 40%
                @media screen and (max-width: 1200px)
                    +full-width

            .content-grid-row
                clear: both
                width: 800px
                @media screen and (max-width: 1600px)
                  width: auto

                .content-toss-container
                    @media screen and (max-width: 1600px)
                        +full-width
                    .content-toss
                      margin: 0 auto
                      @media screen and (max-width: 1300px)
                          .sticky-post
                                float: right

//onload
.transition-appear
  opacity: 0.01

.transition-appear.transition-appear-active
  opacity: 1
    transition: opacity 500ms ease-in-out
