@import '../../../styles/variables-mixins.sass'
$text-color: #ffffff
$headline-color: #ffffff
$contentHeight: 12.5em
$gridLargeMediumHeight: 35em
=gradientBkgrd
    content: ''
    display: block 
    position: absolute 
    bottom: 0 
    left: 0
    right: 0
    width: 100% 
    height: 30%
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 )
=textOverImageHover
    .grid-item
        .grid-content
            h1,
            h4 
                transform: translateY(0)
                transition: transform 0.3s ease-in-out
    &:hover
        .grid-item
            .background-image
                transform: scale3d(1.05, 1.05, 1.05)
                &:before
                    opacity: 1
            .grid-content 
                h1
                    transform: translateY(-20px)
                    transition: transform 0.3s ease-in-out 0.03s
                h4 
                    transform: translateY(-20px)
=smallHover
    .grid-item
        .grid-content
            overflow: hidden
            h1,
            h4 
                transform: translateY(15px)
                transition: transform 0.3s ease-in-out
    &:hover
        text-decoration: none

.blog-view-container .blog-view
    .grid-item
        text-decoration: none 
        color: $invert-text-color
        .background-image
            position: relative 
            height: 25vh
            min-height: 245px
            background-image: url(../../../images/defaultImage.jpg)
            background-position: center 
            background-size: cover 
            background-repeat: no-repeat
            @media screen and (max-width: 1400px)
                height: 27vh
                min-height: 245px
            +respond-max-width(md)
                height: 22em
            +respond-max-width(xs)
                height: auto
                min-height: 100%
                padding-bottom: 56.25%
            .invisible-image 
                visibility: hidden
                height: 0 
                opacity: 0
                width: 100%
                display: block
        .grid-content
            background: white
            padding: 2em
            height: $contentHeight
            margin-bottom: 2em
            position: relative
            z-index: 1
            +respond-max-width(lg)
                padding: 1.5em
            @media screen and (max-height: 975px)
                padding: 1em
            +respond-max-width(xs)
                height: 100%
                padding: 1.5em 1em
            .date
                font-family: $font_0
                font-weight: 700
                font-size: 1em
                margin: 0 0 1em
            .title
                font-family: $font_0
                font-weight: 700
                text-transform: uppercase
                font-size: 1.5em
                margin: 0
    
    //different size Types to track that dont follow default
    .gridItem-large
        .grid-item 
            position: relative
            color: $text-color
            padding-bottom: $gridLargeMediumHeight
            margin-bottom: 2em
            overflow: hidden
            height: 0
            +respond-max-width(lg)
                padding-bottom: 0
                height: 31vw
            +respond-max-width(md)
                color: $invert-text-color
                height: auto
                margin-bottom: 0
            .background-image
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-repeat: no-repeat
                background-position: 50% 50%
                &:after
                    +gradientBkgrd
                +respond-max-width(md)
                    position: relative
                    height: 22em
                    padding-bottom: 0
                    margin-bottom: 0
                    &:after 
                        display: none
                +respond-max-width(xs)
                    height: auto
                    padding-bottom: 56.25%
            .grid-content
                position: absolute 
                bottom: 0 
                left: 0
                background-color: transparent
                margin-bottom: 0
                height: auto
                +respond-max-width(md)
                    margin-bottom: 2em
                    position: relative
                    background-color: white
                    height: $contentHeight
                +respond-max-width(xs)
                    height: 100%
    .gridItem-medium
        .grid-item
            position: relative
            padding-bottom: $gridLargeMediumHeight
            margin-bottom: 2em
            overflow: hidden
            color: $text-color
            height: 0
            +respond-max-width(lg)
                padding-bottom: 0
                height: 31vw
            +respond-max-width(md)
                height: auto
                color: $invert-text-color
                margin-bottom: 0
            .background-image
                position: absolute
                top: 0 
                left: 0 
                width: 100%
                height: 100%
                &:after 
                    +gradientBkgrd
                +respond-max-width(md)
                    position: relative
                    height: 22em
                    padding-bottom: 0
                    margin-bottom: 0
                    &:after 
                        display: none
                +respond-max-width(xs)
                    height: auto
                    padding-bottom: 56.25%
            .grid-content
                position: absolute 
                bottom: 0 
                left: 0
                background: transparent
                margin-bottom: 0
                height: auto
                +respond-max-width(md)
                    margin-bottom: 2em
                    position: relative
                    background-color: white
                    height: $contentHeight
                +respond-max-width(xs)
                    height: 100%
    .gridItem-thirdPos
        .grid-item
            position: relative
            +respond-max-width(md)
                color: $text-color
                margin-bottom: 2em
            +respond-max-width(sm)
                margin-bottom: 0
                color: $invert-text-color
            .background-image
                +respond-max-width(md)
                    height: auto 
                    padding-bottom: 56.25%
                    &:after 
                        +gradientBkgrd
                +respond-max-width(sm)
                    height: 22em
                    &:after
                        display: none
                +respond-max-width(xs)
                    height: auto
                    padding-bottom: 56.25%
            .grid-content
                +respond-max-width(md)
                    position: absolute 
                    bottom: 0 
                    left: 0
                    background: transparent
                    margin-bottom: 0
                    height: auto
                +respond-max-width(sm)
                    margin-bottom: 2em
                    bottom: 0
                    position: relative
                    background-color: white
                    height: $contentHeight
                +respond-max-width(xs)
                    height: 100%
    
    //for hover states
    .gridItem-small,
    .gridItem-medium,
    .gridItem-large,
    .gridItem-thirdPos
        opacity: 0
        transform: translateY(50px)
        .grid-item
            overflow: hidden
            .background-image
                transform: scale3d(1, 1, 1)
                transition: transform 0.3s ease-in-out
    
    .gridItem-small,
    .gridItem-medium,
    .gridItem-large,
    .gridItem-thirdPos
        &:hover 
            text-decoration: none !important
            .background-image
                transform: scale3d(1.05, 1.05, 1.05)
                transition: transform 0.3s ease-in-out
    .gridItem-small 
        +respond-min-width(md)
            +smallHover
    .gridItem-medium,
    .gridItem-large
        +respond-to(lg)
            +textOverImageHover
        +respond-to(xl)
            +textOverImageHover
    .gridItem-thirdPos
        +respond-to(lg)
            +smallHover
        +respond-to(xl)
            +smallHover
        +respond-to(md)
            +textOverImageHover
    //for inViewport animation
    .gridItem-small,
    .gridItem-medium,
    .gridItem-large,
    .gridItem-thirdPos
        &.inView 
            opacity: 1
            transform: translateY(0)
            transition: transform 0.4s ease-out, opacity 0.4s ease-out
