@charset "UTF-8"

.featured-item
    overflow: hidden
    &.large
        min-height: 18em
    &.stacked
        margin-bottom: 1em
        .stream-card-info
            display: none
    &.hero
        max-height: 690px
        height: auto

