@import '../../../../styles/variables-mixins.sass'

// Base style for play guide pages
.play-guide-view
    .content
        position: relative
        margin-top: 55px
        display: block
    .guide-info
        background-size: 0, 0, 0, cover, cover
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat
        background-position: left top, right center, left top, left top, left top
        width: 100%
        overflow: auto
        padding-bottom: 30px
        h2
            text-align: center
            margin: 45px 0 20px
            font-size: 35px
            color: #fff
        h3
            text-align: center
            margin: 50px 0 20px
            font-size: 24px
            color: #fff
        h5
            text-align: center
            margin: 20px 0
            color: #fff
            line-height: 1.5
    .guide-info-head
        margin: 50px 5px
    .guide-info-content
        margin: 0 5px
        padding-bottom: 30px
    .guide-btn
        display: inline-block
        font-size: 18px
        overflow: hidden
        text-overflow: ellipsis
        text-align: center
        white-space: nowrap
        margin: 5px
        background-color: $white
        width: calc(33.3% - 10px)
        height: 41px
        line-height: 41px
        font-size: 18px
        cursor: pointer
        transition: background-color 0.3s ease
        clip-path: polygon(0 0, 100% 0%, 99% 100%, 1% 100%)
        &:nth-child(1), &:nth-child(7), &:nth-child(13), &:nth-child(19)
            clip-path: polygon(0 0, 83% 0, 80% 15%, 100% 10%, 99% 100%, 1% 100%)
        &:nth-child(3), &:nth-child(9), &:nth-child(15), &:nth-child(21)
            clip-path: polygon(0 0, 100% 0, 100% 100%, 17% 100%, 20% 85%, 0 90%)
        &:nth-child(5), &:nth-child(11), &:nth-child(17), &:nth-child(23)
            clip-path: polygon(0 10%, 20% 15%, 17% 0, 100% 10%, 99% 100%, 1% 100%)
        &.active
            background-color: #FFFE55
            cursor: default
    .guide-text
        text-align: center
        margin-top: 20px
        font-size: 13px
        line-height: 18px
        color: #fff
    .guide-map
        background-color: #fff
        width: 400px
        height: 400px
        margin: 20px auto
        padding: 20px
        clip-path: polygon(0 0, 100% 3%, 98% 98%, 3% 100%)
        &:hover .cover
            display: block
        div
            background-repeat: no-repeat
            background-size: cover
            height: 100%
        .cover
            margin-top: -100%
            display: none
    .guide-selection .guide-btn
        width: calc(33% - 10px)
    .guide-tip-wrap
        position: relative
        margin-top: 80px
    .guide-tip-content
        background-color: #6221B3
        text-align: center
        color: #C99AF9
        padding: 50px 80px
        margin-bottom: 30px
        font-size: 13px
        clip-path: polygon(0 0, 100% 15px, 99% calc(100% - 15px), 1% 100%)
        &:nth-child(3)
            clip-path: polygon(0 0, 93% 15px, 90% 35px, 100% 33px, 99% calc(100% - 15px), 1% 100%)
        h5
            font-size: 15px
        img
            max-width: 100%
    .guide-tip-title
        position: absolute
        top: -30px
        left: -20px
        z-index: 1
        display: inline-block
        background-color: #C9C9D8
        padding: 5px 40px
        font-weight: bold
        font-size: 2.5rem
        clip-path: polygon(13% 0, 76% 0, 68% 17%, 100% 14%, 87% 100%, 0 100%)
    .guide-table
        margin-top: 30px
        border-collapse: collapse
        border-spacing: 0
        width: 100%
        background-color: #fff
        text-align: center
        tr.has-info
            td
                 background-color: #fff
        th
            background-color: #000
            color: #fff
            text-align: center
            padding: 15px 20px
            border: 5px solid #000
        td
            border: 5px solid #fff
            background-color: #EDEEF2
            width: 15%
            padding: 10px
            &:first-child
                padding: 0
            &:last-child
                width: auto
                text-align: left
        img
            width: 100%
    .guide-extra
        img
            max-width: 100%
    &.piece, &.map-information
        .guide-selection .guide-btn
            width: calc(25% - 10px)
    &.key-binding
        .guide-tip-title
            display: none
        .guide-tip-content
            background-color: #6edcfa
            color: #009ed0
    &.fortnite-school
        .guide-selection .guide-btn
            width: calc(50% - 10px)
        .guide-tip-title
            background-color: #fff
        .guide-tip-content
            background-color: #E37239
            color: #fff
            text-align: left
    +respond-min-width(xs)
        .content
            margin-top: 65px
        .guide-info-content
            margin: 0 30px
        .guide-info
            h2
                font-size: 40px
            h3
                font-size: 30px
        .guide-tip-content
            font-size: 16px
            h5
                font-size: 19px
        .guide-text
            font-size: 16px
            line-height: 22px
        .guide-btn
            height: 60px
            line-height: 60px
            font-size: 22px
    +respond-min-width(sm)
        .content
            display: flex
            justify-content: space-between
        .guide-menu-wrap
            width: 317px
            min-width: 317px
        .guide-info
            h2
                font-size: 38px
                margin-top: 70px
            h3
                font-size: 28px
        .guide-info-head, .guide-info-content
            margin: 0 8%
        .guide-tip-content
            font-size: 13px
            h5
                font-size: 15px
        .guide-text
            font-size: 13px
            line-height: 18px
        .guide-btn
            height: 44px
            line-height: 44px
            font-size: 18px
    +respond-min-width(md)
        .guide-info
            background-size: contain, auto, contain, cover, cover
            h2
                font-size: 50px
                margin-top: 95px
            h3
                font-size: 36px
        .guide-tip-content
            font-size: 14px
            h5
                font-size: 16px
        .guide-text
            font-size: 14px
            line-height: 24px
        .guide-btn
            height: 58px
            line-height: 58px
            font-size: 21px
    +respond-min-width(lg)
        .guide-info
            h2
                font-size: 60px
            h3
                font-size: 44px
        .guide-info-head, .guide-info-content
            margin: 0 10%
        .guide-tip-content
            font-size: 16px
            h5
                font-size: 19px
        .guide-text
            font-size: 16px
            line-height: 27px
        .guide-btn
            height: 65px
            line-height: 65px
            font-size: 24
    +respond-min-width(xl)
        .guide-info
            h2
                font-size: 80px
            h3
                font-size: 52px
        .guide-info-head, .guide-info-content
            margin: 0 16%
        .guide-tip-content
            font-size: 18px
            h5
                font-size: 22px
        .guide-text
            font-size: 18px
            line-height: 30px
        .guide-btn
            height: 71px
            line-height: 71px
            font-size: 28px
