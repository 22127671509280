@charset "UTF-8"
@import '../../../../styles/variables-mixins.sass'

.edition-section
    padding: 0 3em
    display: flex
    position: relative
    margin-bottom: 4em
    flex-wrap: wrap
    +respond-max-width(lg)
        padding: 0 1em
    +respond-max-width(sm)
        flex-direction: column
    .edition-pack-wrapper
        opacity: 0
        width: 48%
        max-width: 1440px
        margin: 0 auto
        +respond-max-width(sm)
            width: 100%
            margin: 1em 0
        .discount-percent
            width: 100px
            height: 100px
            background-color: #F8FF01
            border-radius: 50%
            display: flex
            text-align: center
            justify-content: center
            align-items: center
            position: absolute
            right: 30px
            top: -30px
            text-transform: uppercase
            font: $font-h4
            +respond-max-width(sm)
                width: 80px
                height: 80px
                font-size: 1.5em
        .edition-pack
            padding: 2em 4em 7em
            height: 100%
            position: relative
            +respond-max-width(lg)
                padding: 1em
            +respond-max-width(sm)
                padding: 2em 1em
            .character-img
                position: absolute
                right: -100px
                bottom: 0
                z-index: -1
                +respond-max-width(sm)
                    display: none
                +respond-max-width(md)
                    height: 90%
                    right: -50px

            h3, .price
                font-family: $font_2
                font-size: 75px
                line-height: 1
                color: #ffffff
                +respond-max-width(md)
                    font-size: 4em
                +respond-max-width(xs)
                    font-size: 2em
            .verb, .actual-price, .old-price
                font-size: 30px
                line-height: 1.5
                font-family: $font_2
                text-transform: uppercase
                color: rgba(0,0,0,0.7)
            .actual-price
                color: #ffffff
            .old-price
                color: #34dfe8
                span.outer
                    text-decoration: line-through
                    color: red
                    .inner
                        color: #34dfe8
            .content-wrapper
                margin-bottom: 2em
                padding-right: 10vw
                display: flex
                align-items: flex-start
                +respond-max-width(lg)
                    padding: 0
                +respond-max-width(xs)
                    flex-direction: column
                img
                    width: 150px
                    +respond-max-width(xs)
                        width: 100px
                .content
                    color: rgba(0,0,0,0.7)
                    font-family: $font_2
                    text-transform: uppercase
                    padding-left: 2em
                    ul
                        font: $font-h5
                        padding: 0
            .pack-buy-button
                +respond-max-width(lg)
                    display: block
                
                button
                    background-color: #ff6305
                    font-family: $font_2
                    padding: 0 1.5em
                    color: #ffffff
                    font-size: 20px
                    line-height: 50px
                    margin-top: 1em
                    transition: all 0.3s ease-in-out
                    +btn-hover-transform
                    &:hover, &:focus
                        background-color: darken(#ff6305, 10%)
            .price, .actual-price, .pack-buy-button, .old-price
                text-align: center
                +respond-min-width(md)
                    text-align: left
            .verb
                font-size: 22px
                color: #fff
                text-align: center
                +respond-min-width(lg)
                    text-align: left

    .mtl-pack-wrapper
        width: 100%
        .pack-content__title
            text-align: center
            +respond-min-width(lg)
                text-align: left
        .pack-content__sub-header
            color: #34dfe8
            text-align: center
            font-family: $font_2
            font-size: 20px
            font-weight: 900
            text-transform: uppercase
            +respond-min-width(lg)
                text-align: left
                margin-right: 500px
        .pack-content__wrapper
            font-family: $font_2
            text-transform: uppercase
            +respond-min-width(lg)
                margin-right: 660px
            img
                margin: 0 auto
                margin-bottom: 20px
                width: 150px
                +respond-min-width(md)
                    margin: unset
            .pack-content__main
                align-items: center
                display: flex
                flex-direction: column
                margin-bottom: 20px
                +respond-min-width(sm)
                    flex-direction: row
            .pack-content__list
                color: #fff
                font-family: $font_2
                text-transform: uppercase
                font-size: 18px
                font-weight: 900
            .pack-content__bonus-text
                color: #ffff00
                font-family: $font_2
                text-transform: uppercase
                text-align: center
                font-size: 20px
                font-style: italic
                font-weight: 900
                width: 100%
                line-height: 1.1
                +respond-min-width(md)
                    width: 50%
                    text-align: left
                +respond-min-width(lg)
                    width: unset
        .actual-price
            margin-bottom: 1em
        .pack-content__legal-text
            color: #fff
            font-family: $font_2
            position: absolute
            bottom: 0
            +respond-max-width(lg)
                display: none
        .pack-content__image--mobile
            position: relative
            bottom: 0
            width: 100%
            +respond-min-width(md)
                display: none


body.ja
    .edition-section
        .edition-pack-wrapper
            .discount-percent
                font-family: $font_jaHeader
            .edition-pack
                h3, .price, .verb, .actual-price, .old-price
                    font-family: $font_jaHeader
                .content-wrapper
                    .content
                        font-family: $font_jaBody
                        ul
                            font-family: $font_jaBody

body.ko
    .edition-section
        .edition-pack-wrapper
            .discount-percent
                font-family: $font_ko_art
            .edition-pack
                h3, .price, .verb, .actual-price, .old-price
                    font-family: $font_ko_art
                .content-wrapper
                    .content
                        font-family: $font_ko
                        ul
                            font-family: $font_ko

body.ar
    .edition-section .edition-pack-wrapper .edition-pack .content-wrapper .content
        padding-right: 2em
