@import '../../../../styles/variables-mixins.sass'

.videoPlayButton
    width: 5.4em
    height: 5.4em
    background-color: rgba(0, 0, 0, 0.3)
    position: relative
    display: flex
    -webkit-box-pack: center
    justify-content: center
    -webkit-box-align: center
    align-items: center
    border-radius: 100%
    cursor: pointer
    border-width: 0.2em
    border-style: solid
    border-color: white
    border-image: initial
    transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s
    span
        position: relative
        left: 0.2em
        width: 0
        height: 0
        display: inline-block
        border-top: 1.2em solid transparent
        border-bottom: 1.2em solid transparent
        border-left: 1.8em solid white
