@import '../../../../styles/variables-mixins.sass'
.creative-view
    background-color: $background
    &.extraPadding
        padding-bottom: 15em
    .section
        max-width: 1600px
        width: 100%
        margin: 0 auto
        padding: 0 1em
    +respond-min-width(sm)
        .simple-tab-header-container
            justify-content: left
            .tab-item-container
                margin: 0 3em
    .error500
        position: relative
    .simple-tab-header-container
        justify-content: center
