@import '../../../../styles/variables-mixins.sass'

.battlepass-gunwraps
    padding: 5% 0
    opacity: 0
    &.visible
        animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
    .gun-content
        .gun-parallax
            margin: 1em auto
            text-align: center
            img
                width: 80%
                +respond-max-width(xs)
                    width: 90%
        .gunwrap-description
            color: #000
            font-family: $font_2
            text-align: center
            margin: 3vw auto
            h1
                margin: 0 auto
                color: #000
                font-size: 5vw
                +respond-max-width(xs)
                    font-size: 6vw
            p
                margin: 0 auto
                margin-top: 2vw
                font-size: 1.5vw
                width: 45vw
                +respond-max-width(xs)
                    width: 75vw
                    font-size: 2.5vw

.chapter-2-season-3,
.ch2-s3
    .battlepass-gunwraps
        padding: 6em 0 
        max-width: 1200px
        margin: 0 auto
        +respond-max-width(xs)
            padding: 2em 0
        .gun-content .gunwrap-description
            h1 
                font-size: 5em
                +respond-max-width(xs)
                    font-size: 3em
