@import '../../../../styles/variables-mixins.sass'
body.ar
    .sectionLink
        text-align: right
        &:before
            margin-left: 10px
.sectionLink
    font-family: $font_4
    font-size: 0.85em
    margin: 0.5em 0
    cursor: pointer
    transition: color 0.3s ease-in-out
    vertical-align: top
    list-style-position: inside
    text-indent: -1.2em
    +respond-max-width(sm)
        width: 33%
        display: inline-block
    +respond-max-width(xs)
        width: 50%
        display: inline-block
    @media only screen and (max-width: 480px)
        width: 100%
    &:hover
        color: $foundersBlue
    &:before
        content: ''
        display: inline-block
        width: 5px
        height: 5px
        background: $foundersBlue
        margin-bottom: 3px
        margin-right: 10px
        border-radius: 50%
