@import '../../../../styles/variables-mixins.sass'

#faq
    background-color: $background
    .container
        +respond-max-width(xs)
            padding-left: 2%
            padding-right: 2%
    h1
        letter-spacing: 0.02em
        font-size: 3em
        text-transform: uppercase
        color: $white
        text-align: center
        padding: 1em 0
    .last-modified-date
        font-style: italic
        padding: calc(1em + 15px)
        @include respond-max-width(sm)
            padding: 0
    .quickLink
        margin-bottom: 2em
        padding: 1em
        border-left: 1px solid $siteBlockBorder
        +respond-max-width(sm)
            border-bottom: 1px solid $siteBlockBorder
            border-left: none
        h3
            text-transform: uppercase
            text-align: center
            padding-bottom: 1em
            @include respond-max-width(sm)
                border-bottom: 1px solid $siteBlockBorder
                text-align: left
                margin-top: 0
                font-weight: bold
        ul
            list-style: none
            margin-left: 2em
            padding: 0
    .stick
        position: fixed
        top: 91px

    .site-block
        max-width: 1200px
        +respond-max-width(xs)
            padding: 3%

.ja
    #faq .quickLink
        h3
            font-size: 1.5em
