@import '~epic-fortnite-styles/variables-mixins.sass'

.creator-card
    background-color: $background
    position: relative 
    .section 
        padding: 6em 1em 8em
        +respond-max-width(sm)
            padding: 4em 1em
        .content
            max-width: 60em
            width: 100%
            margin: 0 auto
            .description
                margin: 0 auto 2em
                max-width: 40em 
                width: 100%
                h3 
                    margin: 0
                    font-size: 2em
                    +respond-max-width(sm)
                        font-size: 1.5em

            .image 
                width: 100%
                margin: 0 auto 
                background-repeat: no-repeat
                background-position: center 
                background-size: cover
                padding-bottom: 56.25%
                cursor: pointer
                position: relative
                transform: scale3d(1, 1, 1)
                transition: transform 0.3s ease-in-out

                .playButton
                    position: absolute
                    left: 0
                    right: 0
                    width: 4em
                    height: 4em
                    margin: 0 auto
                    bottom: -2em
                    background-color: #000
                    border-radius: 50%
                    cursor: pointer
                    display: inline-block
                    +respond-max-width(xs)
                        width: 2em 
                        height: 2em
                        bottom: -1em
                    i 
                        color: $yellow
                        font-size: 4em
                        display: block
                        transform: scale3d(1.1, 1.1, 1.1)
                        line-height: 1.25
                        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out
                        +respond-max-width(xs)
                            font-size: 2em
                &:hover 
                    transform: scale3d(1.01, 1.01, 1.01)
                    i 
                        color: darken($yellow, 5%)
                        transform: scale3d(1.15, 1.15, 1.15)
