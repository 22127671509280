@import '../../../../styles/variables-mixins.sass'

.chapter2s5-header-wrapper
    height: 90vh
    min-height: 1000px
    position: relative
    overflow: hidden
    +respond-max-width(md)
        height: 54vh
        min-height: 560px
    +respond-max-width(sm)
        height: 90vh
        max-height: auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-end
        padding-top: 10vh
        padding-bottom: 12vh
    .header
        height: 100%
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        +respond-max-width(xs)
            height: calc(177vw - 3.7em)
    .active.content
        .animated-content
            h1, h3
                transform: none
                transition: 0.5s ease-in-out
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s forwards
    .content
        position: relative
        color: #fff
        width: 100%
        text-align: center
        transform: none
        height: 70vh
        min-height: 750px
        display: flex
        flex-direction: column
        justify-content: flex-end
        +respond-max-width(md)
            min-height: 440px
            height: 40vh
        .animated-content
            .text-wrapper
                display: inline-block
                padding: 1em 10em
                background: radial-gradient(farthest-side, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%)
                +respond-max-width(sm)
                    padding: 3em 0
                    width: 100vw


            button, a
                margin-top: 0
                +respond-max-width(sm)
                    margin-top: 1vw
                    margin-bottom: 0
            img
                width: 30vw
                margin-bottom: 0
                +respond-max-width(sm)
                    width: 90%
            h1, h3
                opacity: 0
            +respond-max-width(xs)
                text-align: center
            h1
                margin: 16px 0 0
                font-size: 8em 
                color: #fff
                line-height: 1
                background: linear-gradient(#0a2a8a, #178fd7, #0a2a8a)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                +respond-max-width(md)
                    font-size: 6em
                +respond-max-width(xs)
                    font-size: 4em

                .es_ES &,
                .es_MX &,
                .fr &,
                .it &
                    font-size: 6em
                    +respond-min-width(sm)
                        font-size: 8em
                    +respond-min-width(md)
                        font-size: 6em
                    +respond-min-width(lg)
                        font-size: 8em
                    @media screen and (min-width: 1600px)
                        font-size: 10em
                .pt_BR &
                    font-size: 3em
                    +respond-min-width(sm)
                        font-size: 5em
                    +respond-min-width(md)
                        font-size: 4em
                    +respond-min-width(lg)
                        font-size: 6em
                    @media screen and (min-width: 1600px)
                        font-size: 10em
                .pl &
                    font-size: 5em
                .ru &
                    font-size: 5em
                .tr &
                    font-size: 5em
                    +respond-min-width(sm)
                        font-size: 6em
                    +respond-min-width(md)
                        font-size: 4em
                    +respond-min-width(lg)
                        font-size: 6em
                    @media screen and (min-width: 1600px)
                        font-size: 10em

            h3
                font-size: 2em
                letter-spacing: 6px
                text-shadow: 0  0 20px rgba(#fff, 1), 0px 5px 8px rgba(#fff, 0.3)
                color: #000
                +respond-max-width(xs)
                    font-size: 1.2em
                    letter-spacing: 2px
            .badge-img img
                margin-bottom: 1em
                width: 10vw
                +respond-max-width(xs)
                    width: 25vw
            .logo-img img
                width: 65vw
                margin-bottom: -5%
                margin-top: -13%
                +respond-max-width(xs)
                    margin-top: 10%
        .play-wrapper
            .play-button-wrapper
                display: flex
                align-items: center
                justify-content: center
                cursor: pointer
                h4
                    margin: 0 0.5em
                    font-size: 1.5em
                    color: #000
                    text-shadow: 0  0 10px rgba(#fff, 0.8), 0px 5px 20px rgba(#fff, 0.3)

                    +respond-max-width(xs)
                        text-align: center
                        margin: 0.5em 0.5em 0
                .videoPlayButton
                    font-size: 0.8vw
                    margin: 0 auto

                    &.bp-videoplay
                        border-color: #000000
                        background: rgba(#fff, 0.5) 
                        span 
                            border-left: 1.8em solid #000
                    
                    +respond-max-width(xs)
                        font-size: 0.5em
                    &:hover
                        transform: scale(1.1) !important
    .footer-logo
        position: absolute
        height: 30px
        bottom: 50px
        left: 65px
        

        +respond-max-width(md)
            bottom: 70px
            left: 65px
        +respond-max-width(sm)
            bottom: 100px
            left: 15px
        +respond-max-width(xs)
            bottom: 80px
            left: 10px
