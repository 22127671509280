@import '../../../styles/variables-mixins.sass'
$text-color: #ffffff
$headline-color: #ffffff
=hoverEffect
     &:hover 
            .container-image .feature-image:before
                opacity: 1
            .feature-headline 
                h2 
                    transform: translateY(-50px)
                    transition: transform 0.3s ease-in-out 0.03s
                .feature-date 
                    transform: translateY(-50px)
.blog-view-container .blog-view
    .top-featured-activity
        .feature-banner
            position: relative
            display: block
            color: $text-color 
            text-decoration: none
            overflow: hidden
            +respond-min-width(md)
                +hoverEffect
            .container-image
                position: relative 
                top: 0 
                left: 0
                right: 0
                width: 100%
                padding-bottom: 56.25%
                max-width: $containerWidth
                min-width: $containerMinWidth
                margin: 0 auto
                +respond-max-width(sm)
                    max-width: 100%
                    min-width: 100%
                .feature-image
                    position: absolute 
                    top: 0 
                    left: 0 
                    right: 0
                    width: 100%
                    height: 100%
                    background-position: center 0 
                    background-repeat: no-repeat 
                    background-size: cover
                    margin: 0 auto
                    z-index: 0
                    &:before 
                        content: ''
                        display: block
                        position: absolute 
                        bottom: 0 
                        left: 0
                        right: 0
                        width: 100% 
                        height: 100%
                        opacity: 0
                        z-index: 2
                        transition: opacity 0.3s ease-in-out
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 )
                    &:after
                        content: ''
                        display: block 
                        position: absolute 
                        bottom: 0 
                        left: 0
                        right: 0
                        width: 100% 
                        height: 15%
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%)
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 )
                        +respond-max-width(xs)
                            display: none
            .feature-headline 
                position: absolute 
                bottom: 0 
                left: 0
                padding: 4em
                color: $text-color
                z-index: 1
                max-width: 65em
                +respond-max-width(sm)
                    position: relative 
                    background: $headline-color
                    color: $invert-text-color
                    padding: 1.5em
                    max-width: 100%
                h2
                    font-family: $font_0
                    font-weight: 700
                    font-size: 2em
                    text-transform: uppercase
                    margin: 0
                    transform: translateY(0)
                    transition: transform 0.3s ease-in-out
                    +respond-max-width(xs)
                        font-size: 1.5em
                .feature-date 
                    transform: translateY(0)
                    transition: transform 0.3s ease-in-out
