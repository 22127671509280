@charset "UTF-8"
@import '../../../../styles/variables-mixins.sass'

.eula-view-container
    background-color: $background
    #eulaContainer
        min-height: 90vh
        width: 100%
        display: table
        height: 80vh
        .eula-container
            margin-top: 2em
            margin-bottom: 2em
            .site-block-header
                h1
                    max-width: 66%
                    +respond-max-width(md)
                        max-width: 100%
                h1.uefn
                    max-width: 100%
            .site-block-body
                .site-block-body-text
                    font-size: 0.9em

            .btn.btn-primary
                min-width: 7em
                margin-top: 0.6em
                font-size: 1em
                padding: 0.4em 1.5em
            .eula-text
                line-height: 2em

body.ar
    #eulaContainer
        direction: rtl
