@import '../../../../styles/variables-mixins.sass'

.LPVideo
    .background-video
        .screen
            position: unset
        &.fixed
            position: relative
        .video
            z-index: unset
            overflow: unset

.LPVideo.center
    background-color: black
    padding-top: 3vw
    padding-bottom: 3vw
    .background-video
        background-color: #f5faff
    .video.background-video.fixed
        width: 65%
        margin-right: auto
        margin-left: auto
        +respond-max-width(sm)
            width: 75%
        +respond-max-width(xs)
            width: 90%
.LPVideo
    position: relative
.LPVideo.clip
    .after
        content: ''
        display: block
        background-color: black
        position: absolute
        left: 0
        right: 0
        top: -2.2em
        height: 3em
        clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%)
