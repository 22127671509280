@import '../../../../styles/variables-mixins.sass'

=btn-blog
    position: relative
    font-size: 1.75em
    padding: 0 2em
    color: #000000
    margin-top: 0.5em
    background: transparent !important
    border: 4px solid #000000 !important
    transition: all 0.3s ease-in-out
    span
        position: relative
        z-index: 1
    &:hover
        background: #000000 !important
        color: #ffffff !important
        transform: skewX(-5deg)

//Fortnite Custom Styling
.blog-view-container .blog-view
    .top-featured-activity .feature-banner
        .container-image
            .feature-image
                overflow: hidden
                background-image: url(../../../../images/blog/default_image.jpg)
                &:after
                    transform: translateY(0)
                    transition: transform 0.3s ease-in-out
                &:before
                    background-color: rgba($foundersBlue, 0.9)
                    background-image: url(../../../../images/blog/overlaySpikes_big_w.png)
                    background-repeat: no-repeat
                    background-size: cover
                    transform: scale3d(2, 2, 2)
                    transition: all 0.3s ease-in-out
        .feature-headline
            h2
                font-family: $font_0
                font-weight: normal
                font-size: 5em
                +respond-max-width(sm)
                    font-size: 2em
            .feature-date
                p
                    font-family: $font_0
                    font-size: 1.5em
                    color: $text-color
                    line-height: 1
                    margin: 0
                    +respond-max-width(sm)
                        font-family: $font_4
                        font-weight: 700
                        margin: 0 0 0.5em
                        font-size: 1em
                        text-transform: uppercase
                        color: $foundersBlue
        +respond-min-width(md)
            &:hover
                .container-image .feature-image:before
                    transform: scale3d(1, 1, 1)
                .container-image .feature-image:after
                    +respond-min-width(md)
                        transform: translateY(100%)
    .category-filter.listCat
        margin-top: 3em
        .filter-container .filter-list .filter-content
            ul
                margin-bottom: 1em
                li
                    font-family: $font_0
                    font-size: 1.4em
                    text-transform: capitalize
    .category-filter.overlayCat
        .filter-container .toggleButton
            background-color: $foundersBlue
            .count span
                color: $invert-text-color
                font-weight: bold
            .count:before
                background-color: $accent-color
            &:hover
                background-color: darken($foundersBlue, 10%)
                i
                    color: $text-color
            @media screen and (max-width: 1675px)
                background-color: darken($foundersBlue, 10%)
                &:hover
                    background-color: darken($foundersBlue, 15%)
        .filter-list
            .bkgrd
                background: rgba($foundersBlue, 0.95)
                .filter-content
                    .closeButton
                        .epic-blog-icon-close:before
                            font-family: 'icomoon'
                            content: "\e913"
                        &:hover i
                            color: $yellow
                    h3
                        font-size: 2.5em
                        margin: 1em 0
                    ul li
                        font-family: $font_1
                        text-transform: capitalize
                        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
                        +respond-max-width(sm)
                            font-size: 1.1em
                        &.active,
                        &:hover
                            color: $invert-text-color
                            clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%)
                            background: $yellow
                            border-color: $yellow
                            +respond-max-width(sm)
                                clip-path: none
    .grid-item
        .background-image
            background-image: url(../../../../images/blog/default_image.jpg)
        .grid-content
            .title
                font-family: $font_2
                font-weight: normal
                font-size: 1.8em
                line-height: 1
            .date
                font-size: 1em
                color: $foundersBlue
                line-height: 1
                margin: 0 0 0.5em
    .gridItem-large,
    .gridItem-medium
        +respond-min-width(lg)
            .grid-item
                .background-image:after
                    transform: translateY(0)
                    transition: transform 0.3s ease-in-out
                .background-image:before
                    transform: scale3d(2, 2, 2)
                    transition: all 0.3s ease-in-out
                .grid-content .title
                    font-size: 2.8em
                .grid-content .date
                    color: $text-color
            &:hover
                .grid-item
                    .background-image:before
                        transform: scale3d(1, 1, 1)
                    .background-image:after
                        +respond-min-width(md)
                            transform: translateY(100%)
        +respond-to(md)
            .grid-item
                .grid-content
                    overflow: hidden
            &:hover
                .grid-content:after
                    transform: translateY(0)
    .gridItem-small
        .grid-item
            .grid-content:after
                background-color: $foundersBlue
    .gridItem-thirdPos
        +respond-min-width(lg)
            .grid-item
                .grid-content:after
                    background-color: $foundersBlue
        +respond-to(md)
            .grid-item
                .background-image
                    overflow: hidden
                .background-image:after
                    transform: translateY(0)
                    transition: transform 0.3s ease-in-out
                .background-image:before
                    transform: scale3d(2, 2, 2)
                    transition: all 0.3s ease-in-out
            &:hover
                .grid-item
                    .background-image:before
                        transform: scale3d(1, 1, 1)
                    .background-image:after
                        +respond-min-width(md)
                            transform: translateY(100%)

.article-view
    min-height: 80vh
    background-color: $white
    .blog-header-info
        hr
            border-color: lighten($darkGray, 60%) !important
        .blog-header-title
            margin-top: 0.2em !important
    .textile-preview
        ul
            margin-left: 1.5em
            li
                list-style: disc
        &.selected.selected
            border: 1px solid $foundersBlue
    .cmsContainer
        h1
            font-family: $font_0 !important
        h3, h4, h5, h6
            font-family: $font_4 !important
        .blog-header-info
            font-family: $font_4

        .cms
            ul
                font-size: 15px
            a
                font-size: 1em
                font-weight: 500
                color: $foundersBlue !important
                &:hover, &:active, &:focus
                    color: darken($foundersBlue, 10%) !important
            .container
                display: inline
.blog-wrapper, .ReactModalPortal
    .blog-load-indicator
        .loading-icon
            background: url('../../../../images/loading/FN-spin.gif') center no-repeat
            height: 3em

    .blog-container
        .comment-rating-box .pagination .comment-load-more
            +btn
            +btn-blog
        .comment-rating-box .text-area-box
            .sso-sign-in .not-login span
                font-weight: 500
                color: $white !important
            .not-login
                +btn
                +btn-blog
                .sso-sign-in
                    font-weight: 500
                    span
                        color: $white !important
            .textile-preview.selected
                border: 1px solid $foundersBlue !important
        table
            tr:first-child:first-child
                background-color: $foundersBlue
                color: $white

    .comment-edit-box, .comment-rating-box
        .loading-svg
            background: url('../../../../images/loading/FN-spin.gif') center no-repeat
            background-size: 3em
            height: 5em
        .textile-container
            .textile-head
                li
                    box-sizing: content-box
                    padding: 0.2em 1.2em
                    border-radius: 0
                    border: 1px solid $foundersBlue
                    &.selected
                        background-color: $foundersBlue
                        border-color: $foundersBlue
                        color: $white
        textarea
            border: 1px solid $foundersBlue !important

    .accent-color
        color: $accent-color !important
    .accent-hover-color:hover
        color: $foundersBlue !important

    .btn, .btn-primary
        +btn
        +btn-blog

    .blog-container .blog-article .blog-content
        min-height: 62vh
        h1, h2
            line-height: 1.1 !important
        @media screen and (max-width: 768px)
            min-height: 58vh

    .blog-article
        padding-bottom: 5rem

    .prev-link-wrap
        position: absolute
        right: 0
        left: 0
        bottom: 0

    .blog-container .blog-article .prev-link-wrap
        .prev-link, .next-link
            span
                font-family: $font_0
                font-size: 1.125em

            i
                font-size: 2em

    .loading-icon
        background: url('../../../../images/loading/FN-spin.gif') center no-repeat
        background-size: 3em
        height: 5em

    .cw-close
        color: $foundersBlue

.ja
    .activityGridRow .activity-item .headline a
        font-weight: bold
    .blog-wrapper .blog-view-container .headline-banner.headline-btn .btn-news, .ReactModalPortal .blog-view-container .headline-banner.headline-btn .btn-news
        font-family: $font_jaHeader
    .blog-view-container
        .blog-view
            .grid-item
                .grid-content
                    .title, .date
                        font-family: $font_0_ja
            .headline-banner,
            .headline
                h2
                    font-family: $font_jaHeader
    .article-view
        .cmsContainer
            h1, h2, h3, h4, h5, h6
                font-family: $font_jaHeader !important
