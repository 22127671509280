@import '../../../../styles/variables-mixins.sass'

.chapters-cta-footer
    position: relative
    margin-top: 5vw
    width: 100%
    height: 85vh
    min-height: 1200px
    +respond-max-width(xs)
        min-height: auto
        max-height: 400px
    +respond-handheld-max-width(sm, landscape)
        min-height: 600px
        max-height: auto
    .ctaBackground
        width: 100%
        height: 100%
        background-repeat: no-repeat
        background-size: cover
        background-position: left top
    .cta-footer-content
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        display: flex
        align-items: center
        justify-content: center
        > div
            width: 50vw
            +respond-max-width(md)
                width: 100vw
            img
                width: 100%
        .right-section
            color: white
            text-align: center
            float: right
            position: absolute
            right: 0
            +respond-max-width(md)
                margin-top: auto
                margin-bottom: 5%
                bottom: 0
            h1
                font-size: 9vw
                +respond-max-width(md)
                    font-size: 11vw
            h5
                font-size: 2vw
                letter-spacing: 1px
                +respond-max-width(md)
                    font-size: 6vw
            h1, h5
                margin: 0
            button
                width: auto
                font-size: 2vw
                margin-top: 5%
                +respond-max-width(md)
                    font-size: 4vw
                    margin-top: 5%
        .left-section
            width: 55vw
            color: white
            text-align: center
            float: left
            position: absolute
            left: 3vw
            top: 24vw
            +respond-max-width(sm)
                width: 100%
                left: 0
                top: unset
                bottom: 1vw
            h1
                font-size: 2vw
                +respond-max-width(sm)
                    font-size: 4.7vw
            h5
                font-size: 12vw
                letter-spacing: 1px
            h1, h5
                margin: 0
            button
                width: auto
                font-size: 2vw
                margin-top: 0
                +respond-max-width(md)
                    font-size: 4vw
                    margin-top: 5%
                    margin-bottom: 2%
            .animate-string.pass
                h5
                    font-size: 9vw
            .animate-string.battle
                margin-bottom: -3vw
        .center-section
            width: 55vw
            color: white
            text-align: center
            float: left
            position: absolute
            top: 5vw
            +respond-max-width(xs)
                width: 100%
                left: 0
                top: unset
                bottom: 1vw
            h1
                font-size: 2vw
                +respond-max-width(xs)
                    font-size: 4.7vw
            h5
                font-size: 12vw
                letter-spacing: 1px
            h1, h5
                margin: 0
            button
                width: auto
                font-size: 2vw
                margin-top: 0
                +respond-max-width(md)
                    font-size: 4vw
                    margin-top: 5%
                    margin-bottom: 2%
            .animate-string.pass
                h5
                    font-size: 9vw
            .animate-string.battle
                margin-bottom: -3vw

.chapter2s2
    .chapters-cta-footer
        height: 64vw
        min-height: 64vw
        +respond-max-width(sm)
            height: 100vw
            min-height: 100vw
        .ctaBackground
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: no-repeat top
            background-size: cover
            overflow: hidden

body.ko, body.ja
    .chapters-cta-footer
        .cta-footer-content
            .left-section
                .animate-string.battle
                    margin-bottom: 0
            .center-section
                .animate-string.battle
                    margin-bottom: -1vw

body.ja
    .chapters-cta-footer
        .cta-footer-content
            h5
                line-height: 1
body.ko
    .chapters-cta-footer
        .cta-footer-content
            h5
                font-family: $font_ko_art
