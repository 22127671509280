@import '../../../../styles/variables-mixins.sass'

.play-guide-view.home
    .content
        margin: 0
        padding: 0
        overflow: auto
    .guides
        display: flex
    .guide
        display: block
        background-repeat: no-repeat
        background-position: center
        background-size: auto 50%
        text-align: center
        width: 100%
        height: 55px
        background-size: 0
        padding-top: 10px
        transition: width 0.2s
        &:hover
            border-bottom: 5px solid #ECFD5F
        &:nth-child(1)
            background-color: #9a50d8
        &:nth-child(2)
            background-color: #3ddabb
        &:nth-child(3)
            background-color: #f36a22
            margin: 0
        span
            color: #fff
            font-size: 22px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
    .guide-text
        color: inherit
    +respond-max-width(xs)
        background: linear-gradient(-45deg, #72B8D1, #7BECCD) !important
    +respond-min-width(xs)
        .guide
            background-size: auto 50%
            padding-top: 85px
            height: 130px
            border-bottom: 6px solid transparent
            &:hover
                border-bottom: 6px solid #ECFD5F
            span
                font-size: 25px
    +respond-min-width(sm)
        background-repeat: no-repeat, no-repeat
        background-position: left top, left top
        background-size: cover, cover
        .guides
            display: block
            margin: 50px 0
        .guide
            margin-bottom: 15px
            height: 125px
            width: 152px
            &:hover
                width: 170px
                border: 0
            &:nth-child(1):hover
                clip-path: polygon(0 0, 0 3%, 19% 16%, 0 9%, 0 100%, 14% 93%, 10% 100%, 100% 100%, 100% 99%, 85% 86%, 100% 91%, 100% 0, 95% 0, 79% 9%, 86% 0)
            &:nth-child(2):hover
                clip-path: polygon(0 0, 0 3%, 11% 12%, 0 9%, 0 100%, 6% 100%, 18% 92%, 13% 100%, 100% 100%, 100% 96%, 88% 81%, 100% 90%, 100% 0, 98% 0, 85% 9%, 91% 0)
            &:nth-child(3):hover
                clip-path: polygon(0 0, 0 90%, 13% 82%, 0 98%, 0 100%, 91% 100%, 86% 91%, 100% 100%, 100% 9%, 86% 16%, 100% 4%, 100% 0, 13% 0, 18% 8%, 6% 0)
            span
                font-size: 20px
        .guide-info
            width: 328px
            padding: 30px 0
            margin: 0 12% 0 0
    +respond-min-width(md)
        .guide
            height: 165px
            width: 185px
            padding-top: 110px
            &:hover
                width: 210px
            span
                font-size: 24px
        .guide-info
            width: 450px
    +respond-min-width(lg)
        .guide
            height: 210px
            width: 240px
            padding-top: 135px
            &:hover
                width: 265px
            span
                font-size: 32px
        .guide-info
            width: 550px
    +respond-min-width(xl)
        .guide
            height: 262px
            width: 316px
            padding-top: 185px
            &:hover
                width: 372px
            span
                font-size: 40px
        .guide-info
            width: 742px
