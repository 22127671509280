@import '../../../../styles/variables-mixins.sass'

.ScheduleList
    padding-top: 3em
    max-width: 1200px
    width: 90%
    margin: 0 auto
    .container-fluid
        padding-top: 0.5em
        padding-bottom: 2em
    .ScheduleItem
        &:nth-child(odd)
            background: #ececec
        &:nth-child(even)
            background: white
        a, a:hover
            font-family: $font_2
            color: black
        a:hover
            text-decoration: underline

    .ScheduleListHeader
        position: relative
        .header
            position: absolute
            top: 11px
            +respond-min-width(sm)
                top: 3px
            width: 100%
            +respond-max-width(xs)
                position: relative
                top: unset
            +respond-max-width(md)
                h5
                    max-width: 50%
                    margin: 10px auto
        .countryselect
            padding: 0 0 1.5em 10px
            +respond-max-width(md)
                padding-bottom: 2.5em
            max-width: 200px
            margin: 0 10px 0 auto
            +respond-max-width(xs)
                max-width: 100%
                width: 100%
