@import '~epic-fortnite-styles/variables-mixins.sass'

.social-share-banner
    float: right
    top: 150px
    margin-right: 3%
    position: fixed
    right: 0
    z-index: 3
    +respond-max-width(xs)
        position: fixed
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        justify-content: space-around
        box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.5)
        width: 100%
        top: auto !important
        bottom: 0 !important
        margin: 0 !important
        right: 0 !important
        z-index: 9
    .social-share-button
        align-items: center
        background: $lighter-blue
        border: 0
        border-radius: 50%
        color: white
        cursor: pointer
        display: flex
        justify-content: center
        margin-top: 8px
        padding: 10px
        +respond-max-width(xs)
            border-radius: 0
            margin: 0
            flex: 1
    svg
        fill: currentColor
        height: 3rem
        width: 3rem
