@import '../../../../styles/variables-mixins.sass'

.answer
    font-size: 0.9em
.question
    font-size: 1em
.question,
.answer
    font-family: $font_4
    color: $darkGray
    padding-left: 3em
    +respond-max-width(xs)
        padding-left: 1.5em
    &:before
        display: inline-block
        margin-right: 1em
        margin-left: -2em
        float: left
        line-height: 1.5
    a
        color: darken($foundersBlue, 5)
        transition: color 0.1s ease-in-out
        &:hover
            color: lighten($foundersBlue, 5)
    p, > div
        margin: 0 0 5px

    img
        display: block
        max-width: 100%
        height: auto
    table
        border: 1px solid #3d3c3c
        width: 100%
        max-width: 500px
        margin: 0 auto
        td
            padding-left: 10px
            text-align: center
            p
                text-align: center !important
        tr:first-child
            background: $foundersBlue
            color: $invert-text-color


.question
    font-weight: bold
    &:before
        content: "Q: "

.answer
    margin-bottom: 2em
    &:before
        content: "A: "

.ja
    .question p
        font-weight: bold

body.ar
    .question,
    .answer
        padding-left: 0
        padding-right: 3em  
        div,
        p
            text-align: right
        li
            margin-right: 20px
        &:before,
        &:after
            margin-right: -2em
            margin-left: 2em
            float: right
        ul
            margin-top: 1em
    .question:before
        content: "Q : "
    .answer:before
        content: "A : "
