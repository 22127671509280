@import '../../../../styles/variables-mixins.sass'

.code-redeem
    background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat center bottom
    background-size: cover
    position: relative
    +respond-max-width(xs)
        background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat center bottom
    .content-container
        max-width: 1400px
        height: 70vh
        min-height: 845px
        position: relative
        padding: 2em 1em 0
        +respond-max-width(md)
            height: 60vh
            min-height: 635px
        +respond-max-width(sm)
            padding: 0 1em
            height: 100%
            min-height: 100%
        .heroes
            position: absolute
            bottom: 0
            right: 0
            left: 0
            width: auto
            height: 95%
            background: url(../../../../images/fnMobile/astro.png) no-repeat bottom left
            background-size: contain
            z-index: 0
            +respond-max-width(sm)
                background: url(../../../../images/fnMobile/astro_m.png) no-repeat bottom center
                position: relative
                min-height: 28em

        .content
            max-width: 35em
            margin: 8em auto 0
            color: $text-color
            position: relative
            z-index: 1
            +respond-max-width(sm)
                margin: 2em auto
            h2
                margin-bottom: 1em
                font-size: 2em
                +respond-max-width(sm)
                    font-size: 2em
                +respond-max-width(xs)
                    font-size:  1.25em
            p
                font-size: 1em
                max-width: 30em
                margin: 0 auto 1em
            .btn
                font-size: 1.8em
                margin-top: 1em
    &.android 
        background: url(../../../../images/siteBg/blueGridBkgrd.jpg) no-repeat center
        background-size: cover
        .content-container
            .heroes
                background: url(../../../../images/fnMobile/android.png) no-repeat bottom left
                background-size: contain
                +respond-max-width(sm)
                    background: url(../../../../images/fnMobile/android_m.png) no-repeat bottom center
                    background-size: contain
                +respond-handheld-max-width(xs, landscape)
                    display: none
