@import '../../../../styles/variables-mixins.sass'

.battlepass-more-rewards
    background-color: #000
    position: relative
    height: 90vh
    margin-top: -1px
    +respond-max-width(md)
        height: 50vh
        min-height: 700px
        display: flex
        flex-direction: column
    +respond-max-width(sm)
        height: 500px
        min-height: auto
    .more-rewards-image
        position: absolute
        right: 0
        bottom: 0
        height: 100%
        +respond-max-width(xs)
            height: initial
            width: 120%  
    .more-rewards-content
        position: absolute
        top: 50%
        transform: translateY(-50%)
        padding-left: 72px
        text-align: left
        max-width: 40%
        +respond-max-width(xs)
            position: static
            transform: none
            width: 100%
            max-width: 100%
            padding: 25px 5em
            text-align: center
        h1
            font-size: 6vw
            +respond-max-width(md)
                font-size: 6vw
            +respond-max-width(sm)
                font-size: 6vw
            +respond-max-width(xs)
                font-size: 10vw
        p
            font-size: 1.5vw
            color: #fff
            +respond-max-width(md)
                font-size: 2vw
            +respond-max-width(sm)
                font-size: 2.5vw
