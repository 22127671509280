@import '../../../../styles/variables-mixins.sass'

.patch-notes-view
    position: relative
    background-color: $background
    .section
        padding-bottom: 0
        &:after
            content: ""
            display: block
            margin: 4em 0
            border-bottom: 1px solid #b3b3b3
            width: 66.66666667%

        +respond-max-width(md)
            &:after
                width: 91.6666667%
        +respond-max-width(xs)
            padding-bottom: 2em
            &:after
                margin: 2em 0
                width: 100%
        hr
            display: none

        &.noBreak
            &:after
                display: none
