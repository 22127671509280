@import '../../../../styles/variables-mixins.sass'

.chapter2s2-header-wrapper
    position: relative
    height: 100vh
    .chapter2s2-header
        height: 100%
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-size: cover
        background-repeat: no-repeat
        +respond-max-width(xs)
            height: calc(177vw - 3.7em)
        .ch2-header-image
            width: 100%
    .content
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        color: white
        display: flex
        flex-direction: column
        justify-content: center
        +respond-max-width(xs)
            .btn
                margin-top: 3vw
        .animated-content
            display: flex
            align-items: center
            flex-direction: column
            margin-top: -12vw
            +respond-max-width(xs)
                text-align: center
            h1
                margin: 0 auto
                text-align: center
                width: 40vw
                font-size: 2vw
                +respond-max-width(xs)
                    width: 100%
                    font-size: 5vw
                    letter-spacing: 0.7vw
            .badge-img img
                margin-bottom: 1em
                width: 10vw
                +respond-max-width(xs)
                    width: 25vw
            .logo-img img
                width: 65vw
                margin-bottom: -5%
                margin-top: -3%
                +respond-max-width(xs)
                    width: 150vw
        .play-wrapper
            // transition: all 0.3s linear
            position: absolute
            right: 3vw
            bottom: 15%
            display: flex
            align-items: center
            // cursor: pointer
            +respond-max-width(xs)
                flex-direction: column
                bottom: 10%
                right: unset
            h3
                margin-right: 1vw
                font-size: 1.8vw
                +respond-max-width(xs)
                    font-size: 3vw
                    text-align: center
            .videoPlayButton
                font-size: 0.8vw
                margin: 0 auto
                +respond-max-width(xs)
                    font-size: 3vw
                &:hover
                    transform: scale(1.1) !important

body.ar
    .cta-footer-content
        .btn
            margin-top: 2.8vw

body.tr, body.ru, body.de
    .chapters-screens
        .screen-section
            .screen-content
                h1
                    line-height: 1
body.tr
    .chapters-cta-footer
        .cta-footer-content
            .left-section
                h5
                    line-height: 1.3
