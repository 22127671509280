@charset "UTF-8"
@import '../../../../styles/variables-mixins.sass'

.trailer-section
    margin: 1em auto
    display: flex
    justify-content: space-evenly
    +respond-max-width(xs)
        margin: 1em auto
    .trailer
        height: auto
        margin: 0 2em
        cursor: pointer
        +respond-max-width(xs)
            margin: 0 5px
            width: auto
        .trailer-img
            max-width: 300px
            width: 100%
            transform: scale3d(1.01, 1.01, 1.01)
            transition: all 0.3s ease-in-out
        h5
            font: $font-h5        
        &:hover
            .trailer-img
                transform: scale3d(1, 1, 1)