@charset "UTF-8"
@import '../../../../styles/variables-mixins.sass'

.fn-common-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: -99
    width: 100%
    height: 100%
    background-color: rgba(#106ea0, 0.98)
    display: flex
    opacity: 0
    transition: opacity 0.1s linear
    flex-direction: column
    .bkgrd
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: url(~epic-fortnite-react/images/fnMobile/bolts.png) no-repeat center
        background-size: cover
        z-index: 0
        +respond-max-width(xs)
            background-image: url(~epic-fortnite-react/images/fnMobile/bolts_m.png)
    .close-button
        display: inline-block
        background-color: #1ac5d9
        outline: none
        border: none
        margin-bottom: 0.5em
        +respond-max-width(md)
            right: 1em
        +respond-max-width(sm)
            top: 0.2em
        i
            display: inline-block
            padding-top: 0.3em
            font-size: 1.2em
    .overlay-container
        display: flex
        justify-content: center
        align-items: center
        position: relative
        flex: 1
        display: flex
        flex-direction: column
        .fn-content-container
            h1
                color: $white
                text-align: center
            .fn-overlay-btns
                display: flex
                flex-wrap: wrap
                a.fn-overlay-btn
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    margin: 5px
                    display: flex
                    background-color: $yellow
                    flex: 1
                    width: 15em
                    height: 15em
                    color: #000
                    >span
                        font-size: 5em
                        color: #000

.fn-common-overlay.visible
    z-index: 9999999
    opacity: 1