@import '../../../../styles/variables-mixins.sass'

.retail-help-section
    padding: 4em 0
    background-repeat: no-repeat 
    background-size: cover 
    background-position: left 20%
    h1 
        color: $text-color
        letter-spacing: 1px

.en_US,
.de,
.es_MX,
.fr,
.it,
.pt_BR,
.tr
    .retail-help-section h1
        font-family: $font_2

.es_ES,
.pl 
    .retail-help-section h1
        font-family: $font_2
    .btn.btn-display 
        +respond-max-width(xs)
            font-size: 1.2em
