@import '../../../../styles/variables-mixins.sass'
.fortnite-wrapper
    .cta-container
        padding: 1em 1em 5em 1em
        color: white
        h1, h2, h3
            margin: 0
        +respond-min-width(sm)
            div
                font-size: 1.5em
        +respond-max-width(xs)
            div
                font-size: 1em
        h1
            font-size: 5.8125vw
            line-height: 1.2em
        .btn-wrapper
            width: 100%
            display: flex
            justify-content: center
        .btn
            line-height: 2
            background-color: #8bff3a
            clip-path: polygon(0% 16%, 100% 6%, 98% 100%, 2% 90%)
            padding: 0.3875em 1em 0.125em 1em

        .btn:after
            height: 2em
        a
            +respond-max-width(sm)
                font-size: 0.7em
        a:hover
            color: #00c6ff !important
        p
            font-family: BurbankSmall-Black, sans-serif !important
            max-width: 44em
            margin: 0 auto
            font-size: 0.75em
            +respond-max-width(xs)
                font-size: 1em
        p.exitLink
            margin-top: 1vw
            +respond-max-width(sm)
                margin-top: 1em
            a:hover
                color: white !important
