@import '../../../../styles/variables-mixins.sass'

body:not(.ja):not(.ko):not(.ru)
    .subscription-view-wrapper
        p
            font-family: $font_2
            line-height: 1.25
            text-transform: uppercase

body.ru
    .fortnite-wrapper .battle-pass-view
        p
            font-family: $font_0 

.subscription-view-wrapper
    margin: 0 auto
    background-color: #fff
    .subscription-logo-image
        margin-bottom: -45px
        @media screen and (min-width: 1600px)
            margin-bottom: -90px
