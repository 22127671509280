@import '../../../styles/variables-mixins.sass'
$defaultbkgrd: #f1f4f6
.grid-layout
    position: relative
    max-width: $containerWidth
    min-width: 100%
    background: $defaultbkgrd
    padding-top: 2em
    padding-left: 0 !important
    padding-right: 0 !important
    @media screen and (max-width: $containerMinWidth)
        max-width: 100%
        width: 100% 
    +respond-max-width(sm)
        padding-left: 15px !important
        padding-right: 15px !important
    .slick-slider
        clear: both
        padding-bottom: 50px
        padding-left: 15px
        .slick-track
            display: flex
            .slick-slide 
                background: #ffffff
                margin-right: 15px
                .gridItem-small,
                .gridItem-medium,
                .gridItem-large,
                .gridItem-thirdPos
                    padding: 0
                .grid-item
                    .background-image
                        height: 70vw
        .slick-arrow
            bottom: 0
            top: auto
            height: auto
            &.slick-next
                right: 30%
                .icon-arrow
                    &:hover
                        transform: scale(1.3)
            &.slick-prev
                left: 30%
                .icon-arrow
                    transform: rotate(180deg)
                    &:hover
                        transform: rotate(180deg) scale(1.3)
            .slick-control
                width: 3em
                height: 3em
                border: 1px solid #fff
                border-radius: 50%
                color: #fff
                &:hover
                    color: #fff
                .icon-arrow
                    transition: all .3s linear
                    &:before
                        content: "\e92c"
