@import '../../../../styles/variables-mixins.sass'

.battlepass-items
    position: relative
    margin-top: -1px //because of the white line between sections
    background-color: #000
    padding-bottom: 5%
    +respond-max-width(xs)
        padding-bottom: 10%
    &.visible
        .item-bg-image
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
            // transform: translate3D(0, 0, 0)
            // opacity: 1
        .items-images
            img
                opacity: 1
    .item-bg-image
        opacity: 0
        width: 78vw
        // transform: translate3D(-100vw, 100%, 0)
        // transition: 0.5s linear
        opacity: 0
    .items-images
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 5vw
        display: flex
        align-items: baseline
        align-self: flex-start
        +respond-max-width(xs)
            left: -5vw
        img
            position: relative
            &:nth-child(1)
                margin-right: -9vw
                width: 35vw
                z-index: 10
                transition-delay: 0.5s
                transform: scale(1.1) translateY(22%)
                +respond-max-width(xs)
                    width: 40vw
            &:nth-child(2)
                width: 20vw
                z-index: 9
                transition-delay: 0.7s
            &:nth-child(3)
                width: 15vw
                z-index: 8
                transform: translateY(50%)
                transition-delay: 0.9s
                align-self: flex-start
            img
                width: 100%
    .items-content
        font-size: 1.5vw
        position: absolute
        right: 5vw
        top: 40%
        color: #000
        z-index: 1
        +respond-max-width(xs)
            right: 2vw
        h1
            font-size: 4.5vw
            width: 35vw
            +respond-max-width(xs)
                font-size: 7vw
                width: 40vw
        p
            margin-top: 2vw
            font-size: 1.5vw
            width: 25vw
            +respond-max-width(xs)
                font-size: 2.5vw


.chapter-2-season-3,
.ch2-s3
    .battlepass-items
        background-color: #fff
        padding: 2em 0 3em
        .items-content
            font-size: 1em
            max-width: 30vw
            h1 
                font-size: 4vw
                color: #0086cb
                width: 100%
                +respond-min-width(lg)
                    font-size: 3.8em
                +respond-max-width(xs)
                    font-size: 2em
            +respond-max-width(md)
                max-width: 21em
            +respond-max-width(xs)
                right: 3em
                max-width: 10em
            +respond-handheld-max-width(xs, portrait)
                right: 1em
        .items-images
            left: 0
            top: 50%
            position: relative
            width: 100%
            img:nth-child(1)
                width: 70%
                z-index: 1
                transform: none
                +respond-max-width(xs)
                    width: 70%

.es_ES,
.es_MX,
.de,
.it,
.ru,
.pt_BR
    .battlepass-items .items-content
        +respond-max-width(xs)
            h1
                font-size: 1.8em
        +respond-handheld-max-width(xs, portrait)
            right: 3em
