@import './mixins.sass'

$text-color: white
$yellow: #ff0
$invert-text-color: black
$font_4: OpenSans, sans-serif
$overlayBg: rgba(0,0,0,.7)

$purple: #7b02ff
$black: #1b1b1b
$blue: #2c82c5
$lightBlue: #3be1ff
$red: #e8473d

$disabledBtnColor: #ccc
$background: #fff
$bodyBackgroundDark: #2a2a2a
$bodyBackgroundLight: #f2eefc
$darkGray: #1e1e1e
$gray: #454545
$lightGray: #f8f8f8
$white: #fff
$foundersBlue: #1db8f3
$blue: #1ac2fe
$siteBlockBg: #f8f8f8
$accent-color: $yellow
$invert-text-color: #000000
$siteBlockTextSecondary: #bfbfbf
$accent-color-hover: darken($accent-color, 5%)

$lightGrayBackgroundFill: #fcfcfc
$lightGrayBorder: #e7e5e5

// Forms
$error: #bc0a0d
$disabled: #ececec

// font
$font_0: BurbankBigCondensed-Black, sans-serif
$font_1: BurbankSmall-Black, sans-serif
$font_2: BurbankBigRegular-Black, sans-serif
$font_4: OpenSans, sans-serif
$font_5: sans-serif
$font_8: OpenSans, icomoon, sans-serif
$font_9: BurbankBigCondensed-Black, icomoon, sans-serif
$font_ko_art: aERIN, sans-serif
$font_ko: NotoSansCJKkr, sans-serif
$font_jaHeader: Tazugane, sans-serif
$font_jaBody: NotoSansCJKjp, sans-serif
$font_0_ja: BurbankBigRegular-Black, Tazugane, sans-serif
