@import '../../../../styles/variables-mixins.sass'

.patch-notes-navigation
    background: #1c237a
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 90%, 18% 96%, 20% 100%)
    .patch-container
        max-width: 1600px
        margin: 0 auto
        padding: 2.5em 2em
        +respond-max-width(xs)
            padding: 2.5em 1em
        h1
            color: $text-color
            font-size: 6em
            margin: 0
            +respond-max-width(xs)
                font-size: 3em
                line-height: 0.9em
                margin-bottom: 0.2em
        p, div
            color: $text-color
            text-transform: uppercase
            margin: 0
        ul
            list-style: none
            margin: 0
            padding: 0
            +respond-max-width(xs)
                text-align: center
                margin: 1em auto
        .quick-link
            background-color: $text-color
            padding: 0.5em 0 1em
            display: inline-block
            margin: 0.2em
            text-align: center
            cursor: pointer
            overflow: hidden
            min-height: 7em
            max-width: 8em
            width: 100%
            clip-path: polygon(0% 100%, 0% 4%, 100% 0%, 100% 100%)
            +respond-max-width(sm)
                max-width: 7em
            h3
                max-width: 90%
                font-size: 1.6em
                line-height: 0.9em
                margin: 0.2em auto
            .link-jump
                font-weight: 500
                font-size: 0.75em
            .link-jump
                margin: 0
            &.Pink
                h3,.link-jump
                    color: $pink
            &.Blue
                h3,.link-jump
                    color: $lightBlue
            &.Dark
                h3,.link-jump
                    color: $darkText
            &.Yellow
                h3,.link-jump
                    color: $yellow
            &.Red
                h3,.link-jump
                    color: $magenta
            &.Purple
                h3,.link-jump
                    color: $purple
            &.Orange
                h3,.link-jump
                    color: $orange
            &.mintGreen
                h3,.link-jump
                    color: $mintGreen


.ru
    .patch-notes-navigation
        .quick-link
            width: 10em
.ja
    .patch-notes-navigation .patch-container
        h1
            +respond-max-width(xs)
                line-height: 1
        .quick-link
            width: 100%
            max-width: 12em
            min-height: auto
            h3
                line-height: 1
.de
    .patch-notes-navigation
        .quick-link h3
            word-break: break-word
