@import '~epic-fortnite-styles/mixins.sass'

.error500
    @include clearfix
    background: url('~epic-fortnite-react/images/error/404-bkgrd.jpg') no-repeat center top
    background-size: cover
    font-size: 16px
    overflow: hidden
    min-height: 90vh
    +respond-max-width(sm)
        min-height: 75vh

    .content
        h1
            font-family: BurbankBigCondensed-Black, sans-serif
            font-size: 200px
            line-height: 0.95
            text-transform: uppercase
            color: #fff
        h2
            font-family: BurbankBigCondensed-Black, sans-serif
            margin-bottom: 0.8em
            text-transform: uppercase
            color: #fff
        p
            font-family: BurbankSmall-Black, sans-serif
            font-size: 14.5px
            line-height: 1.5
            margin-bottom: 0.5em
            color: #fff
        @include respond-max-width(md)
            h1
                font-size: 60px
        @include respond-max-width(sm)
            padding: 1em
            h1
                font-size: 100px
            h2
                font-size: 30px
                margin-bottom: 1em
                +respond-max-width(xs)
                    margin-bottom: 0
            p
                font-size: 16px
    .errorBody
        position: relative
        margin-top: 180px
        margin-bottom: 180px
        margin-left: 10%
        @include respond-max-width(sm)
            margin-top: 100px
            margin-bottom: 100px
            &:before
                content: none
        @include respond-max-width(sm)
            margin-top: 60px
            margin-bottom: 60px
            margin-left: 0
            z-index: 1
        +respond-handheld-max-width(xs, landscape)
            margin-top: 0
    .btn.wobble-btn
        z-index: 5
        background-color: #ff0
        color: #000000
        &:before
            display: none
        +respond-max-width(md)
            width: auto
            margin-top: 0.5em
        +respond-max-width(xs)
            width: 100%
            padding-left: 0 !important
            padding-right: 0 !important
    .zombie
        background: url('~epic-fortnite-react/images/error/zombie-505.png') no-repeat center
        background-size: contain
        width: 44em
        height: 55em
        position: absolute
        right: 25%
        top: 10%
        @media screen and (max-width: 1495px)
            right: 2em
        +respond-max-width(md)
            top: 2em
            height: 42em
        +respond-max-width(sm)
            width: 28em
            height: 30em
            right: -5em
            top: 2em
        +respond-max-width(xs)
            width: 11em
            height: 12em
            right: 0

body.ar
    .error500
        text-align: right
        .content h1
            word-spacing: normal
            &:after
                content: none
        .errorBody
            margin-left: 0
            padding-right: 16%
            &:before
                left: auto
                right: 40em
            @include respond-max-width(md)
                &:before
                    right: 31em
            @include respond-max-width(sm)
                padding-right: 0
        .zombie
            right: initial
            @include respond-max-width(md)
                right: initial
            @include respond-max-width(md)
                right: initial
