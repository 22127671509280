@import '~epic-fortnite-styles/variables-mixins.sass'

#creative-mode
    #videoOverlay
        &.video-overlay
            position: fixed
            top: 0
            left: 0
            right: 0
            bottom: 0
            width: 100%
            height: 100%
            background: transparent
            z-index: 99
            visibility: hidden
            .full-height
                height: 100%
                padding: 0
            .video-container
                text-align: center
                +vertical-align
                box-shadow: 0 2px 23px -1px black
            .close-video
                position: absolute
                top: 0
                right: 0
                padding: 0.5em
                cursor: pointer
                i
                    font-size: 1.5em
                    color: $yellow
            &.active
                z-index: 11
                visibility: visible
                background: rgba(black, 0.9)
                transition: background 0.3s ease-in-out
                .video-container
                    transform-origin: 50% 50%
                    animation: zoom 0.75s ease-in-out
