@import '../../../styles/variables-mixins.sass'

.prev-link-wrap
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #1e1e1e
  clear: both
  font-size: 20px
  overflow: hidden
  padding: 15px 30px
  min-height: 60px
  +respond-max-width(xs)
    font-size: 14px
  .prev-link, .next-link
    display: flex
    flex: 1
    align-items: center
    gap: 2em
    i
      font-size: 1.5em
      float: right
    span
      text-transform: uppercase
      font-family: $font_1
      +respond-max-width(xs)
        display: none
    &:hover
      color: lighten($accent-color, 10%)
      cursor: pointer
  .next-link
    flex-direction: row-reverse
  .slug-link
    line-height: 1
  .prev-icon
    &:hover
      &:before
        border-color: transparent transparent $accent-color-hover transparent
      &:after
        border-color: $accent-color-hover transparent transparent transparent
    &:before
      display: block
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 0 0.5em 1em 0.5em
      border-color: transparent transparent $accent-color transparent
      transform: skewX(-45deg)
    &:after
      display: block
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 1em 0.5em 0 0.5em
      border-color: $accent-color transparent transparent transparent
      transform: skewX(45deg)
  .next-icon
    &:hover
      &:before
        border-color: transparent transparent $accent-color-hover transparent
      &:after
        border-color: $accent-color-hover transparent transparent transparent
    &:before
      display: block
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 0 0.5em 1em 0.5em
      border-color: transparent transparent $accent-color transparent
      transform: skewX(45deg)
    &:after
      display: block
      content: ''
      width: 0
      height: 0
      border-style: solid
      border-width: 1em 0.5em 0 0.5em
      border-color: $accent-color transparent transparent transparent
      transform: skewX(-45deg)
