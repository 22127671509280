@import '~epic-fortnite-styles/mixins.sass'

body.ar
    .page-jumbotron
        .section-header-imagebg
            transform: scaleX(-1)

.page-jumbotron
    .section-header-imagebg
        height: 260px
        width: 100%
        background-size: cover
        background-color: darken(gray, 20%)
        +respond-max-width(md)
            height: 220px
        +respond-max-width(sm)
            height: 160px
        +respond-max-width(xs)
            height: 130px


    .name-container
        position: relative
        left: 0
        color: white
        max-width: 1200px
        h1
            position: absolute
            top: -175px
            color: white
            +respond-max-width(md)
                top: -155px
            +respond-max-width(sm)
                top: -120px
            +respond-max-width(xs)
                top: -100px

