@import '../../../../styles/variables-mixins.sass'

.battlepass-gliders
    position: relative
    opacity: 0
    z-index: 1
    margin: 0
    &.visible
        animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
    .gliders-bg
        background-repeat: no-repeat
        background-size: cover
        background-position: center
        width: 100%
        height: 90vh
        min-height: 800px
        clip-path: polygon(0 11%, 100% 0%, 100% 90%, 0% 100%)
        +respond-max-width(md)
            min-height: auto
            max-height: 60vh
        +respond-max-width(xs)
            min-height: auto
            max-height: 400px
            clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%)
    .glider-images
        position: absolute
        top: 0
        right: 0
        left: 0
        bottom: 0
        img
            position: absolute
            &:nth-child(1)
                left: 5%
                bottom: 5%
                height: 70%
                animation: gliders 6s linear infinite
                +respond-max-width(md)
                    left: 0
                    height: 60%
                    bottom: 3%
                +respond-max-width(xs)
                    height: 50%
                    bottom: 20%
            &:nth-child(2)
                right: 0
                bottom: 5%
                height: 100%
                animation: gliders 5s linear infinite
                +respond-max-width(md)
                    bottom: 5%
                    right: -10%
                    height: 80%
                +respond-max-width(xs)
                    right: -15%
                    bottom: 20%
            &:nth-child(3)
                left: 10%
                top: 68%
                width: 30vw
                min-width: 580px
                animation: gliders 7s linear infinite
                +respond-max-width(md)
                    left: -10%
                +respond-max-width(xs)
                    width: 100%
                    min-width: auto
                    max-width: 250px
                    left: -10%
                    top: 80%
    .gliders-content-wrapper
        position: absolute
        top: 0
        bottom: 0
        right: 0
        left: 0
        display: flex
        align-items: center
        +respond-max-width(xs)
            align-items: normal
            top: 25%
        .gliders-content
            padding-left: 25%
            text-align: left
            max-width: 50%
            +respond-max-width(xs)
                max-width: 60%
            h1
                font-size: 6vw
                color: #fff
                +respond-min-width(lg)
                    font-size: 3.8em
                +respond-max-width(xs)
                    font-size: 8vw
            p
                margin: 0 auto
                margin-top: 2%
                font-size: 1.5vw
                width: 40vw
                +respond-max-width(xs)
                    font-size: 2.5vw
    .battlepass-gliders-clip-path
        clip-path: polygon(0 50%, 100% 0%, 100% 100%, 0% 100%)
        height: 150px
        width: 120%
        background-color: black
        position: absolute
        bottom: -2px
        +respond-max-width(md)
            clip-path: polygon(0 80%, 100% 0%, 100% 100%, 0% 100%)
            height: 100px
        +respond-max-width(xs)
            clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0% 100%)


@keyframes gliders
    0%
        transform: translate3d(0, 0, 0)
    25%
        transform: translate3d(1vw, 10px, 0)
    50%
        transform: translate3d(0vw, 20px, 0)
    75%
        transform: translate3d(-1vw, 10px, 0)
    100%
        transform: translate3d(0, 0, 0)

.chapter-2-season-3,
.ch2-s3
    .battlepass-gliders-clip-path
        background-color: #fff
    .battlepass-gliders
        z-index: 2
        .gliders-bg
            +respond-max-width(md)
                min-height: 650px
                height: 80vh
            +respond-max-width(xs)
                max-height: unset
                height: 100%
                min-height: 500px
        .glider-images
            img:nth-child(1)
                height: 25%
                bottom: noset
                top: 0
            img:nth-child(2)
                height: 90%
                bottom: 15%
                right: -20%
                @media screen and (max-width: 840px)
                    right: -15%
                    height: 70%
                    bottom: 20%
                +respond-max-width(xs)
                    right: -10%
                    height: 75%
                    bottom: 10%
                +respond-handheld-max-width(xs, portrait)
                    right: -50%
                    height: 60%
                    bottom: 15%
            img:nth-child(3)
                z-index: 1
                +respond-max-width(xs)
                    top: 70%
                +respond-handheld-max-width(md, landscape)
                    min-width: 350px

        .gliders-content-wrapper
            +respond-max-width(xs)
                align-items: center
                top: 0
            .gliders-content
                padding-left: 0
                margin-left: 10%
                max-width: 25em
                h1 
                    color: #000
                    font-size: 5em
                    +respond-max-width(xs)
                        font-size: 3em
                +respond-max-width(md)
                    margin-left: 5%
                +respond-max-width(xs)
                    max-width: 15em
                    

.de
    .battlepass-gliders .gliders-content-wrapper .gliders-content
        h1 
            font-size: 3.8em
