@import '../../../../styles/variables-mixins.sass'

.BgImgVd-container
    .logo
        max-width: 700px
        margin: 0 auto
        padding-top: 2em
        h1
            font-size: 1.18em
        img
            max-width: 100%
        +respond-max-width(sm)
            padding-bottom: 1em
            img
                padding: 1em 0
            h1
                margin: 0.2em
    .battlepass-image-cta
        width: 90%
        max-width: 1200px
        img.image-cta-background
            max-width: 100%
        margin: 0 auto
        z-index: 10
        .image-cta-content-wrapper
            .play-icon-wrapper
                +respond-max-width(xs)
                    span.videoPlayButton
                        height: 3.5em
                        width: 3.5em
                    p
                        padding-right: 0.6em
                +respond-min-width(lg)
                    p
                        font-size: 3vw
                    span.videoPlayButton
                        height: 5vw
                        width: 5vw
