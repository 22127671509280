@import '../../../../styles/variables-mixins.sass'

.chapter2s4-header-wrapper
    height: 90vh
    min-height: 1000px
    position: relative
    overflow: hidden
    +respond-max-width(md)
        height: 50vh
        min-height: 500px
    +respond-max-width(sm)
        height: 90vh
        max-height: auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-end
        padding-top: 10vh
        padding-bottom: 12vh
    .header
        height: 100%
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        +respond-max-width(xs)
            height: calc(177vw - 3.7em)
    .active.content
        .animated-content
            h1, h3
                transform: none
                transition: 0.5s ease-in-out
                animation: textAnimateIn 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.6s forwards
    .content
        position: absolute
        padding-top: 50px
        color: #fff
        left: 0
        right: 0
        width: 100%
        text-align: center
        top: 0
        transform: none

        height: 65vh

        +respond-max-width(md)
            height: 40vh

        +respond-min-width(sm)
            top: 5%
        +respond-min-width(md)
            top: 20%
        +respond-min-width(lg)
            top: 15%
        +respond-max-width(sm)
            height: 90vh
            max-height: auto
            display: flex
            flex-direction: column
            align-items: center
            justify-content: flex-start

            padding: 10vh 20px 12vh
        .animated-content
            button, a
                margin-top: 0
                +respond-max-width(sm)
                    margin-top: 1vw
                    margin-bottom: 0
            img
                width: 30vw
                margin-bottom: 0
                +respond-max-width(sm)
                    width: 90%
            h1, h3
                opacity: 0
            +respond-max-width(xs)
                text-align: center
            h1
                margin-top: 20px
                font-size: 8em
                text-shadow: 0 0 40px rgba(#00e7ff, 0.8),   0px 5px 8px rgba(#00e7ff, 0.3)
                font-style: italic
                +respond-max-width(xs)
                    font-size: 6em

                .es_ES &,
                .es_MX &,
                .fr &,
                .it &
                    padding-top: 30px
                    font-size: 6em
                    +respond-min-width(sm)
                        font-size: 8em
                    +respond-min-width(md)
                        font-size: 6em
                    +respond-min-width(lg)
                        font-size: 8em
                    @media screen and (min-width: 1600px)
                        font-size: 10em
                .pt_BR &
                    padding-top: 30px
                    font-size: 3em
                    +respond-min-width(sm)
                        font-size: 5em
                    +respond-min-width(md)
                        font-size: 4em
                    +respond-min-width(lg)
                        font-size: 6em
                    @media screen and (min-width: 1600px)
                        font-size: 10em
                .pl &
                    margin-top: 30px
                    font-size: 5em
                .ru &
                    font-size: 5em
                .tr &
                    padding-top: 30px
                    font-size: 5em
                    +respond-min-width(sm)
                        font-size: 6em
                    +respond-min-width(md)
                        font-size: 4em
                    +respond-min-width(lg)
                        font-size: 6em
                    @media screen and (min-width: 1600px)
                        font-size: 10em

            h3
                font-size: 2em
                letter-spacing: 6px
                font-style: italic

            .badge-img img
                margin-bottom: 1em
                width: 10vw
                +respond-max-width(xs)
                    width: 25vw
            .logo-img img
                width: 65vw
                margin-bottom: -5%
                margin-top: -13%
                +respond-max-width(xs)
                    margin-top: 10%
        .play-wrapper
            // transition: all 0.3s linear
            position: absolute
            right: 3vw
            bottom: 15%
            display: flex
            align-items: center
            // cursor: pointer
            +respond-max-width(md)
                bottom: 80px
            +respond-max-width(sm)
                bottom: 152px
            +respond-max-width(xs)
                flex-direction: column
                bottom: 10%
                right: 0
                left: 0
            h3
                margin: 0 1vw 0 0
                font-size: 1.5em
                +respond-max-width(xs)
                    text-align: center
                    margin-bottom: 0.5em
            .videoPlayButton
                font-size: 0.8vw
                margin: 0 auto
                +respond-max-width(xs)
                    font-size: 3vw
                &:hover
                    transform: scale(1.1) !important
    .footer-logo
        position: absolute
        height: 30px
        bottom: 50px
        left: 65px

        +respond-max-width(md)
            bottom: 70px
            left: 65px
        +respond-max-width(sm)
            bottom: 100px
            left: 15px
        +respond-max-width(xs)
            bottom: 80px
            left: 10px
