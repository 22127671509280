@import '../../../../styles/variables-mixins.sass'

$df-purple: #e88df3
.darkfireView
    background: #030000
    +respond-max-width(xs)
        font-size: 12px
    h1,h2,h3,h4,h5
        line-height: 100%
    .btn-hover-transform
        background-color: $df-purple !important
        &:hover
            background-color: $df-purple !important
    .header-section
        box-sizing: content-box
        max-width: 1600px
        margin: 0 auto
        display: flex
        padding: 5% 5% 0 5%
        justify-content: center
        align-items: center
        flex-wrap: wrap
        .header-section-left
            min-width: 300px
            flex: 1
            width: 50%
            z-index: 1
            img
                // transform: scale(1.1)
                width: 100%
            +respond-max-width(xs)
                order: 2
        .header-section-right
            flex: 1
            width: 50%
            text-align: center
            img
                width: 100%
                max-width: 525px
                +respond-max-width(xs)
                    width: 80%
            h1
                color: $df-purple
            h2
                color: white
                max-width: 500px
                margin: 2em auto
                +respond-max-width(xs)
                    width: 70%
            +respond-max-width(xs)
                order: 1

    .platform-section
        width: 100vw
        background-image: linear-gradient(-22deg, #1d0521 0%, #58126e 100%)
        padding: 4% 2%
        clip-path: polygon(0 4%,100% 0,100% 100%,0 100%)
        +respond-max-width(xs)
            clip-path: polygon(0 1%,100% 0,100% 100%,0 100%)
        .platform-section-wrapper
            max-width: 1600px
            margin: 0 auto
            .platforms-header
                padding: 3%
                text-align: center
                h1
                    color: white
                h3
                    color: white
            .platform-icons
                margin-top: 2%
                display: flex
                justify-content: center
                flex-wrap: wrap
                .platform-icon
                    list-style: none
                    max-width: 200px
                    min-width: 100px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    padding: 3%
                    color: white
                    box-sizing: content-box
                    i
                        font-size: 10em
                        +respond-max-width(sm)
                            font-size: 6em
                        +respond-max-width(xs)
                            font-size: 4em
                    > span
                        font-weight: 500
                        text-transform: uppercase
                        position: relative
                        +respond-max-width(xs)
                            font-size: 0.6em
                    &.note > span:after
                        content: '*'
                        position: absolute
                        left: 105%
            .platform-note
                color: $df-purple
                margin-top: 5%
                text-align: center
    
    .bundle-section
        max-width: 1600px
        margin: 0 auto
        padding-bottom: 10%
        header
            padding: 5% 5% 0 5%
            font-size: 20px
            h1
                text-align: center
                color: $df-purple
                +respond-max-width(xs)
                    font-size: 6vw
        .outfits
            position: relative
            margin: 0 5%
            display: flex
            // margin-top: -15%
            flex-direction: column
            +respond-max-width(xs)
                margin-top: 0
                align-items: center
            img
                width: 100%
                margin-bottom: 5%
                margin-top: -15%
                flex-shrink: 0 // ie 11 fix
                +respond-max-width(xs)
                    order: 1
                    margin-bottom: 0
            h1
                color: $df-purple
                font-size: 5vw
                bottom: 60%
                position: absolute
                max-width: 300px
                left: 0
                +respond-min-width(2k)
                    font-size: 58px
                +respond-max-width(xs)
                    order: 2
                    position: relative
                    font-size: 5vw
                    margin-bottom: 0
            .outfit-names
                position: absolute
                bottom: 0
                left: 0
                right: 0
                display: flex
                flex-direction: column
                max-width: 250px
                align-items: flex-start
                bottom: 50%
                +respond-max-width(xs)
                    align-items: center
                    max-width: unset
                    order: 3
                    position: relative
                h5
                    color: white
                    font-size: 1vw
                    margin: 0
                    line-height: 1.2
                    +respond-min-width(2k)
                        font-size: 20px
                    +respond-max-width(xs)
                        font-size: 3vw
        .guns
            position: relative
            margin: 5%
            display: flex
            flex-direction: column
            padding-bottom: 0
            +respond-max-width(xs)
                margin-top: 50px
                align-items: center
            img
                width: 100%
                flex-shrink: 0 // ie 11 fix
                +respond-max-width(xs)
                    order: 1
                    width: 100vw
            h1
                margin: 0
                color: $df-purple
                font-size: 3vw
                position: absolute
                max-width: 15vw
                top: 7%
                +respond-min-width(2k)
                    font-size: 58px
                +respond-max-width(xs)
                    position: relative
                    top: 0
                    font-size: 5vw
                    text-align: center
                    max-width: unset
                    width: 100%
                    order: 2
            .guns-names
                position: absolute
                left: 0
                right: 0
                bottom: 70%
                display: flex
                flex-direction: column
                align-items: flex-start
                max-width: 30%
                +respond-max-width(xs)
                    max-width: unset
                    position: relative
                    align-items: center
                    order: 3
                h5
                    color: white
                    font-size: 1vw
                    position: relative
                    margin: 0
                    line-height: 1.2
                    +respond-min-width(2k)
                        font-size: 20px
                    +respond-max-width(xs)
                        font-size: 3vw
        .emote
            position: relative
            width: 100%
            padding-top: 10%
            height: 0
            +respond-max-width(xs)
                padding-top: 0
            .emote-wrapper
                display: flex
                align-items: center
                position: absolute
                left: 70%
                top: -10vw
                +respond-max-width(xs)
                    left: 75%
                    top: -55vw
                    flex-direction: column

                h3
                    position: absolute
                    left: 100%
                    color: $df-purple
                    font-size: 1.5vw
                    width: 10vw
                    +respond-max-width(xs)
                        margin-top: 1em
                        position: relative
                        left: unset
                        order: 2
                        max-width: 20vw
                        font-size: 3vw
                        text-align: center
                img
                    max-height: 20vw
        .backBlings
            position: relative
            margin: 0 5%
            display: flex
            flex-direction: column
            +respond-max-width(xs)
                padding: 5% 0
            img
                width: 100%
                margin-bottom: 5%
                flex-shrink: 0 // ie 11 fix
                +respond-max-width(xs)
                    margin-bottom: 0
                    width: 120vw
            .text-wrapper
                position: absolute
                right: 0
                max-width: 25%
                top: 0
                bottom: 0
                display: flex
                justify-content: center
                flex-direction: column
                +respond-max-width(xs)
                    position: relative
                    max-width: unset
                    align-items: center
                h1
                    color: $df-purple
                    font-size: 3vw
                    margin: 0
                    +respond-min-width(2k)
                        font-size: 58px
                    +respond-max-width(xs)
                        font-size: 5vw
                .backBlings-names
                    margin-top: 10%
                    +respond-max-width(xs)
                        margin-top: 0
                    h5
                        color: white
                        font-size: 1vw
                        line-height: 1.2
                        margin: 0
                        +respond-min-width(2k)
                            font-size: 20px
                        +respond-max-width(xs)
                            text-align: center
                            font-size: 3vw
                            margin-top: 0%
        .axes
            position: relative
            margin: 0 5%
            display: flex
            flex-direction: column
            padding-bottom: 20%
            img
                width: 100%
                margin-bottom: 5%
                flex-shrink: 0 // ie 11 fix
                +respond-max-width(xs)
                    margin-bottom: 0
            h1
                color: $df-purple
                font-size: 3vw
                position: absolute
                max-width: 15%
                top: 15%
                margin: 0
                +respond-min-width(2k)
                    font-size: 58px
                +respond-max-width(xs)
                    position: relative
                    text-align: center
                    max-width: 100%
                    margin-bottom: 5%
                    order: 2
                    font-size: 5vw
                    top: -16vw
            .axes-wrapper
                margin-left: 10%
                position: relative
                +respond-max-width(xs)
                    margin-left: 0
                    display: flex
                    flex-direction: column
                .axes-names
                    position: absolute
                    top: 30%
                    left: -11%
                    display: flex
                    align-items: flex-start
                    flex-direction: column
                    +respond-max-width(xs)
                        align-items: center
                        top: 0
                        left: 0
                        right: 0
                        justify-content: space-around
                        position: relative
                        display: flex
                        flex-direction: column
                    h5
                        color: white
                        font-size: 1vw
                        top: 15%
                        position: relative
                        margin: 0
                        line-height: 1.2
                        max-width: 20vw
                        +respond-min-width(2k)
                            font-size: 20px
                        +respond-max-width(xs)
                            font-size: 3vw
                            max-width: unset

    .disks-section
        background: #fff
        position: relative
        &:before
            content: ""
            width: 100%
            position: absolute
            background: white
            height: 15%
            clip-path: polygon(0 100%,100% 0,100% 100%,0 100%)
            bottom: calc(100% - 1px)
            border: none
            +respond-max-width(xs)
                content: none
        // padding: 0 5%
        .disks-wrapper
            max-width: 1600px
            margin: 0 auto
            img.disks-pic
                width: 100%
                max-width: 1400px
                margin: 0 auto
                margin-bottom: -45%
                display: block
                transform: translateY(-70%)
                +respond-max-width(xs)
                    transform: translateY(-60%)
            .disks-content
                display: flex
                justify-content: space-between
                font-size: 2em
                align-items: center
                justify-content: center
                flex-direction: column
                color: black
                +respond-max-width(xs)
                    font-size: 1em
                h3
                    margin: 0 1em
                    +respond-max-width(xs)
                        font-size: 1.2em
                .btn-hover-transform
                    background-color: #8e16a5 !important
                    color: white
                    margin-top: 4%
                    align-self: center
                    +respond-max-width(xs)
                        margin-top: 1em
            .legal-terms
                max-width: 80%
                text-align: center
                color: #000
                padding: 5em 0
                margin: 0 auto
                line-height: 1.5
                display: flex
                > img
                    width: 232px
                    align-self: center
                    +respond-max-width(xs)
                        margin: 3% auto
                .legal-content
                    margin: 0 3%
                    display: flex
                    align-items: center
                +respond-max-width(xs)
                    flex-wrap: wrap
                    flex-direction: column
                    justify-content: center
                    max-width: 95%
                    font-size: 0.8em