@import '../../../../styles/variables-mixins.sass'
$backColor: #e9f8ff
$blueGray: #aac1cc
.retail-available-platforms
    background-color: $backColor
    padding: 3em 0
    position: relative
    +respond-max-width(xs)
        padding: 2em 0
    &:before
        content: "" 
        position: absolute
        bottom: 99%
        left: 0 
        width: 100%
        height: 3em 
        background-color: $backColor
        clip-path: polygon(0% 100%, 0% 55%, 30% 10%, 29% 50%, 100% 0, 100% 100%)
    .platformContainer
        width: 100%
        max-width: 1600px 
        margin: 0 auto 
    h1
        color: $freezeBlue
    h3 
        color: $blueGray
        &.black 
            color: $invert-text-color
            margin-bottom: 2em
            +respond-max-width(xs)
                margin-bottom: 1em
        i 
            display: inline-block 
            margin-left: 0.5em 
            transform: rotate(90deg)
    ul 
        margin: 0 0 4em
        padding: 0
        list-style-type: none
        +respond-max-width(xs)
            margin: 0 0 2em
        li 
            display: inline-block
            color: $invert-text-color
            margin: 0 3em
            +respond-max-width(sm)
                margin: 0 2em
            +respond-max-width(xs)
                margin: 1em 2em
                width: 30%
            i 
                display: block
                font-size: 8em
                +respond-max-width(sm)
                    font-size: 6em
                +respond-max-width(xs)
                    font-size: 4em
            span 
                font-weight: 800
                text-transform: uppercase

.en_US,
.de,
.es_MX,
.es_ES,
.fr,
.it,
.pl,
.pt_BR,
.tr
    .retail-available-platforms h1
        font-family: $font_2
.ja 
    .retail-available-platforms h3 
        font-size: 1.375em
