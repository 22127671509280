@import '../../styles/variables-mixins.sass'

$blog-bg: #000
$defaultbkgrd: #f1f4f6
body.ar
    .blog-view
        direction: rtl
        h1,
        h2,
        h3,
        h4
            font-family: $font_7 !important
        p,
        span
            font-family: $font_6
        h2
            font-size: 1.75em !important
        .btn
            font-size: 1.2em
    .blog-view
        .load-more
            .btn-load
                font-size: 1em

.loading-icon
    background: url('../../images/loading-bars.svg') center no-repeat
    height: 4em

.blog-view-container
    width: 100%
    min-height: 80vh
    background: $defaultbkgrd
    overflow: hidden

.blog-view
    padding: 2em 1em 0
    font-family: $font_4
    color: $blog-text
    background: $defaultbkgrd
    max-width: $containerWidth
    min-width: $containerMinWidth
    margin: 0 auto
    @media screen and (max-width: $containerMinWidth)
        max-width: 100%
        width: 100% 
        min-width: 100%
    .top-featured-activity
        padding: 0
        +respond-max-width(sm)
            padding: 0 1em 0
    
    +respond-max-width(xs)
        font-size: 12px
    .blog-region
        margin-top: 0
    a
        color: $accent-color
        &:hover
            color: $accent-color-hover
    .btn
        margin-top: 20px

    ul
        padding-left: 0
        li
            list-style: none
    .activity-title
        text-align: center
        padding: 4em
        +respond-max-width(md)
            padding: 2em
        h1
            margin: 0
    .category-wrapper
        padding-top: 2em
        padding-bottom: 2em
        margin-bottom: 0
    .load-more
        position: relative
        margin: 50px auto 0
        text-align: center
        .disabled
            color: grey
            border: 1px solid grey
            cursor: default
            background: transparent
            &:hover
                background-color: transparent
                color: grey
                border-color: grey

    .center-text-on-mobile
        @include respond-max-width(xs)
            text-align: center
    .btn-load
        margin: 1.8em 0
        display: inline-block
    .no-results
        margin: 4em auto
        text-align: center
    .back-main
        text-align: center
        padding: 1em 1em 2.5em

