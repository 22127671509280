@import '~epic-fortnite-styles/variables-mixins.sass'

.SocialButtons
    text-align: center
    .twitter
        background-image: url('../../../../images/creativeMode/twitter.svg')
    .discord
        background-image: url('../../../../images/creativeMode/discord.svg')
    .reddit
        background-image: url('../../../../images/creativeMode/reddit.svg')
    button, span
        display: inline
        margin: 0 1.5em
        width: 30px
        height: 30px
        border: none
        background-size: contain
        background-repeat: no-repeat
        background-position: center
    a
        span
            display: inline-block
    +respond-min-width(sm)
        position: absolute
        right: 0
        bottom: -0.7em
        text-align: right
