@import '../../../../styles/variables-mixins.sass'

.fortnite-wrapper
  .pack-buy-button
    &.tier-1
      .pack-dialog h1
        color: #adb8cd
        &.tier-2
          .pack-dialog h1
            color: $foundersBlue
        &.tier-3
          .pack-dialog h1
            color: #802abf
        &.tier-4
          .pack-dialog h1
            color: #e57d58

    .pack-dialog-container
      position: fixed
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: none
      width: 100%
      height: 100%
      cursor: default
      z-index: 999
      &.visible
        display: block
        .close
          opacity: 0.5
          transition: opacity 0.2s ease-in-out
          transform: translateZ(0)
          &:hover
            opacity: 1
            i
              color: $foundersBlue
        .pack-dialog-bg
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
          background: rgba(black, 0.7)
          z-index: -1

        .pack-dialog
          +vertical-align
          background: $white
          max-width: 460px
          margin-left: auto
          margin-right: auto
          min-height: 32em
          padding: 4em 2em
          color: $darkGray
          text-transform: uppercase
          clip-path: polygon(0% 5%, 24% 4%, 24% 7%, 27% 4%, 100% 0%, 100% 100%, 0% 98%)
          h1
            margin-top: 0
          h2
            color: $darkGray !important

            .coming-soon-container
              .retailers-list
                .retailer
                  display: inline-block
                  width: 50%
                  height: 2em
                  margin: 1em 0
                  .amazon
                    background: url(../../../../images/stw/amazon.png) center no-repeat
                    background-size: contain
                    .gamestop
                      background: url(../../../../images/stw/gamestop.png) center no-repeat
                      background-size: contain
                    .walmart
                      background: url(../../../../images/stw/walmart.png) center no-repeat
                      background-size: contain
                    .target
                      background: url(../../../../images/stw/target.png) center no-repeat
                      background-size: contain
            .btn
              display: block
              margin: 0 auto

        .cta-step
          margin-bottom: 1em
          select
            width: 100%
            padding: 1em
            &:active, &:hover
              outline: $accent-color
            .cta-step-container > label
              display: block
            .step-label
              font-weight: 800
              color: $grey
            &.cta-radio
              label + label
                margin-left: 1em
                input
                  margin-right: 0.25em
        .legal-disclaimer
          font-family: $font_2
          font-size: 0.8em
          margin-top: 1em
          text-align: center
          display: block
        ul.pack-dialog-legalTags
          margin-top: 1em
          text-align: center
          list-style: none
          padding: 0
          li
            display: inline-block
            p
              margin: 0
            img
              display: inline-block
              max-height: 100px
              max-width: 100%
              margin: 0 auto
              padding: 5px
