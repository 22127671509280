@import '../../../../styles/variables-mixins.sass'

.android-view
    background: #0583fc
    background-image: linear-gradient(135deg, #0583fc 0%,#3fdbf8 95%, #3fdbf8 100%)
    .btn.btn-display.no-animate:after
        clip-path: polygon(2% 5%, 98% 0, 100% 100%, 0 100%)
    .btn.btn-display span
        display: block
        margin-top: 3px
    .redeemView
        background: #0583fc
        background-image: linear-gradient(135deg, #0583fc 0%,#3fdbf8 95%, #3fdbf8 100%)
