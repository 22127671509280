@import '../../../../styles/variables-mixins.sass'

.battlepass-xp-progression
    background-color: #000
    padding: 5% 0
    display: flex
    justify-content: flex-end
    margin-top: -1px
    +respond-max-width(xs)
        flex-direction: column
    &.visible
        // opacity: 1
        .xpPregression-image
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.3s forwards
    .xpProgression-image-container
        display: flex
        align-content: flex-end
        .xpPregression-image
            margin: 0 auto
            opacity: 0
            width: 60%
            // width: 20%
            max-width: 1440px
            align-self: center // need this to fix img stretch in safari
            z-index: 1
            +respond-max-width(xs)
                width: 70%
    .xpPregression-content-wrapper
        display: flex
        justify-content: flex-start
        align-items: center
        z-index: 1
        +respond-max-width(xs)
            order: 2
            .xpPregression-content
                padding: 0
                h1, p
                    width: 80%
                    margin: 5% auto
                    text-align: center
    .xpPregression-content
        padding-left: 10%
        color: #000
        h1
            font-size: 5vw
            width: 35vw
        p
            margin-top: 2vw
            font-size: 1.5vw
            width: 40vw
            +respond-max-width(xs)
                font-size: 2.5vw
    
.ch2-s2
    .xpPregression-content-wrapper
        .xpPregression-content
            color: #fff
