@import '../../../../styles/variables-mixins.sass'

#faq
    padding-bottom: 6em
    .section
        margin-bottom: 2em
        padding: 1em
        +respond-max-width(xs)
            padding: 0
        &#general
            border: none
        .content
            margin: 0 auto
            h2
                font-family: $font_0
                font-size: 2.4em
                color: $darkGray
                text-transform: uppercase
                padding-bottom: 1em
                +respond-max-width(xs)
                    font-size: 1.5em
.ja
    #faq .section .content
        h2
            font-family: $font_jaHeader

.ar
    #faq .section
        h2
            text-align: right
