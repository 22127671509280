@import '../../../../styles/variables-mixins.sass'

.header-fnMobile-container
    position: relative
    max-width: 40em
    margin: 0 auto 1em
    +respond-max-width(sm)
        margin: 0 auto 1em
    h1
        color: $invert-text-color
        font-size: 5em
        margin: 0 auto
        +respond-max-width(lg)
            font-size: 4em
        +respond-max-width(sm)
            font-size: 3em
        +respond-max-width(xs)
            font-size: 2.5em
        span
            text-transform: none
    &.android 
        h1 
            color: $text-color
.de,
.es_ES,
.fr,
.pt_BR
    .fn-mobile-view
        .sign-up-page
            h1
                font-size: 4em
                +respond-max-width(sm)
                    font-size: 2em

.es_MX,
.pl,
.tr,
.ru,
.it
    .fn-mobile-view
        .sign-up-page
            h1
                font-size: 3em
                +respond-max-width(sm)
                        font-size: 2em
