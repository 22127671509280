@import '../../../../styles/variables-mixins.sass'

.battlepass-background-header
    position: relative
    height: max(80vh, 750px)
    width: 100vw
    .above-title h4
        font-size: 2em
        color: #fff
        letter-spacing: 10px
        font-style: italic
        span
            display: block
        +respond-max-width(md)
            font-size: 2em
        +respond-max-width(sm)
            font-size: 1.5em
        +respond-max-width(xs)
            font-size: 0.8em
            letter-spacing: 5px
    .video-title h1
        font-size: 10em !important
        margin: 20px 0 0 0
        color: #fff
        text-shadow: 0 0 40px rgba(0,0,0,.8), 0 5px 8px rgba(0,0,0,.3)
        +respond-max-width(md)
            font-size: 8em !important
        +respond-max-width(xs)
            margin-top: 0
            font-size: 4em !important

    .video-copy h3
        max-width: 100%
        font-size: 1.5em !important
        margin: 10px 0 20px
        text-shadow: 0 0 40px rgba(0,0,0,.8), 0 5px 8px rgba(0,0,0,.3)
        font-style: italic
        word-spacing: 3px
        +respond-max-width(md)
            font-size: 1em !important
        +respond-max-width(xs)
            max-width: 23em
            padding: 0 1em
            margin: 10px auto 20px
    .video-badge
        position: absolute
        left: 5vw
        top: 5%
        img
            width: 50px
            margin-bottom: 0
            +respond-max-width(xs)
                width: 40px
    .video-dots-mask
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkxNjgyMDA2NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkxNjgyMDA3NjJBNzExRTBCNjU1QzhBRDY1RUJCOUU4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTE2ODIwMDQ2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTE2ODIwMDU2MkE3MTFFMEI2NTVDOEFENjVFQkI5RTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7UWbiJAAAAHUlEQVR42mJiYGD4DwQggoEJSDAyQAETAxIACDAAJMMJ/r07h+kAAAAASUVORK5CYII=')
        background-color: rgba(0,0,0,0.4)

    .content
        color: white
        position: absolute
        justify-content: center
        align-items: center
        display: flex
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1
        .animated-content
            text-align: center
            .vh-duration
                position: absolute
                right: 5vw
                top: 5%
                +respond-max-width(xs)
                    top: 0
                    right: 50%
                    transform: translate(50%, 50%)
                h5
                    max-width: 15vw
                    font-size: 1.5vw
                    line-height: 100%
                    +respond-max-width(xs)
                        max-width: 30vw
                        font-size: 3.5vw

            .play-wrapper
                display: flex
                justify-content: center
                cursor: pointer
                margin-top: 2em
                transition: transform 200ms ease-out
                &:hover
                    transform: scale(1.1) !important
                    +respond-max-width(xs)
                        font-size: 0.8em
                        margin-top: 2em
                +respond-max-width(xs)
                    margin-top: 1em !important
.es_ES,
.es_MX,
.fr,
.it,
.pl,
.ru,
.pt_BR
    .battlepass-background-header .content h1
        +respond-max-width(md)
        font-size: 12vw
.tr
    .battlepass-background-header .content h1
        margin: 36px 0
        +respond-max-width(xs)
        margin: 20px 0
.ko
    .battlepass-background-header .content h3
        +respond-max-width(xs)
            font-size: 1.5em

.de,
.es_MX,
.es_ES,
.pt_BR,
.it,
.pl,
.tr,
.ru
    .battlepass-background-header .video-title h1
        +respond-max-width (md)
            font-size: 6em !important
        +respond-max-width(xs)
            font-size: 3em !important

.tr
    .battlepass-background-header .video-title h1
        line-height: 1.2

.nexus-war
    .battlepass-background-header
        +respond-handheld-max-width(md, landscape)
            min-height: 400px
        .video-badge
            position: absolute
            left: 5vw
            top: 5%
            img
                width: 9.5vw
                margin-bottom: 0
                +respond-max-width(xs)
                    width: 82px
        .video-title h1
            font-size: 10em !important
            margin-top: 20px
            color: #fff
            text-shadow: 0 0 40px rgba(0,0,0,.8), 0 5px 8px rgba(0,0,0,.3)
            font-style: italic
            +respond-max-width(md)
                font-size: 8em !important
            +respond-max-width(xs)
                margin-top: 0
                font-size: 6em !important
                span
                    padding-right: 0
                    line-height: 1
                span + span
                    display: block
                    font-size: 0.75em !important
                    margin: 0
                    padding: 0
                    line-height: 50px
        .above-title h4
            letter-spacing: 6px !important
            font-style: italic
            text-shadow: 0 0 40px rgba(0,0,0,.8), 0 5px 8px rgba(0,0,0,.3)
            +respond-max-width(md)
                font-size: 1em !important
            +respond-max-width(xs)
                margin: 0
                letter-spacing: 1px !important

        .video-copy h3
            max-width: 100%
            font-size: 1.5em !important
            text-shadow: 0 0 40px rgba(0,0,0,.8), 0 5px 8px rgba(0,0,0,.3)
            +respond-max-width(md)
                font-size: 1em !important
            +respond-max-width(xs)
                padding: 0 1em
        .play-wrapper
            +respond-max-width(xs)
                margin-top: 1em !important

.ar .nexus-war .battlepass-background-header
    .video-title h1
        margin-bottom: 0.2em

.es_ES,
.es_MX,
.fr,
.it,
.pl,
.ru
    .nexus-war .battlepass-background-header
        .video-title h1
            +respond-max-width(sm)
                font-size: 4em !important
            span
                padding-right: 0.2em
            span + span
                display: inline-block
                font-size: 1em !important
.pt_BR,
.tr,
.ja
    .nexus-war .battlepass-background-header .video-title h1
        font-size: 7em !important
        +respond-max-width(sm)
            font-size: 5em !important
        span + span
            display: block
            font-size: 0.5em !important

.ja
    .nexus-war .battlepass-background-header
        .video-title h1
            +respond-max-width(xs)
                font-size: 3.5em !important
        .above-title h4
            +respond-max-width(sm)
                margin-bottom: 1em
