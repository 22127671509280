@import '../../../../styles/variables-mixins.sass'
body.fr,
body.ar
    #img-container
        .caption span
            max-width: 170px
body.ar
    #img-container
        .cpation span
            margin: 1em auto
#img-container
    overflow: hidden
    position: relative
    min-width: 100%
    min-height: 100%
    background: $darkGray
    .img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-position: 50% 0
        background-size: cover
        transform: scale3d(1.3, 1.3, 1.3)
        transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out
        +respond-max-width(md)
            transform: scale3d(1,1,1)
        +respond-max-width(xs)
            transform: scale(1)
            opacity: 0.45
    .caption
        position: absolute
        top: 0
        right: 0
        left: 0
        padding: 1em 2.5em
        // min-height: 300px
        transform: translateY(12vw)
        transition: transform 0.25s ease-in-out
        max-height: 95%
        //overflow-y: auto
        @media screen and (max-width: 1300px)
            transform: translateY(9vw)
        +respond-max-width(md)
            transform: translateY(10vw)
        +respond-max-width(sm)
            transform: translateY(50px)
        +respond-max-width(xs)
            // min-height: 200px
            transform: translateY(1em)

        h2
            color: $text-color
            text-align: center
            margin-bottom: 0
            position: relative
            padding: 0.5em 0
            text-shadow: 0 2px 20px $invert-text-color
            // overflow: hidden
            // max-height: 4em
        .title-underline
            position: relative
            margin: 0 auto
            width: 100%
            height: 3px
            background: $text-color
            transition: width 0.5s ease-in-out
            margin-bottom: 1em
            +respond-min-width(sm)
                width: 10%

        p,
        a,
        span
            opacity: 0
            transform: translateY(20px)
            transition: opacity 0.25s ease-in-out, transform 0.3s ease-in-out
            transition-delay: 0s
            +respond-max-width(xs)
                opacity: 1
                transform: translateY(0)
                transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out
                transition-delay: 0.125s
        p
            color: $text-color
            margin: 0 auto
            text-align: center
            overflow: hidden
            max-width: 500px
            max-height: 8em
            @media only screen and (min-width: 1600px)
                max-height: 10em
            @media only screen and (max-width: 1350px)
                white-space: nowrap
                text-overflow: ellipsis
            +respond-max-width(md)
                white-space: nowrap
                text-overflow: ellipsis
            +respond-max-width(sm)
                max-height: 6em
                white-space: nowrap
                text-overflow: ellipsis
            +respond-max-width(xs)
                max-height: none
                white-space: nowrap
                text-overflow: ellipsis
        a,
        span
            color: $accent-color
            display: block
            margin: 0.5em auto
            position: relative
            max-width: 100px
            text-align: center
            &:before,
            &:after
                content: ''
                display: block
                position: absolute
                top: 0
                height: 100%
                width: 2px
                background: $accent-color
                opacity: 0
                transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out, right 0.25s ease-in-out
                transition-delay: 0.5s
            &:before
                left: 0.5em
            &:after
                right: 0.5em
    +respond-min-width(xs)
        &:hover
            .caption
                transform: translateY(0)
                .title-underline
                    width: 100%
                p,
                span
                    opacity: 1
                    transform: translateY(0)
                    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out
                    transition-delay: 0.125s
                a,
                span
                    &:before,
                    &:after
                        transition-delay: 0
                        opacity: 1
                    &:before
                        left: -0.5em
                    &:after
                        right: -0.5em

            .img
                transform: scale(1)
                opacity: 0.45

