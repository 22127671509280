@import '../../../../styles/variables-mixins.sass'
$lightBlue: #bef5ff

.retail-header
    height: 80vh
    min-height: 875px
    position: relative
    +respond-max-width(sm)
        max-height: 830px
        min-height: 835px 
    .backgroundImage 
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100% 
        background-repeat: no-repeat
        background-size: cover
        background-position: center
    .contentContainer 
        max-width: 1600px
        width: 100%
        height: 100%
        margin: 0 auto
        position: relative
        .content-left 
            position: relative
            z-index: 3
            padding-top: 25%
            +respond-max-width(sm)
                padding-top: 3em
        .logoContainer 
            max-width: 30em 
            width: 100%
            margin: 0 auto
            h3 
                color: $lightBlue
                font-size: 2em
                margin: 0 auto
                +respond-max-width(sm)
                    font-size: 1.7em
            .logo 
                width: 100% 
                height: 6em 
                background: url(../../../../images/logo/fnLogo_white.png) no-repeat center
                background-size: contain
                +respond-max-width(sm)
                    height: 5em
                +respond-max-width(xs)
                    height: 4em
        .content
            margin-top: 4em
            +respond-max-width(sm)
                margin-top: 2em
            .bundle-description 
                margin-bottom: 4em
                +respond-max-width(sm)
                    margin-bottom: 2em
                h1
                    width: 100%
                    max-width: 11em
                    margin: 0 auto
                    color: $text-color
                    font-size: 3.2em
                    letter-spacing: 1px
                    font-style: italic
                    +respond-max-width(sm)
                        font-size: 3em
                    +respond-max-width(xs)
                        font-size: 2em
            .btn.btn-border                 
                i 
                    display: inline-block 
                    transform: rotate(90deg)
                    margin-left: 0.5em
                    transition: color 0.3s ease-in-out
                &:hover 
                    i 
                        color: $yellow
            a 
                color: $text-color
                text-decoration: underline
                transition: color 0.3s ease-in-out
                display: block
                margin-top: 1em
                &:hover 
                    color: $yellow
        .heroContainer 
            +respond-max-width(xs)
                position: relative
                width: 100%
                max-width: 20em
                margin: 1em auto 0
                height: 28em
            .hero 
                position: absolute 
                bottom: 0 
                right: 1em 
                width: 100%
                height: 100%
                background-size: auto 110%
                background-position: right bottom
                background-repeat: no-repeat
                z-index: 0
                +respond-max-width(sm)
                    right: -2.5em
                +respond-max-width(xs)
                    background-size: auto 100%
                    background-position: center bottom
                    right: 0
            .hero + .hero 
                z-index: 3
                width: 9.7em
                +respond-max-width(sm)
                    width: 9em
                +respond-max-width(xs)
                    width: 4em
                    background-position: right bottom

body[data-firefox="true"],
body[data-msie="true"]
    .retail-header .contentContainer .content .btn.btn-border
        border: 10px solid $text-color
        clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%)
        &:hover 
            border-color: $yellow
.en_US,
.de,
.es_ES,
.es_MX,
.fr,
.it,
.pl,
.tr
    .retail-header .contentContainer .content .bundle-description h1
        font-family: $font_2
        font-size: 3.5em
        max-width: 10em
        +respond-max-width(md)
            font-size: 2.3em
        +respond-max-width(xs)
            font-size: 1.5em
.es_MX,
.pt_BR
    .retail-header .contentContainer
        .content .bundle-description 
            margin-bottom: 1em
        .logoContainer h3
            +respond-max-width(xs)
                font-size: 1.5em
.it,
.pt_BR
    .retail-header .contentContainer .content
        .btn.btn-border 
            +respond-max-width(xs)
                font-size: 1.7em
                i 
                    margin-left: 0.25em
.pt_BR
    .retail-header .contentContainer .content .bundle-description h1
        font-family: $font_2
        font-size: 3em
        max-width: 14em
        +respond-max-width(md)
            font-size: 2.3em
        +respond-max-width(xs)
            font-size: 1.5em
.ru
    .retail-header .contentContainer .content
        .btn.btn-border 
            font-size: 1.7em
            span 
                transform: scale(0.95)
                display: inline-block
            i 
                margin: 0 0.25em
.ja 
    .retail-header .contentContainer .content
        .bundle-description h1
            font-size: 2.75em
            +respond-max-width(md)
                font-size: 2.3em
            +respond-max-width(xs)
                font-size: 1.5em
        .btn.btn-border 
            font-family: $font_jaHeader
            +respond-max-width(xs)
                font-size: 1.7em
            span 
                transform: scale(0.93)
                display: inline-block
            i 
                margin: 0 0.25em
