@import '../../../../styles/variables-mixins.sass'

.PosterList
    max-width: 1200px
    width: 90%
    margin: 0 auto
    .PosterItem
        padding: 10px
        .poster, .title
            background-color: #ececec
            max-width: 500px
            margin: 0 auto
        .title
            padding: 1.5em 0.5em
            +respond-max-width(xs)
                font-size: 1.5em
            +respond-min-width(md)
                font-size: 1.5em
        img
            max-width: 100%
