@import '../../../../styles/variables-mixins.sass'

.sign-up-page
    .header-wrapper
        background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat top center
        background-size: cover
        position: relative
        +respond-max-width(xs)
            background-image: url(../../../../images/fnMobile/fnMobileBk_m.jpg)
        .content-wrapper
            position: relative
            height: 60vh
            min-height: 645px
            max-width: 65em
            +respond-max-width(sm)
                height: 100%
                min-height: 100%
            .head
                position: relative
                z-index: 1
                padding-top: 4em
                .content
                    max-width: 40em
                    color: $text-color
                    position: absolute
                    right: 0 
                    transition: all 0.3s ease-in-out
                    +respond-max-width(sm)
                        position: relative
                        max-width: 30em
                        margin: 0 auto

                    h2
                        font-family: $font_1
                        margin-bottom: 1em
                        font-size: 2em
                        text-transform: initial
                        +respond-max-width(sm)
                            font-size: 2em
                        +respond-max-width(xs)
                            font-size:  1.25em
                    h3
                        font-family: $font_1
                        font-size: 1.2em
                        text-transform: uppercase
                        max-width: 30em
                        margin: 0 auto
                        +respond-max-width(sm)
                            font-size: 1em
                    p
                        font-size: 0.8em
                        max-width: 30em
                        margin: 0 auto 1em
                    .main-content
                        margin-bottom: 1em
                        p
                            font-size: 1em
                            max-width: 30em
                            margin: 0 auto 1em
            .btn-container
                margin: 0 auto
                position: relative
                bottom: -2em
                left: 0
                right: 0
                +respond-max-width(xs)
                    bottom: -1em
                    margin-top: 1em
                .btn
                    &.height
                        padding: 1em 2em
                        white-space: normal
                        span
                            line-height: 1
                            font-size: 1.7em
                        &:after
                            height: 100%
                        +respond-handheld-max-width(lg, landscape)
                            font-size: 1.2em
                        .small
                            display: block
                            font-family: $font_4
                            font-size: 0.6em
                            text-transform: none
                            +respond-max-width(sm)
                                font-size: 0.8em
                    +respond-max-width(sm)
                        font-size: 1em
            .space-man
                position: absolute
                bottom: 0
                left: 0
                right: 0
                width: auto
                height: 98%
                background: url(../../../../images/fnMobile/astro.png) no-repeat bottom left
                background-size: contain
                z-index: 0
                +respond-max-width(sm)
                    display: none

    .white-bk
        background-color: white
        padding: 0 1em
        position: relative
        +respond-max-width(sm)
            padding: 3em 1em
        &:before
            content: ''
            position: absolute
            bottom: 99.9%
            left: 0
            right: 0
            height: 13.5em
            width: 100%
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="600" height="50"><polygon points="0,50 600,20 600,50" style="fill:#ffffff;stroke:#ffffff;stroke-width:0"></polygon></svg>')
            background-repeat: no-repeat
            background-size: cover
            +respond-max-width(sm)
                display: none
        .text-section
            max-width: 1400px
            padding-top: 1em
            +respond-max-width(xs)
                padding-top: 0
            .requirements p
                max-width: 100%
                margin: 0 auto 0.5em
                font-size: 1em
            p, .aux-message
                max-width: 50em
                margin: 4em auto
                font-size: 1em
                +respond-max-width(sm)
                    font-size: 1em
                    margin: 2em auto                                
            .media-container
                margin: 2em 0 0
                +respond-max-width(md)
                    .video:after,
                    .background-video:after
                        display: none
    &.android
        .header-wrapper 
            background: url(../../../../images/siteBg/blueGridBkgrd.jpg) no-repeat center 
            background-size: cover 
            .content-wrapper
                height: 50vh
                min-height: 615px
                max-width: 65em
                +respond-max-width(sm)
                    height: 100%
                    min-height: 100%
                .head .content h2 
                    font-size: 1.5em 
                .space-man 
                    background: url(../../../../images/fnMobile/android.png) no-repeat bottom left
                    background-size: contain
    &.createAccount 
        .header-wrapper .content-wrapper .head
            .content
                max-width: 35em
                .main-content
                    h2 
                        font-size: 1.8em
                        margin: 0
                    img 
                        max-width: 8em
                        margin: 1em 0
                p 
                    font-family: $font_1
                    font-size: 1.2em 
                    line-height: 1.5
                    max-width: 25em
                .blueText
                    color: #215685
                    margin-top: 1em
                .btn-container
                    max-width: 31em
                    +respond-handheld-max-width(lg, landscape)
                        max-width: 24em
.de,
.es_ES,
.es_MX,
.fr,
.it,
.ja,
.pl,
.pt_BR,
.ru
    .fn-mobile-view
        .sign-up-page
            .content
                h2
                    margin-bottom: 1em
                    font-size: 1.5em
                    +respond-max-width(sm)
                        font-size: 1.2em
                    +respond-max-width(xs)
                        font-size:  1.25em
            .btn-container .btn
                font-size: 1.5em
                white-space: normal
                +respond-max-width(md)
                    font-size: 1em
.es_MX,
.pl,
.ru
    .fn-mobile-view
        .sign-up-page
            .content-wrapper
                .head
                    padding-top: 2em
                    +respond-max-width(sm)
                        padding-top: 2em
.tr
    .fn-mobile-view
        .sign-up-page
            .content-wrapper
                .content
                    h2
                        margin-bottom: 1em
                        font-size: 1.5em
                        +respond-max-width(sm)
                            font-size: 1.2em
                        +respond-max-width(xs)
                            font-size:  1.25em
.ko
    .fn-mobile-view
        .sign-up-page
            .content-wrapper
                .content
                    h2
                        font-size: 1.5em
                        font-family: $font_ko_art
                        font-weight: 300
                    h3
                        font-family: $font_ko_art
                        font-weight: 300
                .btn-container .btn.height span:first-child
                    font-family: $font_ko_art

