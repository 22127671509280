@import '../../../../styles/variables-mixins.sass'

.inviteView
    background: url('../../../../images/sfs/background.jpg') center no-repeat
    background-size: cover

$darkBlue: #002A44
$lightBlue: #00C6FF
$red: $red

// POSA Custom Style
#fortnite-posa-redemption.epic-code-redemption
    .ecr-ctaSection
        display: none
    &.landingPageViewed
        .ecr-faqSection
            display: none
        .ecr-rich-text
            display: none
        .ecr-redeemSection
            background-repeat: no-repeat
            background-size: cover
            .ecrrs-left
                text-align: center
                img
                    width: 60%
                    max-width: 800px
                    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.6))
                    padding: 20px
    &.codeVerified
        .ecr-redeemSection
            .ecrrs-left
                flex: 1
                margin-left: unset
                text-align: center
                img
                    max-width: 800px
                    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.6))
                    padding: 20px

#fortnite-posa-redemption.epic-code-redemption, .posaSuccessView
    h1, h2, h3, h4
        line-height: 100%
    .ecr-tooltip
        .ecr-tooltip-wrapper
            .ecr-tooltip-popup
                background-color: $darkBlue
                &:after
                    border-color: $darkBlue transparent transparent transparent
                .ecr-tooltip-tip
                    h1
                        color: $lightBlue
                        font-size: 2em
                    p
                        color: #fff
            .ecr-tooltip-icon
                border-color: $darkBlue
            &:hover
                .ecr-tooltip-icon
                    background-color: $darkBlue
    .ecr-redeemSection
        background-size: cover
        background-repeat: round
        +respond-max-width(sm)
            background-size: cover
            background-repeat: no-repeat

        .input-wrapper
            max-width: calc(100% - 2em)
            input, .ecr-dropdown-menu
                padding: 1em
        .platformInput
            .ecr-dropdown-row.warning
                color: $red
                opacity: 1

        .input-wrapper.verified:before
            color: $yellow
            content: '\e901'
            font-family: 'icomoon'
            font-weight: normal
            font-style: normal
            font-size: 1.5em
            left: -1.5em
        .redeem-next
            font-family: $font_2
            text-transform: uppercase
            margin-bottom: 8px
            font-size: 150%
            strong
                color: white
            span
                color: $darkBlue
        &.verified
            .ecrrs-right
                padding-bottom: 6em
                .confirm-next-section
                    align-items: center
                    +respond-max-width(xs)
                        align-items: unset
                    button
                        margin-top: 0
                        // min-width: 150px
                        text-transform: uppercase
                        padding: 10px 2em
                    span
                        margin-left: 0.7em
                        font-weight: bold
                        +respond-max-width(xs)
                            margin: 4% 0
                .psnWarning
                    font-weight: bold
                    font-size: 1.4em
                    color: yellow
                    font-family: $font_2
                    text-transform: uppercase
                .psnWarning.psn-sign-out
                    text-decoration: underline
                    padding: 0 1em
        &.unloggedIn
            // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 91%)
            .ecrrs-left
                text-align: center
                img
                    width: 60%
                    max-width: 800px
                    filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.6))
                    padding: 20px
        .ecrrs-left
            flex: 1
        .redeem-next.next-select-platform
            word-spacing: nowrap
        .ecrrs-right
            padding-right: 0
            +respond-max-width(sm)
                // text-align: center
                margin-left: 0
            .ecrrs-notLoggedIn, .ecrrs-loggedIn
                padding-left: 0
                margin-left: 0
                button
                    background-color: $yellow
                    color: $darkText
                    padding: 2% 8%
                    font-family: $font_2
                    font-size: 1.5em
                    margin-top: 2%
                    &.disabled
                        pointer-events: none
                h1
                    color: white
                    +respond-min-width(lg)
                        font-size: 4em
                h2
                    color: $yellow
                p
                    font-family: $font_4
                    font-weight: 500


    .ecr-faqSection
        padding-bottom: 0
        @media only screen and (min-width: 769px)
            padding-right: 0
        .ecrfs-left
            max-width: 100%
            padding-left: 5%
            padding-right: 5%
            @media only screen and (min-width: 769px)
                max-width: 1000px
            h1
                font-size: 4em
                color: $darkBlue
            h5
                font-size: 1.2em
                color: $red
            .ecr-faqItem-question
                h3
                    color: $lightBlue
                .expand-icon
                    color: $darkBlue
            .ecr-faqItem-answer
                font-weight: bold
                a
                    font-size: unset
                    font-weight: bold
                    &:hover
                        text-decoration: underline
        .ecrfs-right
            // max-width: 650px
            padding-left: 5%
            margin-left: auto
    .ecr-rich-text
        p
            font-weight: 500
            padding-left: 5%
            a
                font-weight: bold
                font-size: inherit
    .ecr-ctaSection
        padding-bottom: 0
        padding-top: 0
        padding-left: 0
        .ecrcs-left
            position: relative
            align-self: stretch
            margin-top: -5%
            overflow: hidden
            img
               position: absolute
               +respond-max-width(lg)
                    bottom: 0
        .ecrcs-content
            button
                background-color: #000
                color: #fff
                font-size: 1.2em
                padding: 8px 25px
                font-family: $font_2
                margin-top: 3%

    .ecr-posa-confirm-overlay
        background-color: rgba(16,110,160,.98)
        z-index: 9999999
        overflow: auto
        &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: url(~epic-fortnite-react/images/fnMobile/bolts.png) no-repeat center
            background-size: cover
            z-index: 0
        .ecrp-confirm-close
            font-size: 1.3em
            background: #1ac5d9
            width: 1.2em
            height: 1.2em
            display: flex
            justify-content: center
            align-items: center
            color: $darkGray
            font-weight: bolder
            transition: all 0.2s
            &:hover
                transform: scale(1.05)
        .ecrp-confirm-content

            > h1
                font-size: 3vw
            > h3
                color: $yellow
            .ecrp-confirm-box
                display: flex
                align-items: center
            .ecrp-confirm-table
                background-color: #28abf8
                padding-top: 1vw
                width: 65%
                order: 1
                +respond-max-width(sm)
                    width: 90%
                margin: 0 auto
                h1
                    font-size: 2.7vw
                h3
                    margin: 0 5.3vw 0.9vw 5.3vw
                    color: #002a44
            .ecrp-confirm-detail-row
                padding: 3% 6%
                border-bottom-color: #1592E6
                &:first-child
                    border-top-color: #1592E6
            .ecrp-confirm-detail-row:nth-child(odd)
                background: #0d99eb
                border: unset
            .ecrp-confirm-notice, .ecrp-bonus-notice
                background-color: $darkBlue
                padding: 2%
                width: 100%
                .ecn-important
                    color: $yellow
            .ecrp-bonus-wrapper
                order: 2
                margin-left: 10%
                width: 30%
                h3
                    color: #002a44

            .ecn-warning-msg
                max-width: 80%
                margin: 0 auto
                color: $red
                background: white
                padding: 1em
                font-size: 90%
            .ecrp-confirm-action
                margin-top: 1em
                button
                    border-width: 0
                button:first-child
                    padding: 0.5em 2em
                    font-family: $font_2
                    background-color: $yellow
                button.ecrp-confirm-reset
                    border: none
                    background: transparent
                    font-size: 0.8em
                    font-weight: bold
                    &:hover
                        text-decoration: underline

#fortnite-posa-redemption.epic-code-redemption.posaSuccessView
    +respond-max-width(xs)
        font-size: 14px
    .ecr-success-header
        background-attachment: unset
        +respond-max-width(xs)
            background-attachment: fixed
        img
            width: 100%
    .ecr-success-header-wrapper
        .ecrs-button, .ecrs-copiedText
            font-family: $font_2
        .ecrp-bonus-notice
            background-color: $darkBlue
    &.null, &.pc, &.mobile, &.nintendo
        .ecr-success-header
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4vw), 0 100%)
            font-size: 2.5vw
            .ecr-success-header-wrapper
                text-align: unset
                .ecrs-content
                    display: flex
                    align-items: center
                    +respond-max-width(sm)
                        flex-direction: column-reverse
                    .right-part, .left-part
                        flex: 1
                        padding: 0 6%
                        max-width: 600px
                    .right-part
                        font-size: 1.3em
                        color: white
                        h1
                            color: white
                            font-size: 5vw
                        h3
                            font-size: 2vw
                            color: $white
                        .btn-primary
                            font-size: 1.2vw
                            padding: 0.5vw 1.5vw 0.3vw 1.5vw
                        +respond-max-width(sm)
                            h1
                                color: white
                                font-size: 8vw
                            h3
                                font-size: 4vw
                            .btn-primary
                                font-size: 2.4vw
                                margin-bottom: 4vw

        .ecr-ctaSection
            display: flex
            text-align: center
            margin-top: -3%
            .bg-image
                all: unset
                position: absolute
                top: 0
                left: 0
                width: 100vw
                +respond-max-width(sm)
                    width: auto
                    height: 100%
            .ecrcs-left
                display: none
            .ecrcs-right
                padding: 6%
                .ecrcs-content
                    >div
                        h1
                            color: white
                        p
                            font-weight: bold
    &.psn, &.xbl
        .ecr-success-header .ecr-success-header-wrapper
            text-align: unset
            .ecrs-content
                display: flex
                font-size: 1.2em
                position: relative
                +respond-max-width(sm)
                    flex-direction: column
                &.with-bonus
                    .left-part 
                        padding-bottom: 450px
                    .ecrp-bonus-wrapper 
                        position: absolute
                        top: 450px
                        left: 160px
                        +respond-max-width(lg)
                            top: 450px
                            left: 2%
                        +respond-max-width(sm)
                            top: 370px
                        +respond-max-width(xs)
                            left: 0
                            top: 460px

                .right-part, .left-part
                    flex: 1
                    padding: 0 2%
                .left-part
                    max-width: 600px
                    font-size: 1.3em
                    color: white
                    h1
                        color: white
                        font-size: 3.75em
                    h3
                        color: $yellow
                        font-size: 1.5em
                .right-part
                    max-width: 800px
                    h3
                        color: $darkBlue
                    h1
                        display: inline-block
                        margin: 0
                        background: white
                        padding: 20px 30px
                        clip-path: polygon(10px 0%,100% 0,calc(100% - 10px) 100%,0 100%)
                        color: $darkBlue
                    h4
                        color: white
                        a
                            font-family: $font_2
                    p
                        font-weight: 450
                    a
                        color: $yellow
                        text-decoration: underline
                        font-weight: bold
                    a.ecrs-button
                        color: black
            > ul li
                margin-top: 1em
                margin-left: 34vw
                @media only screen and (min-width: 1555px)
                    margin-left: 200px
                +respond-max-width(sm)
                    margin-left: unset
                &:before
                    border-color: white
                    color: white
                button, a
                    color: black
                    background: $yellow
                    text-align: center
                    font-family: $font_2
                .ecrs-copiedText
                    font-family: $font_2
        .ecr-steps-slider
            .ecr-slider-wrapper
                > h1
                    max-width: 600px
                    color: $darkBlue
                    margin: 0 auto 1em
                img
                    margin-bottom: 1em
                li > div
                    max-width: 600px
                    margin: 0 auto
                    p, a
                        font-weight: bold
                    a
                        text-decoration: underline
.ar
    #fortnite-posa-redemption.epic-code-redemption .ecr-redeemSection, .posaSuccessView .ecr-redeemSection
        background-size: cover
        +respond-max-width(sm)
            direction: rtl
        .ecrrs-right
            direction: rtl
    #fortnite-posa-redemption.epic-code-redemption
        .ecr-faqSection .ecrfs-left
            padding-left: 0
            padding-right: 5%
        .ecr-faqSection .ecrfs-left,
        .ecr-rich-text
            direction: rtl
        .ecr-tooltip .ecr-tooltip-wrapper .ecr-tooltip-popup
            left: 50%
            +respond-max-width(sm)
                left: 90%
            &:after
                left: 46%
                +respond-max-width(sm)
                    left: 5%
        .ecr-tooltip
            margin-right: 10px
        .ecr-redeemSection .ecrrs-loggedIn .input-wrapper input
            margin-right: 0
        .ecr-redeemSection .ecrrs-loggedIn .input-wrapper.verified:before
            right: -1.5em
        .ecr-redeemSection .ecrrs-loggedIn .confirm-next-section
            display: block
            > span
                margin-right: 10px
        &.posaSuccessView.psn, &.posaSuccessView.xbl
            .ecr-success-header .ecr-success-header-wrapper .ecrp-bonus-wrapper
                top: 400px!important
.ru
    #fortnite-posa-redemption.epic-code-redemption
        button
            font-family: $font_0 !important

.ja
    #fortnite-posa-redemption.epic-code-redemption
        .ecr-posa-confirm-overlay
            .ecrp-confirm-content
                > h1
                    font-size: 2em
                .ecrp-confirm-table
                    h3
                        font-size: 2.25em
