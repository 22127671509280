@import '../../../../styles/variables-mixins.sass'

.simple-tab-list-section-wrapper
    a
        text-decoration: none
    .simple-tab-list-section-header
        font-size: 4em
        margin-bottom: 0
        +respond-max-width(md)
            font-size: 3em
        +respond-max-width(xs)
            font-size: 2em
    .simple-tab-list-item-container
        padding: 10px 0
        margin: 15px 0
        &.item-has-link
            cursor: pointer
            &:hover
                .icon-reverse-right-arrow
                    transform: translateX(0.2em) scale(1.2)
        .simple-tab-list-section-content-container
            display: flex
            align-items: center
            .icon-reverse-right-arrow
                color: #ff4640
                margin-left: 10px
                font-size: 1.6em
                transition: 0.2s ease-in-out
                transition-property: transform, scale
        p
            margin-bottom: 0
