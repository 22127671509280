@charset "UTF-8"
@import '../../../styles/variables-mixins.sass'

$success: $green
$warning: $orange
$info: $blue
$danger: $red
$text: #fff

.alert-container
    padding-top: 2em
    line-height: 2em
    .alert
        margin-bottom: 0
        .col-xs-1:last-child
            @media only screen and (max-width: 480px)
                position: absolute
                right: 15px
    .alert-icon
        line-height: 1em
        font-size: 1.4em
        vertical-align: text-bottom
    .alert-success
        border-color: $success
        background-color: $success
    .alert-warning
        border-color: $warning
        background-color: $warning
    .alert-info
        border-color: $info
        background-color: $info
    .alert-danger
        border-color: $danger
        background-color: $danger

    .alert-success,
    .alert-warning,
    .alert-info,
    .alert-danger
        border-radius: 0
        color: $text

    .close
        opacity: 1
        line-height: 1.4em
        font-size: 1.4em
        vertical-align: middle
        .epic-blog-icon-close
            color: $text

    p
        margin-bottom: 0
