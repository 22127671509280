@import '../../../../styles/variables-mixins.sass'

body:not(.ko), body:not(.ja)
    .fortnite-wrapper .battle-pass-view
        p
            font-family: $font_2
            line-height: 1.25
            text-transform: uppercase

body.ru
    .fortnite-wrapper .battle-pass-view
        p
            font-family: $font_0 
