@import '~epic-fortnite-styles/common-variables.sass'

.TakeOverView
    background: black
    div
        position: relative
        margin: 0 auto
        .text
            position: absolute
            top: 246px
            right: 0%
            letter-spacing: 0.1em
            font-size: 2.1em
            font-family: BurbankBigRegular-Black
            background: -webkit-linear-gradient(left, #fffbc6, #8d660f 75%)
            background: -o-linear-gradient(right, #fffbc6, #8d660f 75%)
            background: -moz-linear-gradient(right, #fffbc6, #8d660f 75%)
            background: linear-gradient(to right, #fffbc6, #8d660f 75%) 
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

.TakeOverView.desktop
    padding: 90px 0
    div
        width: 830px
        img
            width: 830px
            min-width: 830px
            max-width: 830px
.TakeOverView.mobile
    display: none
    div
        width: 320px
        img
            min-width: 320px
            max-width: 320px
        .text
            top: 370px
            right: 40px
            font-size: 1.2em

+respond-max-width(md)
    .TakeOverView.mobile
        display: block
    .TakeOverView.desktop
        display: none

html[lang="ko"]
    .TakeOverView
        div
            .text
                right: -4% !important
                +respond-max-width(md)
                    right: 0% !important
