@import '../../../../styles/variables-mixins.sass'

.squareButton
    display: block
    padding: 1.5em 1.75em
    font-family: $font_4
    font-size: 11px
    font-weight: 500
    letter-spacing: 0.91px
    text-transform: uppercase
    cursor: pointer
    transition: all 0.3s ease-in-out
    &.centered
        margin: 0 auto
    &.inline
        display: inline-block
    &.filled
        background-color: $accent-color
        border: 1px solid transparent
        color: $siteBlockBg
        &:hover
            background-color: $accent-color-hover
    &.clear
        background-color: transparent
        border: 1px solid $accent-color
        color: $accent-color
        &:hover
            background-color: darken($siteBlockBg, 5%)
