@import '../../../../styles/variables-mixins.sass'
$pink: #ec1d8d
.patch-notes-social-share
    position: relative
    max-width: 1400px
    margin: 0 auto
    +respond-max-width(sm)
        margin: 0 auto 1em
    .share-container
        position: absolute
        right: 2em
        list-style: none
        margin: 0
        padding: 0
        li
            width: 2em
            height: 2em
            margin: 0.5em 0
            border-radius: 50%
            text-align: center
            background-color: $pink
            cursor: pointer
            transition: background-color 0.3s ease-in-out
            +respond-min-width(md)
                &:hover
                    background-color: darken($pink, 5%)
            i
                display: block
                +vertical-align
                color: $text-color
        +respond-max-width(sm)
            position: relative
            right: auto
            left: 1em
            li
                display: inline-block
                margin: 0.5em 0.2em
