@import '../../../../styles/variables-mixins.sass'

.battlepass-loadingScreens
    position: relative
    margin-top: 2vw
    opacity: 0

    &.visible
        animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
    .loadingScreens-content
        text-align: center
        padding: 2vh 2vw
        color: #000
        h1
            font-size: 5vw
        p
            // max-width: 600px
            font-size: 1.5vw
            margin: 0 auto
            width: 75vw
            +respond-max-width(xs)
                font-size: 2.5vw
    .loadScreens-slider
        display: flex
        flex-direction: column-reverse
        .slick-slide
            overflow: hidden
            .loadScreenSlide 
                background-position: center
                background-repeat: no-repeat
                background-size: cover
                width: 100%
                height: 80vh
                min-height: 685px
                margin: 0 1em
                +respond-max-width(sm)
                    min-height: auto
                    max-height: 400px
                +respond-max-width(xs)
                    min-height: auto
                    max-height: 200px
                    
        .loadScreens-paginations
            padding: 0
            display: flex !important
            justify-content: center
            align-items: center
            margin-top: 20px
            li
                position: relative
                display: inline-block
                margin: 0 0.5em
                button
                    cursor: pointer
                    outline: none
                    height: 10px
                    width: 160px
                    border: none
                    background: #29ade4
                    color: transparent
                    opacity: 0.3
                    transition: 0.5s ease-in-out
                    +respond-max-width(xs)
                        width: 60px
                &.slick-active
                    button
                        opacity: 1
