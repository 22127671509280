@charset "UTF-8"

.twitch-chat-embed
    height: 27.4vw
    @media (max-width: 1650px)
        height: 20vw
    @media (max-width: 1200px) and (min-width: 992px)
        height: 30vw
    iframe
        width: 100%
        height: 100%

.twitch-preview-anchor img
    height: 100%

