@charset "UTF-8"
.twitch-video-embed
    height: 100%
    .twitch-preview-anchor
        img
            width: 100%
            height: auto
    iframe
        width: 100%
        height: 100%
