@import '../../../../styles/variables-mixins.sass'

.axe-wrapper
    position: relative
    background-color: #000
    .battlepass-axes
        position: relative
        padding: 0 2em
        opacity: 0
        height: 100vh
        min-height: 600px
        width: 100%
        background-color: #000
        padding: 5% 0
        z-index: 1
        margin-top: -1px
        +respond-max-width(lg)
            height: auto
        +respond-max-width(md)
            height: 50vh
        +respond-max-width(xs)
            min-height: auto
            max-height: 80vw
        +respond-handheld-max-width(sm, landscape)
            min-height: 375px
            max-height: auto
        &.visible
            animation: appear 0.5s cubic-bezier(0.7, 0, 0.1, 1) 0.2s forwards
        .axe-image
            position: absolute
            right: 72px
            top: 0
            max-width: 50%
            @media screen and (max-width: 1300px)
                // max-width: 60%
            +respond-max-width(md)
                transform: translateX(1em)
                max-width: 60%
                right: 10px
                top: 50%
                transform: translateY(-50%)
        .axes-content
            padding-left: 72px
            position: absolute
            color: #fff
            top: 50%
            transform: translateY(-50%)
            max-width: 40%
            +respond-max-width(md)
                top: 80%
                padding-left: 25px
            h1
                font-size: 7vw
            p
                margin: 0 auto
                margin-top: 2vw
                font-size: 1.5vw
                +respond-max-width(xs)
                    font-size: 2.5vw

.chapter-2-season-3,
.ch2-s3
    .axe-wrapper
        background-color: #fff
        max-width: 1200px
        margin: 0 auto
        padding: 0 3em
        +respond-max-width(xs)
            padding: 0 1em
    .battlepass-axes
        background-color: #fff
        padding: 0
        height: 50vh
        +respond-max-width(md)
            height: 40vh
            min-height: 400px
            padding: 0
        +respond-max-width(xs)
            height: auto
            min-height: auto
            max-height: unset
            text-align: center
        .axe-image
            max-width: 54%
            position: relative
            right: 0
            float: right
            transform: translateX(5%)
            +respond-max-width(md)
                top: 0
                max-width: 50%
                transform: none
            +respond-max-width(xs)
                position: relative
                top: unset
                right: unset
                padding: 0
                margin: 1em auto
                transform: none
                max-width: 70%
                float: none
        .axes-content
            color: #000
            padding-left: 0
            left: 0
            position: relative
            max-width: 46%
            h1 
                color: #0086cb
                font-size: 4vw
                +respond-min-width(lg)
                    font-size: 3.8em
                +respond-max-width(xs)
                    font-size: 2em
                    max-width: 9em
                    margin: 20px auto 10px
            p 
                font-size: 1.2vw
                max-width: 35em
                margin-top: 0
                +respond-min-width(lg)
                    font-size: 1.4em
                +respond-max-width(md)
                    font-size: 1.2em
                +respond-max-width(xs)
                    font-size: 1em
            
            +respond-max-width(md)
                top: 50%
                padding: 0
                max-width: 50%
            +respond-max-width(xs)
                position: relative
                top: unset
                padding: 0
                margin: 0 auto
                transform: none
                max-width: 100%

.de
    .battlepass-axes .axes-content
        h1 
            +respond-max-width(md)
                font-size: 2.5em
            +respond-max-width(xs)
                font-size: 2em

.ko
    .battlepass-axes .axes-content
        p 
            font-family: $font_ko_art !important
            font-size: 1.5em
