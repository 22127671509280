@import '~epic-fortnite-styles/variables-mixins.sass'

.CreativeHomeGrid
    max-width: 1200px
    width: 100%
    margin: 0 auto
    .bumper
        top: 25%
        left: -50px
        height: 125px
        width: 125px
        transform: rotateY(0deg) rotate(-25deg)
        transition: transform 0.5s
        +respond-max-width(xs)
            transform: rotateY(0deg) rotate(-10deg)
            top: 55px
            left: 20px
            height: 60px
            width: 60px
        +respond-min-width(xl)
            top: 25%
            left: -85px
            height: 150px
            width: 150px
    .duck
        top: 75%
        right: -100px
        height: 150px
        width: 150px
        transform: rotateY(0deg) rotate(25deg)
        transition: transform 0.4s
        +respond-max-width(xs)
            top: 45px
            right: 30px
            height: 50x
            width: 50px
        +respond-min-width(xl)
            top: 55%
            right: -145px
            height: 200px
            width: 200px
    .dino
        top: 50%
        left: -120px
        width: 250px
        height: 250px
        transition: transform 0.4s
        +respond-max-width(xs)
            top: 85px
            right: -40px
            left: unset
            height: 100px
            width: 100px
    .stormdevice
        top: 10%
        right: -9%
        width: 152px
        height: 250px
        transform: rotateY(0deg) rotate(25deg)
        transition: transform 0.4s
        +respond-max-width(xs)
            top: 60px
            left: 15px
            height: 80px
            width: 80px
            right: unset
        +respond-min-width(xl)
            right: -105px
            top: 15%
            width: 160px
            height: 275px
    .island
        img
            width: 100%
    .icon-reverse-right-arrow
        padding-top: 0.3em
        padding-left: 0.2em
    .featured-title
        position: relative
        font-style: italic
        text-transform: uppercase
        color: #0b85ec
        text-align: center
        font-size: 1.85em
        padding-top: 2.25em
        margin-top: 0
        +respond-max-width(xs)
            font-size: 2.2em
            width: 60%
            margin: 0 auto 0 auto
            padding: 1.5em 0 0.5em 0
    &.feed
        .featured-title
            +respond-max-width(xs)
                font-size: 2.2em
                width: 100%
                margin: 0 auto
        .FeaturedGridWithSlider
            margin-top: 1em
    .container
        padding: 0

    .island
        cursor: pointer
        padding: 0.1em
        > div
            background: white
            padding: 0
            .caption
                padding: 0.75em
                h5
                    font-family: $font_4
                    font-size: 0.8em
                    letter-spacing: 0
                    color: #1bbaf4
                h3, a
                    margin-top: 0.5em
                    font-size: 1.375em
                    +respond-max-width(sm)
                        font-size: 1em
                    +respond-max-width(xs)
                        font-size: 1.375em
                    font-family: BurbankBigRegular-Black,sans-serif
                    text-transform: uppercase
                    color: black
    .slick-slider
        .island
            padding: 0.1em 0.3em 1em 0.1em
    .grid
        .island
            +respond-max-width(xs)
                padding: 0.25em 0 0 0
    &.featured
        .slick-slider
            padding: 1.2em 0 0.5em 1em
            .caption
                min-height: 6em
                text-align: center
        .grid
            +respond-min-width(sm)
                .caption
                    height: 6.5em
                    text-align: center
            +respond-max-width(xs)
                position: relative
                color: white
                .caption
                    padding: 0.25em
                    position: absolute
                    bottom: 0
                    left: 1em
                    color: white
                    a
                        color: white
                    h3
                        margin-top: 0
                    h5
                        margin-bottom: 0.15em
                        color: white
    .pad
        position: relative
        padding: 0 0.1em
        +respond-max-width(xs)
            padding: 0 1em
        max-width: 100%
        margin: 0 auto
    .learnmore
        display: flex
        flex-direction: row
        a
            font-family: $font_2
            display: flex
            justify-content: center
            line-height: 1.5
        +respond-max-width(xs)
            flex-direction: column
        h3
            display: flex
            justify-content: center
            line-height: 1.5
            text-align: center
    .bluebox
        font-style: italic
        margin-top: 0.25em
        text-align: center
        padding: 0.75em
        color: white
        background: rgb(13,204,255)
        background: linear-gradient(90deg, rgba(71,96,255,1) 0%, rgba(13,204,255,1) 100%)
        h1
            font-size: 2.3em
        .learnmore
            h3
                width: 100%
                a
                    color: white
    .purplebox
        margin: 6em 0
        +respond-max-width(xs)
            margin: 2em 1em
        color: white
        padding: 0.75em
        background: rgb(212,37,218)
        background: linear-gradient(90deg, rgba(212,37,218,1) 0%, rgba(92,29,167,1) 100%)
        text-align: center
        h1
            font-size: 2.3em
        .learnmore
            h3
                color: #00fffc
                width: 33.3%
                +respond-max-width(xs)
                    width: 100%
                    margin-top: 0
                a
                    color: #00fffc

    &.featured
        +respond-min-width(xs)
            .grid.size2, .grid.size1
                .row
                    display: flex
                    flex-direction: row
                    width: 100%
                    justify-content: center
                    .island
                        width: 100%
                        float: unset
            .grid.size1
                .island
                    max-width: 50%
    .learnmore
        h3
            a
                white-space: nowrap

body[data-safari="true"]
    .CreativeHomeGrid
        .learnmore
            .icon-reverse-right-arrow
                padding-top: 0.2em
