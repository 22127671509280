@import '../../../../styles/variables-mixins.sass'

#stw
    background: #000
    overflow: hidden
    .bk-img
        background-size: 100%
        +respond-max-width(xs)
            background-size: cover
            max-height: 65vh 
    .pack-dialog-container
        position: absolute