@font-face
  font-family: 'icomoon-react-blog'
  src:  url('./fonts/icomoon-react-blog.eot?24qm63')
  src:  url('./fonts/icomoon-react-blog.eot?24qm63#iefix') format('embedded-opentype'), url('./fonts/icomoon-react-blog.ttf?24qm63') format('truetype'), url('./fonts/icomoon-react-blog.woff?24qm63') format('woff'), url('./fonts/icomoon-react-blog.svg?24qm63#icomoon') format('svg')
  font-weight: normal
  font-style: normal

[class^="epic-blog-icon-"], [class*=" epic-blog-icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-react-blog' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.epic-blog-icon-filter:before
  content: "\e993"
.epic-blog-icon-danger:before
    content: "\e919"

.epic-blog-icon-close:before
    content: "\e90e"

.epic-blog-icon-warning:before
    content: "\ea07"

.epic-blog-icon-info:before
    content: "\ea08"

.epic-blog-icon-upArrow:before
    content: "\e902"

.epic-blog-icon-downArrow:before
    content: "\e903"

.epic-blog-icon-rightArrow:before
    content: "\e900"

.epic-blog-icon-leftArrow:before
    content: "\e901"

.epic-blog-icon-google-plus:before
    content: "\ea8b"

.epic-blog-icon-facebook:before
    content: "\ea90"

.epic-blog-icon-twitter:before
    content: "\ea96"

.epic-blog-icon-vk:before
    content: "\ea98"

.epic-blog-icon-share:before
  content: "\ea82"

.epic-blog-icon-linkedin:before
    content: "\eaca"

.epic-blog-icon-reddit:before
    content: "\eac6"

.epic-blog-icon-tumblr:before
    content: "\e904"

.epic-blog-icon-weibo:before
  content: "\ea9a"

.epic-blog-icon-wechat:before
  content: "\e905"

.epic-blog-icon-grid:before
  content: "\e90d"

