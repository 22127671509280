@import '~epic-fortnite-styles/variables-mixins.sass'

.creative-image-card
    position: relative
    .bkgrdImage
        position: absolute
        top: 0
        left: 0 
        width: 100%
        height: 100%
        background-repeat: no-repeat
        background-position: center
        background-size: cover
        &:before
            content: ''
            width: 100%
            height: 25em
            position: absolute
            bottom: 0 
            left: 0 
            right: 0 
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)
    .section
        height: 90vh
        min-height: 800px
        overflow: hidden
        +respond-max-width(sm)
            height: 60vh
            min-height: 375px
        .content
            position: absolute 
            bottom: 20%
            left: 0 
            right: 0
            max-width: 60em
            width: 100% 
            margin: 0 auto
            padding: 0 1em
            color: $text-color
            h1 
                font-size: 6em
                opacity: 0
                text-shadow: 1px 0 20px rgba(black, 0.3)
                +respond-max-width(sm)
                    font-size: 2.5em
            .description
                max-width: 40em
                width: 100%
                opacity: 0 
                margin: 1em auto
                +respond-max-width(sm)
                    max-width: 35em
                h4 
                    font-family: $font_1
                    text-transform: none
                    font-size: 1.2em

.de 
    .creative-image-card .section .content h1
        +respond-max-width(sm)
            max-width: 8em 
            width: 100% 
            margin: 0 auto

.ar
    .creative-image-card .section .content 
        .description h4
            font-size: 1.75em
            +respond-max-width(sm)
                font-size: 1.2em
