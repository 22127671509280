@import '../../../../styles/variables-mixins.sass'

.simpleView
    background-size: cover!important
    overflow: hidden
    min-height: 90vh
    font-size: 1.1em
    .content
        padding: 2em
        margin: 3em auto
        max-width: 940px
        img
            max-width: 100%
            height: auto !important
    &.verticalCenter
        display: flex
        width: 100%
        flex-direction: column
        justify-content: center
        .content
            width: 100%
    &.noTopMargin
        .content
            margin: 0 auto
    &.withBorder
        .content
            border: 1px solid #e8e8e8

.linkColorBlue
    a
        color: #1155cc
    a:hover
        color: #1155cc
    a:visited
        color: #1155cc
.linkColorBlack
    a
        color: black
    a:hover
        color: black
    a:visited
        color: black
.fortnite-wrapper
    .fontBurbankBigCondensedBlack
        font-family: $font_0
    .fontBurbankSmallBlack
        font-family: $font_1
    .fontBurbankBigRegularBlack
        font-family: $font_2
    .fontOpenSans
        font-family: $font_4
    .fontsansserif
        font-family: $font_5
    .fontOpenSans
        font-family: $font_8
    .fontKoaERIN
        font-family: $font_ko_art
    .fontKo
        font-family: $font_ko
    .fontjaHeader
        font-family: $font_jaHeader
    .fontjaBody
        font-family: $font_jaBody
    .fontArabic
        font-family: $font_ar
