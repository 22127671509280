@import '../../../../styles/variables-mixins.sass'

.mouse-position
    position: fixed
    width: 4em
    height: 4em
    border-radius: 50%
    background-color: transparent
    z-index: 5
    visibility: hidden
    opacity: 0
    transition: all 0.3s ease-out
    overflow: hidden
    text-align: center
    i
        display: inline-block
        vertical-align: middle
        margin-top: 0.55em
        color: $text-color
        font-size: 2em
    &.tier-1
        background-color: rgba(darken(#c9cad8, 20%), 0.9)
    &.tier-2
        background-color: rgba(darken(#1ac2fe, 20%), 0.9)
    &.tier-3
        background-color: rgba(darken(#b83df2, 20%), 0.9)
    &.tier-4
        background-color: rgba(darken(#eb9257, 20%), 0.9)

    &.showChaser
        opacity: 1
        visibility: visible

body[data-firefox="true"],
body[data-ios="true"],
body[data-safari="true"],
body[data-msie="true"]
    .mouse-position
        i
            margin-top: 0.44em
