/* variables.sass */
//== Colors
//
$background: #f6f7f9
$darkGray: #1e1e1e
$lightGray: #f8f8f8
$gold: #dbbd68
$white: #fff
$green: #526451
$orange: #d96e37
$red: #782c30
$blue: #506da2
$disabledBtnColor: #ccc

//old
$text-color: #ffffff
$invert-text-color: #000000
$dark-text-color: #797979
$nav-background-color: #1f1f1f
$nav-hover-background-color: #3C3C3C
$background-color: #000000
$inverted-background-color: #656565
$bodyBgColor: #121612
$bodyBorderColor: #656565
$bodyLightBorderColor: #b3b3b3
$accent-color: #dbbd68
$accent-color-hover: lighten($accent-color, 5%)
$accent-color-darken: darken($accent-color, 5%)
$footerLinks: #474236
$download-background-color: #dbbd68
$headline-color: #fff
$blog-bg: #fff
$blog-text: #000
$blog-btn-bg-color: #dcbe62
$siteBlockBg: #f8f8f8
$siteBlockBorder: #e8e8e8
$siteBlockText: #1e1e1e
$siteBlockTextSecondary: #bfbfbf
$siteBlockWidth: 800px
$siteBlockShadow: 0px 9px 27px 0px #666
$errorMessageText: #bc0a0d
$h2Color: #acab9c
$newStore: #526451
$saleStore: #782c30
$bundleStore: #506da2
$featuredStore: #d96e37

// Watch Page styles
$liveStream: #7a3035
$recordedStream: #8c9aad
$cardWidth: 21.3em
$cardHeight: 164px

//iframe video sizes
$iframeLgWidth: 960px
$iframeLgHeight: 540px
$iframeMdWidth: 640px
$iframeMdHeight: 360px
$iframeSmWidth: 480px
$iframeSmHeight: 270px
$iframeXsWidth: 100%
$iframeXsHeight: 360px

//ContainerSize 
$containerWidth: 120vh
$containerMinWidth: 1170px
//== URL's

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font_0: OpenSans, sans-serif
$font_1: OpenSans, sans-serif
$font_2: OpenSans, sans-serif
$font_4: OpenSans, sans-serif
$font_5: sans-serif
$font_6: Noto-Regular, sans-serif
$font_7: Noto-Bold, sans-serif

$font-size-base: 16px !default
$font-size-large: ceil($font-size-base * 1.25) !default
$font-size-small: ceil($font-size-base * 0.85) !default
// ~18px
$font-size-small: ceil($font-size-base * 0.85) !default

// ~12px

$font-size-h1: 29px
$font-size-h2: 24px
$font-size-h3: 20px
$font-size-h4: 16px
$font-size-h5: 14px
$font-size-h6: 12px

$font_0_letterspacing: 0.35em

//
//
//== Mixins ==*/
//
=phone-landscape
  @media only screen and (orientation: landscape) and (min-aspect-ratio: 1/1)
    @content

=ellipsis()
    white-space: pre
    text-overflow: ellipsis
    overflow: hidden

=vertical-align($position: relative)
    position: $position
    top: 50%
    transform: translateY(-50%)

=respond-to($media)
    @if $media == xs
        //select xs devices and up
        @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max)
            @content

    @if $media == xl
        //select lg devices and up
        @media only screen and (min-width: $screen-xl-min)
            @content

=respond-min-width($media)
    @if $media == xs
        //select xs devices and up
        @media only screen and (min-width: $screen-xs-min)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (min-width: $screen-sm-min)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (min-width: $screen-md-min)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (min-width: $screen-lg-min)
            @content

    @if $media == xl
        //select lg devices and up
        @media only screen and (min-width: $screen-xl-min)
            @content

=respond-max-width($media)
    @if $media == min
        //select xs devices and up
        @media only screen and (max-width: $screen-xs-min)
            @content

    @if $media == xs
        //select xs devices and up
        @media only screen and (max-width: $screen-xs-max)
            @content

    @if $media == sm
        //select sm devices and up
        @media only screen and (max-width: $screen-sm-max)
            @content

    @if $media == md
        //select md devices and up
        @media only screen and (max-width: $screen-md-max)
            @content

    @if $media == lg
        //select lg devices and up
        @media only screen and (max-width: $screen-lg-max)
            @content

=respond-handheld-min-width($media, $orientation)
    @if $orientation != null and $media == xs
        @media only screen and (min-width: $screen-xs-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == sm
        @media only screen and (min-width: $screen-sm-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == md
        @media only screen and (min-width: $screen-md-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == lg
        @media only screen and (min-width: $screen-lg-min) and (orientation: $orientation)
            @content

=respond-handheld-max-width($media, $orientation)
    @if $orientation != null and $media == xs
        @media only screen and (max-width: $screen-xs-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == sm
        @media only screen and (max-width: $screen-sm-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == md
        @media only screen and (max-width: $screen-md-min) and (orientation: $orientation)
            @content
    @if $orientation != null and $media == lg
        @media only screen and (max-width: $screen-lg-min) and (orientation: $orientation)
            @content

=keyframes($animation-name)
    @-webkit-keyframes #{$animation-name}
        @content

    @-moz-keyframes #{$animation-name}
        @content

    @-ms-keyframes #{$animation-name}
        @content

    @keyframes #{$animation-name}
        @content

=clearfix
    &:before, &:after
        content: " "
        display: table
    &:after
        clear: both

=gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%)
    background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent)
    background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent)
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent)
    background-repeat: repeat-x
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0)
//
//
//
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px !default
$screen-xs-min: $screen-xs !default
$screen-phone: $screen-xs-min !default

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default
$screen-sm-min: $screen-sm !default
$screen-tablet: $screen-sm-min !default

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default
$screen-md-min: $screen-md !default
$screen-desktop: $screen-md-min !default

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default
$screen-lg-min: $screen-lg !default
$screen-lg-desktop: $screen-lg-min !default

$screen-xl: 1400px !default
$screen-xl-min: $screen-xl !default
$screen-xl-desktop: $screen-xl-min !default

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: $screen-sm-min - 1 !default
$screen-sm-max: $screen-md-min - 1 !default
$screen-md-max: $screen-lg-min - 1 !default
$screen-lg-max: $screen-xl-min - 1 !default

=transition($prop, $ease, $time)
    -webkit-transition: $prop $ease $time
    -moz-transition: $prop $ease $time
    -ms-transition: $prop $ease $time
    -o-transition: $prop $ease $time
    transition: $prop $ease $time
