@import '../../../../styles/variables-mixins.sass'


body.ar
    .cs-skin-slide > span
        text-align: right

    .cs-select > span
        &:after
            right: auto!important
            left: 1em!important

    .skins .skin-list .cs-options li
        text-align: right
        padding-right: 40px
        &:before
            right: 0
            left: auto

/*  Based on http://tympanus.net/Development/SelectInspiration/index4.html */

div.cs-select
    display: inline-block
    vertical-align: middle
    position: relative
    text-align: left
    background: #fff
    z-index: 100
    width: 100%
    max-width: 500px
    user-select: none
    &:focus
        outline: none

.cs-select
    select
        display: none
    span
        display: block
        position: relative
        cursor: pointer
        padding: 1em
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    > span
        padding-right: 3em
        &::after
            speak: none
            position: absolute
            top: 50%
            transform: translateY(-50%)
            -webkit-font-smoothing: antialiased
            -moz-osx-font-smoothing: grayscale
    .cs-selected span::after
        speak: none
        position: absolute
        top: 50%
        transform: translateY(-50%)
        content: '\e913'
        margin-left: 1em
    > span::after
        content: '\e913'
        right: 1em
    &.cs-active > span::after
        transform: translateY(-50%) rotate(180deg)
    .cs-options
        position: absolute
        overflow: hidden
        width: 100%
        height: 100%
        background: #fff
        visibility: hidden
    &.cs-active .cs-options
        visibility: visible
    ul
        list-style: none
        margin: 0
        padding: 0
        width: 100%
        span
            padding: 1em
        li.cs-focus span
            background-color: #ddd
    li.cs-optgroup
        ul
            padding-left: 1em
        > span
            cursor: default

    .cs-select-underlay
        background: transparent
        visibility: hidden
        position: fixed
        display: none
        left: 0
        right: 0
        bottom: 0
        top: 0
        z-index: 0

        &.cs-select-underlay-active
            display: block
            visibility: visible
            z-index: -1


div.cs-skin-slide
    color: $invert-text-color
    font-size: 1.5em
    width: 300px
    &::before
        content: ''
        background: #fff
        color: $invert-text-color
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        backface-visibility: hidden
        transition: transform 0.3s

.cs-skin-slide
    &.cs-active
        &::before
            transform: scale3d(1.1, 1.1, 1)
            height: 280%
            background: transparent
        > span
            text-indent: -290px
            opacity: 0
        > span::after
            font-family: 'icomoon'
            content: '\e913'
            color: $accent-color
            backface-visibility: hidden
            transform: translate3d(0, -50%, 0)
    > span
        height: 80px
        line-height: 3.5em
        font-size: .6em
        font-family: $font_0
        +respond-max-width(xs)
            font-size: .6em
        text-transform: uppercase
        transition: text-indent 0.3s, opacity 0.3s
    > span::after
        font-family: 'icomoon'
        content: '\e913'
        color: $accent-color
        backface-visibility: hidden
    .cs-options
        background: #fff
        width: 100%
        height: auto
        padding: 1em
        top: 150%
        left: 50%
        transition: transform 0.3s
        transform: translate3d(-50%, -50%, 0)
        li
            opacity: 0
            transform: translate3d(30%, 0, 0)
            transition: transform 0.3s, opacity 0.3s
    &.cs-active .cs-options li
        -webkit-transform: translate3d(0, 0, 0)
        transform: translate3d(0, 0, 0)
        opacity: 1
        &:first-child
            -webkit-transition-delay: 0.05s
            transition-delay: 0.05s
        &:nth-child(2)
            -webkit-transition-delay: 0.1s
            transition-delay: 0.1s
        &:nth-child(3)
            -webkit-transition-delay: 0.15s
            transition-delay: 0.15s
        &:nth-child(4)
            -webkit-transition-delay: 0.2s
            transition-delay: 0.2s
        &:nth-child(5)
            -webkit-transition-delay: 0.25s
            transition-delay: 0.25s
        &:nth-child(6)
            -webkit-transition-delay: 0.30s
            transition-delay: 0.30s
        &:nth-child(7)
            -webkit-transition-delay: 0.35s
            transition-delay: 0.35s
        &:nth-child(8)
            -webkit-transition-delay: 0.40s
            transition-delay: 0.40s
        &:nth-child(9)
            -webkit-transition-delay: 0.45s
            transition-delay: 0.45s
        &:nth-child(10)
            -webkit-transition-delay: 0.5s
            transition-delay: 0.5s
    .cs-options li
        span
            font-family: $font_0
            text-transform: uppercase
            letter-spacing: 2px
            font-size: 0.6em
            padding: 0.6em 1em 0.8em 2.5em
            &:hover
                color: $accent-color
                background: transparent
        &.cs-focus span, &.cs-selected span
            color: $accent-color
            background: transparent
    .cs-selected span::after
        content: ''

+respond-max-width(md)
    .cs-skin-slide.cs-active::before
        height: 400%
        transform: scale3d(1, 1.1, 1)
    div.cs-skin-slide
        width: 180px
        .cs-options
            padding: 0.25em
            li
                line-height: 1.5em
                &:before
                    top: 0!important
                span
                    padding: 0.2em 1em 0.2em 2.5em!important
        > span
            height: 39px
            line-height: 1em
            font-size:  .6em!important


body.ar
    .skins .skin-list
        left: 0
        right: 70px
        +respond-max-width(md)
            right: 130px

