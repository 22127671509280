@import '~epic-fortnite-styles/mixins.sass'

.error404
    @include clearfix
    background: url('~epic-fortnite-react/images/error/404-bkgrd.jpg') center no-repeat
    background-size: cover !important
    text-align: center
    font-size: 16px
    overflow: hidden
    min-height: 90vh
    +respond-max-width(md)
        min-height: 85vh
    @include respond-max-width(sm)
        padding: 1em
    .content.content
        margin: 60px auto
        @media only screen and (min-width: $screen-xl-min)
            margin-bottom: 90px
        @include respond-max-width(md)
            margin-bottom: 30px
        h1
            font-family: BurbankBigCondensed-Black, sans-serif
            font-size: 40em
            letter-spacing: 0.05em
            color: #ffffff
            display: inline
            line-height: 1
            pointer-events: none
            @include respond-max-width(md)
                font-size: 400px
            @include respond-max-width(sm)
                font-size: 200px
            @include respond-max-width(xs)
                font-size: 150px
            @media only screen and (min-width: $screen-xl-min)
                font-size: 52em
        p
            font-family: BurbankBigCondensed-Black, sans-serif
            text-transform: uppercase
            font-size: 2em
            color: #ffffff
            line-height: 1.5
            +respond-max-width(xs)
                font-size: 1.5em
    .errorHeader
        display: block
        max-width: 66em
        position: relative
        margin: 0 auto 1em
        h1
            position: relative
            z-index: 2
            line-height: 0.85 !important
        .llama-1, .llama-2, .llama-3
            position: absolute
        .llama-1
            background: url('~epic-fortnite-react/images/error/llama1.png') center no-repeat
            background-size: contain !important
            width: 10em
            height: 20em
            left: -2em
            top: 0
        .llama-2
            background: url('~epic-fortnite-react/images/error/llama2.png') center no-repeat
            background-size: contain !important
            width: 10em
            height: 10em
            left: 26%
            top: 27%
        .llama-3
            background: url('~epic-fortnite-react/images/error/llama3.png') center no-repeat
            background-size: contain !important
            width: 10em
            height: 16em
            right: 0
            top: 0
        +respond-max-width(md)
            max-width: 45em
            .llama-1
                width: 7em
                height: 14em
                left: 0
            .llama-2
                display: none
            .llama-3
                width: 8em
                height: 12em
        +respond-max-width(sm)
            max-width: 22em
            .llama-1
                width: 3em
                height: 7em
            .llama-3
                width: 5em
                height: 6em
        +respond-max-width(xs)
            max-width: 18em
            .llama-1
                height: 5em
            .llama-3
                width: 4em
                height: 5em

        @media only screen and (min-width: $screen-xl-min)
            max-width: 78em
            .llama-1
                left: -2em
                top: 10%
            .llama-2
                width: 13em
                height: 13em
            .llama-3
                width: 10em
                height: 16em
                right: -3em
                top: -1em

    .errorBody
        margin: 4em auto
        +respond-max-width(sm)
            margin: 1em auto
    .box-shadow
        position: absolute
        bottom: 2em
        left: 0
        right: 0
        width: 50%
        height: 0.7em
        border-radius: 44%
        background: rgba(black, 0.1)
        margin: 0 auto
        box-shadow: 0 0 14px 3px rgba(black, 0.1)
    .btn.wobble-btn
        z-index: 5
        background-color: #ff0
        color: #000000
        &:before
            display: none
        +respond-max-width(xs)
            padding-left: 0 !important
            padding-right: 0 !important
    .btn-error
        border: none !important
body.ar
    .error404
        .errorBody
            text-align: center
