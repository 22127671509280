@import '~epic-fortnite-styles/mixins.sass'

.errorPages
    .btn-error.btn-error
        margin-top: 2em
        padding-left: 3em
        padding-right: 3em
        a
            font-family: BurbankBigCondensed-Black, sans-serif
            color: #ffffff
            &:hover
                color: #ffffff
        @include respond-max-width(sm)
            width: 100%
