@import '../../../../styles/variables-mixins.sass'

.patch-notes-header
    position: relative
    padding: 0 0 4em
    .background-image
        width: 100%
        padding-bottom: 36%
        background-size: cover !important
        margin-top: -2.5em
        +respond-max-width(sm)
            background-position: center !important
            padding-bottom: 50%
            margin-top: -3em
        +respond-max-width(xs)
            padding-bottom: 71%
