@import '~epic-fortnite-styles/variables-mixins.sass'
.fortnite-wrapper
    .creative-header.creative-header.header-container
        div.header-panel
            bottom: -1px
        #mainCarousel
            .caption
                top: 50%
                +respond-min-width(lg)
                    top: 45%
                +respond-max-width(sm)
                    top: 0
                    max-width: 450px
                h1, h3, img, button
                    opacity: 0
                img
                    width: 100px
                    margin-bottom: 20px
                    +respond-max-width(sm)
                        width: 60px
                        margin-left: auto
                        margin-right: auto
                        filter: drop-shadow(0 0 5px rgba(black, 0.5))
                button, a
                    transition: 0.5s ease-in-out
                h1
                    font-size: 5vw
                    line-height: 0.8
                    margin: 0
                    +respond-max-width(sm)
                        font-size: 60px
                h3
                    font-size: 2vw
                    line-height: 2
                    margin: 0
                    transition: 0.5s ease-in-out
                    color: yellow
                    +respond-max-width(sm)
                        font-size: 21px
                &.right
                    right: 100px
                    text-align: left
                    max-width: 45em
                &.left
                    left: 100px
                &.leftv2
                    left: 100px
                &.center, &.topCenter
                    text-align: center
                    left: 0
                    right: 0
                    margin-left: auto
                    margin-right: auto
                    img
                        margin-left: auto
                        margin-right: auto
                &.topCenter
                    top: 25%

.creative-header.creative-header.creative-header
    #mainCarousel
        margin-bottom: -1px
        .slide
            min-height: 400px
            height: 65vh
            +respond-max-width(2k)
                height: 57vh
            +respond-max-width(md)
                min-height: 550px
                height: 550px
            +respond-max-width(sm)
                justify-content: flex-start
                max-height: 1024px
                height: 70vh
            +respond-max-width(xs)
                max-height: 800px
                height: 50vh
            .caption
                max-width: 100%
                h1
                    font-size: 5em
                    +respond-max-width(md)
                        font-size: 3em
                    +respond-max-width(sm)
                        font-size: 2.5em
                    text-shadow: 1px 0 20px rgba(0,0,0,.3)
                h3
                    font-size: 1em
                    padding-bottom: 1em
                    +respond-max-width(sm)
                        font-size: 0.9em
                    max-width: 45em
                    margin: 0 auto
                h1, h3
                    opacity: 1
                button
                    opacity: 1
                +respond-max-width(xs)
                    position: absolute
                    h1
                        font-size: 1.5em
                    h1, h3
                        text-align: center
            .bk-img
                background-size: cover
                background-position: center
        .caption.left
            text-align: left
            max-width: 45em
            h1
                line-height: 1
                text-align: left
            h3
                text-align: left
                max-width: 34em
                margin: 0
                line-height: 1.3
            +respond-max-width(md)
                text-align: center
                width: 28em
            +respond-max-width(xs)
                position: absolute
                margin-top: 15%
                text-align: center
                margin-left: auto
                margin-right: auto
                h1
                    font-size: 1.3em
                h1, h3
                    text-align: center
                h3
                    font-size: 0.8em
                    margin: .5em auto
    &.header-container
        position: relative
        .header-panel
            display: flex
            flex-direction: row
            position: absolute
            right: 0
            bottom: -1px
            div
                background: black
            +respond-max-width(sm)
                width: 90%
            @media only screen and (min-width: 1600px)
                bottom: -30px
        .twitter
            margin-top: 0.25em
            background-image: url('../../../../images/creativeMode/twitter.svg')
        .header-icon
            +respond-max-width(sm)
                display: none
            padding: 1em 0 0 0
            text-align: center
            button, span
                display: inline
                margin: 0 1em 0 0
                width: 30px
                height: 30px
                border: none
                background-size: contain
                background-repeat: no-repeat
                background-position: center
            a
                span
                    display: inline-block
        .header-clip
            margin-right: -1px
            margin-left: auto
            width: 30px
            clip-path: polygon(54% 0, 100% 0, 100% 100%, 0% 100%)
            +respond-max-width(sm)
                width: 10%
        .header-text
            padding: 1em 0.5em 0 0
            .socialbutton
                button, span
                    display: inline-block
                    margin: 0 3px 0 0
                    width: 10px
                    height: 10px
                    border: none
                    background-size: contain
                    background-repeat: no-repeat
                    background-position: center
                a
                    span
                        display: inline-block
                +respond-min-width(md)
                    display: none

            .text, .text:hover, .text:visited
                font-weight: unset
                line-height: 70%
                font-size: 0.7em
                +respond-max-width(sm)
                    line-height: 1.1
                    font-size: 1.1em
                    display: block
                    text-overflow: ellipsis
                    word-wrap: break-word
                    overflow: hidden
                    max-height: 3.2em
                +respond-min-width(2k)
                    line-height: 1
                    font-size: 1.2em
            a
                line-height: 1

            +respond-max-width(xs)
                padding: 1em 0.3em 0 0
            font-family: $font_4
            max-width: 300px
            color: #fff478
            a, a:hover, a:visited
                font-weight: bold
                color: #fff478
            +respond-max-width(sm)
                width: 40%
                font-size: 0.7em
        .preview
            width: 250px
            position: relative
            img
                width: 100%
            > div
                display: block
                position: absolute
                max-height: 112px
                overflow: hidden
                margin-bottom: 30px
                bottom: 0
                width: 200px
                border-radius: 5px
            +respond-max-width(sm)
                width: 50%
                max-width: 200px
                > div
                    right: 0
                    width: 100%
                    max-width: 175px
                    max-height: 99px
                    margin-bottom: 20px
            +respond-max-width(xs)
                > div
                    border-radius: 5px 0 0 5px
                    margin-bottom: 10px
            +respond-min-width(2k)
                width: 350px
                > div
                    border-radius: 10px
                    width: 300px
                    max-height: 168px

body.ru, body.de
    .creative-header.header-container
        #mainCarousel
            .slide
                .caption
                    h1
                        font-size: 4em
                    +respond-max-width(xs)
                        h1
                            font-size: 1.5em

body.es_ES
    .creative-header.header-container
        #mainCarousel
            .slide
                .caption
                    h1
                        font-size: 3em
                    +respond-max-width(xs)
                        h1
                            font-size: 1.5em

body.tr
    .creative-header.header-container
        #mainCarousel
            .slide
                .caption
                    max-width: 50em
                    h1
                        font-size: 4em
                        +respond-max-width(sm)
                            font-size: 2.3em
body.ru
    .creative-header.header-container
        .header-panel
            @media only screen and (min-width: 991px)
                bottom: -1px
            @media only screen and (min-width: 1600px)
                bottom: -30px

body.ru
    .creative-view
        .header-container
            .header-panel
                @media only screen and (min-width: 991px)
                    bottom: -1px
                @media only screen and (min-width: 1600px)
                    bottom: -30px

body.ru, body.de
    .creative-view
        #mainCarousel
            .slide
                .caption
                    h1
                        font-size: 4em

body.ja, body.ko
    .creative-view
        #mainCarousel
            .slide
                .caption.left
                    max-width: 30em
                    h1
                        line-height: 1.1

body.es_ES
    .creative-view
        #mainCarousel
            .slide
                .caption.left
                    max-width: 35em

body.es_ES, body.es_MX
    .creative-view
        #mainCarousel
            .slide
                .caption.left
                    h1
                        font-size: 3em
                        +respond-max-width(xs)
                            font-size: 1.5em

body.pl, body.pt_BR
    .creative-view
        #mainCarousel
            .slide
                .caption.left
                    h1
                        font-size: 4em
                        +respond-max-width(xs)
                            font-size: 1.3em

body.it
    .creative-view
        #mainCarousel
            .slide
                .caption.left
                    h1
                        +respond-max-width(xs)
                            font-size: 1.3em
