@import '../../../styles/variables-mixins.sass'

// ********** social sprite Styling *********** //
=stitches-social-sprite($x: 0, $y: 0, $width: 0, $height: 0)
    background-position: $x $y
    width: $width
    height: $height
body.ar
    .blog-container
        h1,
        h2,
        h3,
        h4,
        h5,
        h6
            font-family: $font_7
        p,
        span
            font-family: $font_6
        .blog-article
            .blog-social
              margin-left: 0
              margin-right: -6em
              +respond-max-width(sm)
                margin-right: 0
            h1,
            .author
                text-align: right
            .cms
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                span,
                img,
                li
                    text-align: right
                li
                    direction: rtl

            .footer
                clear: both

.blog-container
    overflow-x: hidden
    .blog-translate-message
        width: 100%
        background-color: #e5a300
        color: #fff
        padding: 5px
        font-size: 16px
    .blog-header
        width: 100%
        background-size: cover
        background-repeat: no-repeat
        height: 400px
        position: relative
        overflow: hidden
        +respond-min-width(lg)
            height: 60vh
            min-height: 600px
        img.invisible-image
            width: 100%
            opacity: 0
            position: absolute
            pointer-events: none
        +respond-max-width(xs)
            &
                height: 300px
                background-position: top
    .fallback
        display: none !important
    .blog-article
        padding-top: 4em
        +respond-max-width(xs)
            padding-top: 2em
            font-size: 0.9em
        h1
            font-family: $font_2
            font-size: 40px
            text-transform: uppercase
            color: $blog-text
        .blog-content
            transform: none
            color: $blog-text
            max-width: 840px
            margin: 0 auto 50px
            min-height: 50vh
            .blog-social
              position: absolute
              top: 11.5em
              margin-left: -6em
              z-index: 998
              opacity: 1
              +respond-max-width(sm)
                margin-left: 0
                bottom: -1px
                top: auto
                background: $background
                border-top: 1px solid rgba($background-color, 0.1)
                left: 0
                right: 0
                transform: translateY(0)
                text-align: center
                position: fixed
                padding: 0.5em 0
                transition: transform 0.3s ease-in-out
              &.top
                top: 15.2em
                opacity: 1
                +respond-max-width(sm)
                  top: auto
              &.bottom
                top: auto
                bottom: 0
                +respond-max-width(sm)
                  transform: translateY(100%)
              &.fixed
                position: fixed
                +respond-max-width(sm)
                  bottom: -1px

              .social-count
                text-align: center
                font-size: 0.85em
                font-weight: bold
                vertical-align: middle
                +respond-max-width(sm)
                  display: inline-block
                  margin-right: 0.25em
                  margin-top: 0.75em
                .count
                  display: block
                  font-weight: bold
                  +respond-max-width(sm)
                    display: inline-block
                    margin-right: 0.25em
                  span
                    padding-right: 0.5em
                  .social-count-subtext
                    font-weight: normal
              i
                color: white
                display: block
                width: 2em
                height: 2em
                border-radius: 50%
                opacity: 1
                padding: 0.5em
                cursor: pointer
                margin: 0.5em auto
                backface-visibility: hidden
                transition: opacity 0.3s ease-in-out
                +respond-max-width(sm)
                  display: inline-block
                  margin: 0.5em

                &.facebook
                  background-color: #236293
                &.twitter
                  background-color: #38ace0
                &.google-plus
                  background-color: #d6492b
                &.vk
                  background-color: #456890
                &.reddit
                  background-color: rgb(255, 133, 88)
                &.tumblr
                    background-color: #36465d
                &.linkedin
                    background-color: #0077b5
                &.weibo
                  background-color: #f7671d
                &.wechat
                  background-color: #10cf1f
                &:hover
                  opacity: 0.8
              .wechat-share
                position: relative
                display: inline
                .qrcode-block
                  background-color: $text-color
                  display: none
                  position: absolute
                  bottom: 0
                  left: 3em
                  canvas
                    padding: 6px 6px 0
                &:hover
                  .qrcode-block
                    display: block
            .cmsContainer
              position: relative
              .stream-wrapper
                  padding-top: 56.25%
                  position: relative
                  iframe
                      border: none
                      width: 100%
                      height: 100%
                      position: absolute
                      top: 0
                      left: 0
              .tik-tok-container
                  display: flex
                  justify-content: space-around
                  max-width: 100%
                  flex-wrap: wrap
                  video
                    +respond-max-width(xs)
                      margin: 0 auto


            .blog-header-info
              hr
                border-color: #e2e3e4
                opacity: 1
                margin: 0.5em 0
              margin-bottom: 0.75em
              .category-section
                margin: 0
                .category
                  color: $blog-text
                  font-family: 'Brutal_Medium'
                  display: inline-block
                  text-transform: capitalize
                  font-size: 0.875em
                  padding-right: 0
                  margin: 0 0 0 0
                  transition: color 0.3s ease-in-out
                  &:hover
                    color: $accent-color
                a:not(:last-child)
                  .category
                    margin: 0 0.5em 0 0
                    padding-right: 0.5em
                    border-right: 1px solid $blog-text
              .blog-header-title
                font-family: $font_0
                font-size: 2.8em
                line-height: 1
                text-transform: uppercase
                margin: 0 0 0.25em 0
                +respond-max-width(xs)
                  font-size: 2em
              .blog-header-subtitle
                font-family: $font_1
                padding-right: 0.5em
                margin-right: 0.5em
                font-size: 1em
                border-right: 1px solid $blog-text
              .blog-header-date
                font-family: $font_1
                font-size: 1em
              .blog-header-author
                font-family: $font_1
                font-size: 1em
                margin: 1em 0  1.25em 0
            p
                word-wrap: break-word
            .cms > p
                    color: $blog-text
                    *
                        color: $blog-text
                    a
                        line-height: 1em
                        color: $accent-color
                        &:hover, &:focus, &:active
                            text-decoration: underline
            article
                clear: both
                .author
                    .post-author
                        font-style: italic
                strong
                    font-family: $font_4
        +respond-max-width(md)
            margin-top: 0
            h1
                font-size: 48px
        +respond-max-width(sm)
            h1.post-title
                margin-top: 100px
            .content
                padding: 0 20px
                .socialLink
                    display: block
                    margin-top: 10px
                .cms
                    p:first-child
                        margin-top: 10px

        .cms
            padding-top: 0.5em
            h1
              font-size: 3.25em
            h2
              font-size: 1.875em
            h3
              font-size: 1em
            img
              max-width: 100%
              height: auto !important
              margin: 2em 0
            .embed-responsive
              margin: 2em 0
            p
                margin: 1em 0
                line-height: 2
                font-size: 1em

            ul,
            ol
                padding-left: 20px
            li
                list-style-type: inherit
                line-height: 2
                font-size: 1em
                ul
                    padding-left: 40px
            .cms-quote
                text-transform: uppercase
                float: right
                width: 40%
                padding: 0 0 5% 5%
                margin: 0
                color: #236394
                font-size: 2em
                clear: both
                &:before
                    content: '"'
                &:after
                    content: '"'
                +respond-max-width(md)
                    font-size: 1.4em
                    padding: 0 0 5% 5%
                +respond-max-width(sm)
                    font-size: 1em
        .recentPostContainer
          margin: 5em auto 0
          .recent-title
            text-align: center
            text-transform: uppercase
            font-size: 1.75em
          .content-toss-item
            border: none
            +respond-max-width(sm)
              .image
                height: 200px
            .byline, .short
              display: none

    // ********** Table Styling for Blogs *********** //
    table
        width: 100%
        border: 1px solid #3d3c3c
        tr:first-child
            text-align: center
            &:first-child
                background-color: #dbbd68
                color: #000
        td
            text-align: center
            p
                margin: 0 !important

.blog-container.no-image
    overflow: hidden
    background-position-y: 0
    .blog-header
        height: auto
        .blog-header-info
            text-shadow: none
            color: $blog-text


// ********************** Style for Comment Area ************************ //
.comment-area
    h1, h2, h3, h4, h5
        margin: 0
    a
        text-decoration: none
    ul
        margin: 0
        padding: 0

// **************** _varialbe.sass **************** //

/* Plugin Defaults */
$comment-font-size: 16px

/* Colors */
$comment-primary-color: #1e1e1e
$comment-primary-hover: lighten($comment-primary-color, 15)
$comment-blog-text: #1e1e1e
$comment-back: #1e1e1e
$comment-off-black: #1e1e1e
$comment-white: #ffffff
$comment-grey: #1e1e1e
$comment-transparent-grey: rgba(0,0,0,0.8)
$comment-modal-title: #1e1e1e
$comment-modal-close: #1e1e1e
$comment-date: #1e1e1e
$comment-vote: #1e1e1e


/* screen size */
$small-screen-up: 601px !default
$medium-screen-up: 993px !default
$large-screen-up: 1201px !default
$small-screen: 600px !default
$small-x-screen: 400px !default
$medium-screen: 992px !default
$medium-x-screen: 1120px !default
$large-screen: 1200px !default



// **************** _mixins.sass **************** //
=calc($property, $expression)
    #{$property}: -moz-calc(#{$expression})
    #{$property}: -webkit-calc(#{$expression})
    #{$property}: calc(#{$expression})

=commentBox
    width: 100%
    height: 140px !important
    max-height: 200px
    border: 1px solid $bodyBorderColor
    padding: 20px
    font-size: 14px
    outline: none
    overflow: hidden!important
    overflow-y: auto!important
    &:focus
        box-shadow: 0 0px 5px $bodyBorderColor

=modalHeader
    .cw-close, .cw-title
        display: inline-block
        line-height: 25px
        text-align: center
    .cw-title
        background-color: $comment-modal-title
        color: $comment-white
        font-size: 12px
        position: relative
        text-transform: uppercase
        top: -1px
        width: 100%
    .cw-close
        position: absolute
        top: 0
        right: 0
        height: 24px
        background-color: $comment-modal-close
        cursor: pointer
        width: 40px
    > p
        text-align: center

.comment-add-reply, .comment-edit-box
    .textile-head
        z-index: 10
        top: 0
        position: relative
        line-height: normal
        li
            background-color: $lightGray
            top: 0
            cursor: pointer
            display: inline-block
            height: 28px
            line-height: 28px
            margin: 0 5px 0 0
            padding: 0 10px 30px
            position: relative
            text-align: center
            width: auto
            border-top: 0
            border-bottom-left-radius: 5px
            border-bottom-right-radius: 5px
            &.selected
                background: transparent
                border: 1px solid $bodyBorderColor
                border-top: none
                z-index: 10
    .textile-body
        position: relative
        top: 0
        height: 140px
        left: 0
        z-index: 1
        margin-bottom: 0
        .textile-edit, .textile-preview, .textile-help
            width: 100%
            display: none
            &.selected
                display: inline-block
            textarea
                resize: none
                max-width: 1000px
                max-height: 1000px!important
                background: transparent
                +commentBox
        .textile-preview
            max-width: 1000px
            text-align: initial
            +commentBox
    .comment-chars-min, .comment-chars-max
        color: $comment-primary-color
        display: none
        font-size: 12px
        &.comment-edit-invalid
            color: $red
            display: block !important

// **************** addComment.sass **************** //

.blog-container .add-comment
    margin-top: 40px
    .textile-head
        z-index: 10
        top: 0
        position: relative
        line-height: 1em
        li
            background-color: transparent
            cursor: pointer
            display: inline-block
            height: 28px
            line-height: 28px
            margin: 0 5px 0 0
            padding: 0 10px 30px
            position: relative
            text-align: center
            width: auto
            border-top: 0
            position: relative
            top: -1px
            &.selected
                background: transparent
                border: 1px solid $bodyBorderColor
                border-top: none
                color: $blog-text
                z-index: 10
    .textile-body
        position: relative
        top: 0
        height: 140px
        left: 0
        z-index: 1
        margin-bottom: 0
        .textile-edit, .textile-preview, .textile-help
            width: 100%
            display: none
            &.selected
                display: inline-block
            textarea
                resize: none
                max-width: 1000px
                max-height: 1000px!important
                background: transparent
                color: $blog-text!important

                +commentBox
        .textile-preview
            max-width: 1000px
            color: darken($blog-text, 30)
            text-align: start
            +commentBox

// **************** commentList.sass **************** //
body.ar
    #react-blog-comment
        textarea
            text-align: right
        .sort-box
            padding-bottom: 2em
        .comment-item
            .comment-body
                text-align: right
            .comment-vote,
            .comment-upvote,
            .comment-downvote
                float: right
        .textile-body
            text-align: right
        .comment-count
            direction: rtl
        .comment-body
            direction: rtl
        .sort-box a.active
            &::before
                left: 0
    .ReactModalPortal
        textarea
            text-align: right
.blog-container
    .comment-item
        margin-bottom: 2em
        .comment-vote
            display: inline-block
            width: 40px
            vertical-align: top
            & > a, & > span
                display: block
                text-align: center
                width: 100%
            .comment-count
                color: $comment-primary-color
            .comment-upvote, .comment-downvote
                color: lighten($comment-primary-color, 50)
                cursor: pointer
                &:hover
                    color: darken($comment-primary-color, 15)
                    text-decoration: none
                &.voted
                    cursor: not-allowed
                    color: darken($comment-primary-color, 15)
                i
                    font-style: normal
            .comment-upvote
                i:before
                    display: block
                    content: '▾'
                    font-size: 1.5em
                    transform: scale(-1)
            .comment-downvote
                i:before
                    content: '▾'
                    font-size: 1.5em
        .comment-info-box
            display: inline-block
            width: calc(100% - 50px)
        .comment-body
            margin-bottom: 1.5em
            margin-left: 1em
            .username
                margin-bottom: 1em
            .comment-content
                    margin: 0
            .textile-container-view
                    color: $blog-text
            .comment-links
                font-size: 14px
                margin-top: 10px
                & > a
                    cursor: pointer
                .comment-date
                    color: $comment-date
                & > a, & > span
                    color: $comment-primary-color
                    margin-right: 1em
                    position: relative
                    +respond-max-width(xs)
                        margin-right: 0.7em
                    &:hover
                        .comment-tooltip
                            display: block
                    .comment-tooltip
                        background: $comment-back
                        border-radius: 4px
                        color: $comment-white
                        display: none
                        font-size: 12px
                        left: -20px
                        line-height: 1.2
                        margin: 0
                        min-width: 60px
                        padding: 5px 10px
                        position: absolute
                        text-align: center
                        top: 35px
                        z-index: 99
                        word-break: normal
                        &:before
                            content: ''
                            display: block
                            width: 0
                            height: 0
                            position: absolute
                            border-width: 8px
                            border-style: solid
                            border-top-color: transparent
                            border-left-color: transparent
                            border-right-color: transparent
                            border-bottom-color: #000
                            left: 20px
                            top: -16px
        .reply-box
            margin-top: 1em
            +respond-max-width(xs)
                    padding-left: 0
            .comment-vote
                min-height: 50px
        .view-more
            color: $comment-primary-color
            cursor: pointer
            margin-left: 1em
            &:hover
                color: $comment-primary-hover
        .comment-add-reply
            margin-top: 10px
            margin-left: 15%
            .textile-head
                li.selected
                    border-top: 1px solid #181818
                    color: $blog-text
            .textile-body
                .textile-edit, .textile-preview, .textile-help
                    textarea
                        color: $blog-text
                .textile-preview
                    color: darken($blog-text, 30)


    // **************** comment.sass **************** //
    .comment-rating-box
        color: $comment-off-black
        margin-top: 50px
        width: 100%
        h1.title
            border-bottom: 1px solid $comment-grey
            margin-bottom: 10px
            line-height: 200%
            font-family: $font_1
            font-size: 24px
            padding-bottom: 10px
        .sort-box
            text-align: right
            color: $blog-text
            span
                font-family: $font_0
                font-size: 14px
                text-transform: uppercase

            div > span:first-child:after
                content: ': '
            a
                color: $comment-primary-color
                margin-left: 10px
                cursor: pointer
                &:hover
                    color: $comment-primary-hover
                &.active
                    cursor: default
                    position: relative
                    &:before
                            content: ''
                            position: absolute
                            width: 100%
                            height: 100%
                            border-bottom: 4px solid lighten($comment-primary-color, 50)
                            top: 5px
                            left: 0
            +respond-max-width(sm)
                text-align: left
                padding-bottom: 1em
                span
                    font-size: 12px
        .loading-svg
            background: url("../../../images/loading-bars.svg")
            background-size: contain
            height: 80px
            margin: 20px auto
            width: 80px
        .pagination
            padding: 10px 2em
            text-align: center
            display: block
            .comment-load-more
                text-transform: uppercase
                font-size: 14px
            a
                color: $comment-grey
                float: right
                width: 100px
                &:hover
                    color: $comment-primary-color
        .text-area-box
            text-align: center
            .alert-moderation
                max-width: 1000px
                text-align: left
                margin: 0 auto
                margin-bottom: 1em
                font-size: 0.8em
            .not-login
                position: relative
                //transition: all 0.5s ease-in-out
                cursor: pointer
                text-align: center
                display: inline-block
                margin: 0 auto
                padding: 1em
                &:hover
                    background: $accent-color-hover
                span
                    color: #000!important
                p
                    margin: 0
    .comment-chars-min, .comment-chars-max
        color: $comment-primary-color
        display: none
        font-size: 12px
        position: relative
        top: 10px
        margin-bottom: 2em
        &.comment-edit-invalid
            color: $red
            display: block !important

.ReactModalPortal
    position: relative
    z-index: 99999

    .ReactModal__Content
        position: absolute !important
        transform: translateY(-50%)
        background-color: #181818
        margin: 0 auto !important
        top: 50% !important
        right: 0 !important
        left: 0 !important
        bottom: auto !important
        max-width: 90%
        max-height: 98%
    .report-modal
        +modalHeader
        textarea
            border: 1px solid $comment-grey
            font-size: 14px
            margin: 20px
            max-height: 150px
            padding: 20px
            outline: none
            overflow: scroll
            resize: none
            width: calc(100% - 40px)
        a.btn, button.btn
            display: block
            width: 150px
            +respond-max-width(xs)
              line-height: 1
              padding: 1em
    .btn
        margin: 0 auto
        color: #000
    .edit-modal, .reply-edit-modal
        +modalHeader
        .delete-info
            text-align: center
        .comment-edit-box
            margin: 20px auto 0
            width: 95%
            .textile-container
                ul
                    padding-left: 0
            .btn
                margin-top: 20px
                display: block
                +respond-max-width(xs)
                  line-height: 1
                  padding: 1em
    .delete-modal
        text-align: center
        +modalHeader
    .login-modal
        +modalHeader

    .comment-chars-min, .comment-chars-max
      color: $comment-primary-color
      display: none
      font-size: 12px
      text-align: center
      &.comment-edit-invalid
        color: $red
        display: block !important

// ********** Comment Preview ******** //
.blog-container, .ReactModalPortal
    .textile-preview
        em
            font-style: italic
        strong
            font-weight: 700
        ul
            margin-left: 1.5em
            margin-top: 0
            margin-bottom: 11px
            li
                list-style: disc
        ol
            margin-top: 0
            margin-bottom: 11px
            -webkit-padding-start: 40px
            li
                list-style: decimal
        a
            color: inherit

// ********** Hero Race Sass ******** //
#table-race
    margin: 1em 0
    font-size: 0.85em
    text-align: center
    +respond-max-width(sm)
        text-align: left
    .header
        text-transform: uppercase
        color: #acab9c
        padding: 1em 0
        +respond-max-width(sm)
            display: none
    .light,
    .dark
        padding: 1.5em 0
        +respond-max-width(sm)
            padding: 2em 0
        .pos
            position: relative
            display: block
            font-weight: bold
            z-index: 1
            &.first,
            &.second,
            &.third
                &:after
                    content: ''
                    position: absolute
                    top: -2px
                    left: 0
                    right: 0
                    display: block
                    width: 25px
                    height: 25px
                    margin: 0 auto
                    transform: rotate(45deg)
                    z-index: -1
                    +respond-max-width(sm)
                        top: 0.85em
                        left: 2em
                    +respond-max-width(xs)
                        top: 7px
                        left: 24px
            &.first
                &:after
                    background: $accent-color
            &.second
                &:after
                    background: #908b85
            &.third
                &:after
                    background: #a66e43
        .pos,
        .usr,
        .level,
        .stamp
            +respond-max-width(sm)
                padding: 1em 0
                padding-left: 1em
                &:before
                    display: inline-block
                    width: 50%
                    color: #acab9c
                    text-transform: uppercase
                    font-weight: 400
        .pos
            +respond-max-width(sm)
                &:before
                    content: 'Position'
        .usr
            +respond-max-width(sm)
                &:before
                    content: 'Participant'
        .level
            +respond-max-width(sm)
                &:before
                    content: 'Hero Level'
        .stamp
            +respond-max-width(sm)
                &:before
                    content: 'Time Stamp'
    .light
        background: #505050
    .dark
        &:first-child
            background: #908b85

// ********** Caption Styling *********** //
.cms
    .caption
        margin: 0 0 2em!important
        color: darken($blog-text, 40%)
        font-size: 0.75em
        background: #000
        padding: 1em

footer
  clear: both

.hidden-scroll
    overflow: hidden


// ********** Lightbox Styling for Blogs *********** //
.blog-container
    .lightbox-enabled
        img
            cursor: pointer
            border: 1px solid transparent
            transition: border 0.3s ease-in-out
            &:hover
                border: 1px solid black
                transition: border 0.3s ease-in-out

    .lightbox
        position: fixed
        width: 100%
        height: 100%
        background-color: rgba(0,0,0,0.9)
        top: 0
        left: 0
        z-index: 100000
        padding: 50px
        text-align: center
        &:before
            display: inline-block
            height: 100%
            content: ''
            vertical-align: middle
        img
            cursor: default
            max-width: 100%
            max-height: 100%
        button
            background-color: transparent
            border: 0
            outline: none
            &:focus
                outline: none
            color: #c5c5c5
            position: absolute
            top: calc(50% - 1.8em)
            padding: 15px 18px
            font-size: 1.8em
            &:hover
                color: #ddd
            &.prev
                left: 0
            &.next
                right: 0
            &.close
                right: 5px
                top: 5px
                opacity: 1
