@import '../../../../styles/variables-mixins.sass'
.terms-view-container
    background-color: #3c3c3c
    .site-block
        max-width: 932px
        margin: 0 auto
        background-color: $white
        border: 0
        padding: 40px 20px
        &:before
            display: block
            content: ''
            border-bottom: 1px solid #d6d6d6
    .terms-text
        margin: 42px 23px 0 42px
        +respond-max-width(xs)
            margin: 20px 0
