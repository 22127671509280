@import '../../../../styles/variables-mixins.sass'

.simple-tab-header-container
    display: flex
    justify-content: space-evenly
    background-color: black
    color: white
    +respond-max-width(xs)
        flex-wrap: wrap
    .tab-item-container
        +respond-max-width(xs)
            flex-basis: 50%
            text-align: center
        .tab-item
            color: white
            cursor: pointer
            font-size: 1.5em
            border-bottom: 4px solid transparent
            position: relative
            font-weight: 500
            line-height: 85%
            font-family: BurbankBigRegular-Black, sans-serif
            text-transform: uppercase
            margin-block-start: 0.83em
            margin-block-end: 0.83em
            margin-inline-start: 0px
            margin-inline-end: 0px
            margin-top: 20px
            margin-bottom: 10px
            display: block
            +respond-max-width(md)
                font-size: 1em
                margin-top: 15px
                margin-bottom: 5px
            &:hover
                .tab-item-border-container
                    .tab-item-border
                        width: 100%
            .tab-item-border-container
                position: absolute
                height: 4px
                width: 100%
                background-color: transparent
                .tab-item-border
                    position: absolute
                    width: 0%
                    height: 100%
                    transition: 0.2s ease-in-out all
        .tab-item-active
            color: #00fffc
            transition: 0.2s ease-in-out
            transition-property: color, border-bottom

.ja, .pl
    .esports-wrapper
        .tab-item
            font-size: 1.5em
            +respond-max-width(xs)
                font-size: 0.8em

.ru
    .creative-view
        .tab-item
            font-size: 1.5em
            +respond-max-width(sm)
                font-size: 0.6em
