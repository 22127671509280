@import '../../../../styles/variables-mixins.sass'

.patch-notes-overview
    max-width: 1400px
    margin: 0 auto
    padding: 0 2em
    +respond-max-width(sm)
        padding: 0 1em
    .subSection
        margin-top: 2em
    h1
        font-size: 4em
        line-height: 1
        margin: 0 0 0.5em
        &.Dark
            color: $darkText
        &.Blue
            color: $lightBlue
        &.Yellow
            color: $yellow
        &.Red
            color: $magenta
        &.Pink
            color: $pink
        &.Purple
            color: $purple
        &.Orange
            color: $orange
        +respond-max-width(xs)
            font-size: 3em
    h2
        font-size: 3em
        margin: 1em 0 0.5em
    p
        margin-bottom: 0
    ul
        padding: 0 0 0 1.4em
        margin: 0
        list-style-type: disc
        font-size: 1em
        ul
            list-style-type: circle
            ul
                list-style-type: square
        li
            margin-top: 0.5em
            line-height: 1.6em
    img
        max-width: 100%
        margin: 1em 0
    .embed-responsive
        margin: 1em 0

    li ul
        padding: 0 0 0 1.4em
    ul + strong
        margin-top: 1em
        display: block
        margin-bottom: 0.5em
    p + p
        margin-top: 1em

    &.section-header
        h1
            max-width: 5em
            font-size: 8em
            +respond-max-width(xs)
                font-size: 5em
                max-width: 100%
.en_US
    .patch-notes-text
        h1
            line-height: 1.2
