@import '../../../../styles/variables-mixins.sass'

.help-center-wrapper .section-container
    padding: 35px 0

    .title-container
        padding: 20px 0
        display: flex
        justify-content: space-between
        align-items: center
        +respond-max-width(sm)
            padding: 10px 0
        .section-title
            margin: 0
        .icon-full-arrow
            padding-left: 50px
            font-size: 3em

        &:hover
            .icon-full-arrow
                animation: pulse 2s infinite

    .section-body
        padding-right: 80px
        font-family: $font_2
        font-size: 1.5rem
        line-height: 1.25
        text-transform: uppercase
        +respond-max-width(sm)
          padding-right: 50px

        .help-center-link
            font-family: $font_2
            font-size: 1.5rem
            color: rgb(255, 255, 0)
            text-transform: uppercase
            text-decoration: underline


// Pulse Animation //
@mixin pulse($vendor: "")
  0%
    #{$vendor}transform: translate(0px, 0) scale(1)

  50%
    #{$vendor}transform: translate(5px, 0) scale(1.25)

  100%
    #{$vendor}transform: translate(0px, 0) scale(1)


@keyframes pulse
  @include pulse
