@import '../../../../styles/variables-mixins.sass'
$blueGray: #aac1cc

.retail-bundle-section
    padding: 3em 0
    h1
        color: $freezeBlue
    h2 
        color: $blueGray
        margin: 1em auto 0
        +respond-max-width(sm)
            font-size: 1.8em
        +respond-max-width(xs)
            font-size: 1.5em
    h3 
        color: $blueGray
        margin: 0 auto 1em
        font-size: 1.2em
    .description 
        width: 100% 
        max-width: 25em
        margin: 0 auto 2em
        +respond-max-width(sm)
            margin: 0 auto 3em
    .img-container 
        position: relative
        &:after 
            content: ""
            position: absolute 
            top: 0
            left: 0
            right: 0 
            bottom: 0
            +gradient-horizontal(right, 0%, 100%, #328ae0, #94e2ff)
            z-index: 0
            +respond-max-width(sm)
                margin: 2em 0
            +respond-max-width(xs)
                margin: 0
                top: 2%
                bottom: 6%
        img 
            width: 102%
            margin: 0 auto
            display: block
            height: auto
            position: relative 
            z-index: 1
            transform: scale(1.13) translate(-0.5em, 0em)
            +respond-max-width(md)
                width: 105%
                transform: scale(1.13) translate(-1.5em, 0em)
            +respond-max-width(sm)
                width: 50%
                transform: scale(1.05) translate(0, -0.8em)
            +respond-max-width(xs)
                width: 100%
                transform: scale(1) translate(0, 0)

    .img-responsive 
        margin: 0 auto 2em

.en_US,
.de,
.es_MX,
.es_ES,
.fr,
.it,
.pl,
.pt_BR,
.tr
    .retail-bundle-section h1
        font-family: $font_2
    .retail-bundle-section h2
        font-family: $font_2
.ja 
    .retail-bundle-section h3 
        font-size: 1.375em
