@import '../../../../styles/variables-mixins.sass'

.confirm-page
    background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat center bottom
    background-size: cover
    position: relative
    +respond-max-width(xs)
        background: url(../../../../images/fnMobile/fnMobileBk.jpg) no-repeat center bottom
    .content-container
        max-width: 1400px
        height: 70vh
        min-height: 845px
        position: relative
        padding: 2em 1em 0
        +respond-max-width(md)
            height: 60vh
            min-height: 635px
        +respond-max-width(sm)
            padding: 0 1em
            height: 100%
            min-height: 100%
        .heroes
            position: absolute
            bottom: 0
            right: 0
            left: 0
            width: auto
            height: 95%
            background: url(../../../../images/fnMobile/astro.png) no-repeat bottom left
            background-size: contain
            z-index: 0
            +respond-max-width(sm)
                background: url(../../../../images/fnMobile/astro_m.png) no-repeat bottom center
                position: relative
                background-size: contain
                min-height: 28em
        .content
            max-width: 35em
            margin: 8em auto 0
            color: $text-color
            position: relative
            z-index: 1
            +respond-max-width(sm)
                margin: 2em auto
            h2
                margin: 0.5em auto
                text-transform: none 
                font-family: $font_1
                font-size: 2em
                +respond-max-width(sm)
                    font-size: 1.25em
            p
                font-size: 1em
                max-width: 25em
                margin: 0 auto
                +respond-max-width(sm)
                    max-width: 25em
            a 
                color: $text-color 
                text-decoration: underline
    &.android 
        background: url(../../../../images/siteBg/blueGridBkgrd.jpg) no-repeat center
        background-size: cover
        .content-container
            .heroes
                background: url(../../../../images/fnMobile/android.png) no-repeat bottom left
                background-size: contain
                +respond-max-width(sm)
                    background: url(../../../../images/fnMobile/android_m.png) no-repeat bottom center
                    background-size: contain
                +respond-handheld-max-width(sm, landscape)
                    display: none

.confirm-page
    &.createAccount .content-container
        .content
            h2 
                font-family: $font_1
                text-transform: none
                font-size: 2em
                max-width: 15em
            p 
                font-size: 1em
        .iosButton
            width: 15em
            height: 5em
            background: url(../../../../images/fnMobile/iosBadge.svg) no-repeat center
            background-size: contain
            margin: 2em auto 0

